import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function Checkout() {
  return (
    <>
     <div className="tf-page-title">
            <div className="container-full">
                <div className="heading text-center">Check Out</div>
            </div>
        </div>
        
        <section className="flat-spacing-11">
            <div className="container">
                <div className="tf-page-cart-wrap layout-2">
                    <div className="tf-page-cart-item">
                        <h5 className="fw-5 mb_20">Billing details</h5>
                        <form className="form-checkout">
                            <div className="box grid-2">
                                <fieldset className="fieldset">
                                    <label for="first-name">First Name</label>
                                    <input type="text" id="first-name" placeholder="Themesflat"/>
                                </fieldset>
                                <fieldset className="fieldset">
                                    <label for="last-name">Last Name</label>
                                    <input type="text" id="last-name"/>
                                </fieldset>
                            </div>
                            <fieldset className="box fieldset">
                                <label for="country">Country/Region</label>
                                <div className="select-custom">
                                    <select className="tf-select w-100" id="country" name="address[country]"
                                        data-default="">
                                        <option value="---" data-provinces="[]">---</option>
                                        <option value="Australia">
                                            Mumbai</option>
                                        <option value="Austria" data-provinces="[]">Delhi</option>
                                        <option value="Belgium" data-provinces="[]">Kolkata</option>
                                        <option value="Canada">
                                            Lucknow</option>

                                    </select>
                                </div>
                            </fieldset>
                            <fieldset className="box fieldset">
                                <label for="city">Town/City</label>
                                <input type="text" id="city"/>
                            </fieldset>
                            <fieldset className="box fieldset">
                                <label for="address">Address</label>
                                <input type="text" id="address"/>
                            </fieldset>
                            <fieldset className="box fieldset">
                                <label for="phone">Phone Number</label>
                                <input type="number" id="phone"/>
                            </fieldset>
                            <fieldset className="box fieldset">
                                <label for="email">Email</label>
                                <input type="email" id="email"/>
                            </fieldset>
                            <fieldset className="box fieldset">
                                <label for="note">Order notes (optional)</label>
                                <textarea name="note" id="note"></textarea>
                            </fieldset>
                        </form>
                    </div>
                    <div className="tf-page-cart-footer">
                        <div className="tf-cart-footer-inner">
                            <h5 className="fw-5 mb_20">Your order</h5>
                            <form className="tf-page-cart-checkout widget-wrap-checkout">
                                <ul className="wrap-checkout-product">
                                    <li className="checkout-product-item">
                                        <figure className="img-product">
                                            <img src="myimg/gar4.jpg" alt="product"/>
                                            <span className="quantity">1</span>
                                        </figure>
                                        <div className="content">
                                            <div className="info">
                                                <p className="name">Garter set</p>
                                                <span className="variant">Brown / M</span>
                                            </div>
                                            <span className="price">₹ 1,299</span>
                                        </div>
                                    </li>
                                    <li className="checkout-product-item">
                                        <figure className="img-product">
                                            <img src="myimg/bstsl3.jpg" alt="product"/>
                                            <span className="quantity">1</span>
                                        </figure>
                                        <div className="content">
                                            <div className="info">
                                                <p className="name">Body Suit</p>
                                            </div>
                                            <span className="price">₹ 1,399</span>
                                        </div>
                                    </li>
                                    <li className="checkout-product-item">
                                        <figure className="img-product">
                                            <img src="myimg/bstsl4.webp" alt="product"/>
                                            <span className="quantity">1</span>
                                        </figure>
                                        <div className="content">
                                            <div className="info">
                                                <p className="name">Babydoll Dresses</p>
                                                <span className="variant">Beige / S</span>
                                            </div>
                                            <span className="price">₹ 800</span>
                                        </div>
                                    </li>
                                    <li className="checkout-product-item">
                                        <figure className="img-product">
                                            <img src="myimg/lin4.jpg" alt="product"/>
                                            <span className="quantity">3</span>
                                        </figure>
                                        <div className="content">
                                            <div className="info">
                                                <p className="name">Plus size lingerie</p>
                                                <span className="variant">Orange / S</span>
                                            </div>
                                            <span className="price">₹ 699</span>
                                        </div>
                                    </li>
                                </ul>
                                <div className="coupon-box">
                                    <input type="text" placeholder="Discount code"/>
                                    <Link to="#"
                                        className="tf-btn btn-sm radius-3 btn-fill btn-icon animate-hover-btn">Apply</Link>
                                </div>
                                <div className="d-flex justify-content-between line pb_20">
                                    <h6 className="fw-5">Total</h6>
                                    <h6 className="total fw-5">₹ 2,567.00</h6>
                                </div>
                                <div className="wd-check-payment">
                                    <div className="fieldset-radio mb_20">
                                        <input type="radio" name="payment" id="bank" className="tf-check" checked/>
                                        <label for="bank">Direct bank transfer</label>

                                    </div>
                                    <div className="fieldset-radio mb_20">
                                        <input type="radio" name="payment" id="delivery" className="tf-check"/>
                                        <label for="delivery">Cash on delivery</label>
                                    </div>
                                    <p className="text_black-2 mb_20">Your personal data will be used to process your order,
                                        support your experience throughout this website, and for other purposes
                                        described in our <Link to="privacy-policy"
                                            className="text-decoration-underline">privacy policy</Link>.</p>
                                    <div className="box-checkbox fieldset-radio mb_20">
                                        <input type="checkbox" id="check-agree" className="tf-check"/>
                                        <label for="check-agree" className="text_black-2">I have read and agree to the
                                            website <Link to="terms-conditions"
                                                className="text-decoration-underline">terms and conditions</Link>.</label>
                                    </div>
                                </div>
                                <button
                                    className="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center">Place
                                    order</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
