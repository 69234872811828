import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function ShippingDelivery() {
  return (
    <>
      <div class="tf-page-title style-2">
            <div class="container-full">
                <div class="heading text-center">Shipping & Return</div>
            </div>
        </div>
       
        <section class="flat-spacing-25">
            <div class="container">
                <div class="tf-main-area-page tf-page-delivery">
                    <div class="box">
                        <h4>Delivery</h4>
                        <p>All orders shipped with UPS Express.</p>
                        <p>Always free shipping for orders over ₹1000.</p>
                        <p>All orders are shipped with a UPS tracking number.</p>
                    </div>
                    <div class="box">
                        <h4>Returns</h4>
                        <p>Items returned within 14 days of their original shipment date in same as new condition will
                            be eligible for a full refund or store credit.</p>
                        <p>Refunds will be charged back to the original form of payment used for purchase.</p>
                        <p>Customer is responsible for shipping charges when making returns and shipping/handling fees
                            of original purchase is non-refundable.</p>
                        <p>All sale items are final purchases.</p>
                    </div>
                    <div class="box">
                        <h4>Help</h4>
                        <p>Give us a shout if you have any other questions and/or concerns.</p>
                        <p>Email: <Link to="mailto:contact@domain.com" class="cf-mail">contact@domain.com</Link></p>
                        <p>Phone: 7894561236</p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
