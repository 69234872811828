import React, { useEffect } from 'react'
import { Link,useNavigate,useParams } from "react-router-dom";
import Products from "./Products";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CategoryInner(props) {
    const {id, category} = useParams();
  return (
    <>
     <div className="tf-page-title">
            <div className="container-full">
                <div className="row">
                    <div className="col-12">
                        <div className="heading text-center">Garter Sets</div>
                        <p className="text-center text-2 text_black-2 mt_5">Shop through our latest selection of Garter Set
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
        <section className="flat-spacing-1">
            <div className="container">
                <div className="tf-shop-control grid-3 align-items-center">
                    <div className="tf-control-filter">
                        <Link to="#filterShop" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                            className="tf-btn-filter"><span className="icon icon-filter"></span><span
                                className="text">Filter</span></Link>
                    </div>
                    <ul className="tf-control-layout d-flex justify-content-center" style={{visibility: "hidden"}}>
                        <li className="tf-view-layout-switch sw-layout-2" data-value-grid="grid-2">
                            <div className="item"><span className="icon icon-grid-2"></span></div>
                        </li>
                        <li className="tf-view-layout-switch sw-layout-3" data-value-grid="grid-3">
                            <div className="item"><span className="icon icon-grid-3"></span></div>
                        </li>
                        <li className="tf-view-layout-switch sw-layout-4 active" data-value-grid="grid-4">
                            <div className="item"><span className="icon icon-grid-4"></span></div>
                        </li>
                        <li className="tf-view-layout-switch sw-layout-5" data-value-grid="grid-5">
                            <div className="item"><span className="icon icon-grid-5"></span></div>
                        </li>
                        <li className="tf-view-layout-switch sw-layout-6" data-value-grid="grid-6">
                            <div className="item"><span className="icon icon-grid-6"></span></div>
                        </li>
                    </ul>
                    <div className="tf-control-sorting d-flex justify-content-end">
                        <div className="tf-dropdown-sort" data-bs-toggle="dropdown">
                            <div className="btn-select">
                                <span className="text-sort-value">Featured</span>
                                <span className="icon icon-arrow-down"></span>
                            </div>
                            <div className="dropdown-menu">
                                <div className="select-item active">
                                    <span className="text-value-item">Featured</span>
                                </div>
                                <div className="select-item">
                                    <span className="text-value-item">Best selling</span>
                                </div>
                                <div className="select-item">
                                    <span className="text-value-item">Alphabetically, A-Z</span>
                                </div>
                                <div className="select-item">
                                    <span className="text-value-item">Alphabetically, Z-A</span>
                                </div>
                                <div className="select-item">
                                    <span className="text-value-item">Price, low to high</span>
                                </div>
                                <div className="select-item">
                                    <span className="text-value-item">Price, high to low</span>
                                </div>
                                <div className="select-item">
                                    <span className="text-value-item">Date, old to new</span>
                                </div>
                                <div className="select-item">
                                    <span className="text-value-item">Date, new to old</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <ToastContainer position="top-right" reverseOrder={false} />
                <div className="grid-layout wrapper-shop" data-grid="grid-4">
                
                <Products addItem={props.addItem}/>
                    
                    
                
                {/* <ul className="tf-pagination-wrap tf-pagination-list">
                    <li className="active">
                        <Link to="#" className="pagination-link">1</Link>
                    </li>
                    <li>
                        <Link to="#" className="pagination-link animate-hover-btn">2</Link>
                    </li>
                    <li>
                        <Link to="#" className="pagination-link animate-hover-btn">3</Link>
                    </li>
                    <li>
                        <Link to="#" className="pagination-link animate-hover-btn">4</Link>
                    </li>
                    <li>
                        <Link to="#" className="pagination-link animate-hover-btn">
                            <span className="icon icon-arrow-right"></span>
                        </Link>
                    </li>
                </ul> */}
            </div>
            </div>
        </section>


        
        <div className="offcanvas offcanvas-start canvas-filter" id="filterShop">
            <div className="canvas-wrapper">
                <header className="canvas-header">
                    <div className="filter-icon">
                        <span className="icon icon-filter"></span>
                        <span>Filter</span>
                    </div>
                    <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
                </header>
                <div className="canvas-body">
                    {/* <!-- <div className="widget-facet wd-categories">
                        <div className="facet-title" data-bs-target="#categories" data-bs-toggle="collapse"
                            aria-expanded="true" aria-controls="categories">
                            <span>Product categories</span>
                            <span className="icon icon-arrow-up"></span>
                        </div>
                        <div id="categories" className="collapse show">
                            <ul className="list-categoris current-scrollbar mb_36">
                                <li className="cate-item current"><Link to="shop-default"><span>Lingerie</span></Link></li>
                                <li className="cate-item"><Link to="shop-default"><span>Intimate Toys</span></Link></li>
                                <li className="cate-item"><Link to="shop-default"><span>Women</span></Link></li>
                                <li className="cate-item"><Link to="shop-default"><span>Denim</span></Link></li>
                                <li className="cate-item"><Link to="shop-default"><span>Dress</span></Link></li>
                            </ul>
                        </div>
                    </div> --> */}
                    <form action="#" id="facet-filter-form" className="facet-filter-form">
                        <div className="widget-facet">
                            <div className="facet-title" data-bs-target="#availability" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="availability">
                                <span>Availability</span>
                                <span className="icon icon-arrow-up"></span>
                            </div>
                            <div id="availability" className="collapse show">
                                <ul className="tf-filter-group current-scrollbar mb_36">
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="availability" className="tf-check" id="availability-1"/>
                                        <label htmlFor="availability-1" className="label"><span>In
                                                stock</span>&nbsp;<span>(14)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="availability" className="tf-check" id="availability-2"/>
                                        <label htmlFor="availability-2" className="label"><span>Out of
                                                stock</span>&nbsp;<span>(2)</span></label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="widget-facet">
                            <div className="facet-title" data-bs-target="#price" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="price">
                                <span>Price</span>
                                <span className="icon icon-arrow-up"></span>
                            </div>
                            <div id="price" className="collapse show">
                                <div className="widget-price">
                                    <div id="slider-range"></div>
                                    <div className="box-title-price">
                                        <span className="title-price">Price :</span>
                                        <div className="caption-price">
                                            <div>
                                                <span>$</span>
                                                <span id="slider-range-value1" className=""></span>
                                            </div>
                                            <span>-</span>
                                            <div>
                                                <span>$</span>
                                                <span id="slider-range-value2" className=""></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="widget-facet">
                            <div className="facet-title" data-bs-target="#color" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="color">
                                <span>Color</span>
                                <span className="icon icon-arrow-up"></span>
                            </div>
                            <div id="color" className="collapse show">
                                <ul className="tf-filter-group filter-color current-scrollbar mb_36">
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_beige" id="beige"/>
                                        <label htmlFor="beige"
                                            className="label"><span>Beige</span>&nbsp;<span>(3)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_dark" id="black"/>
                                        <label htmlFor="black"
                                            className="label"><span>Black</span>&nbsp;<span>(18)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_blue-2" id="blue"/>
                                        <label htmlFor="blue" className="label"><span>Blue</span>&nbsp;<span>(3)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_brown" id="brown"/>
                                        <label htmlFor="brown"
                                            className="label"><span>Brown</span>&nbsp;<span>(3)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_cream" id="cream"/>
                                        <label htmlFor="cream"
                                            className="label"><span>Cream</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_dark-beige"
                                            id="dark-beige"/>
                                        <label htmlFor="dark-beige" className="label"><span>Dark
                                                Beige</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_dark-blue"
                                            id="dark-blue"/>
                                        <label htmlFor="dark-blue" className="label"><span>Dark
                                                Blue</span>&nbsp;<span>(3)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_dark-green"
                                            id="dark-green"/>
                                        <label htmlFor="dark-green" className="label"><span>Dark
                                                Green</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_dark-grey"
                                            id="dark-grey"/>
                                        <label htmlFor="dark-grey" className="label"><span>Dark
                                                Grey</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_grey" id="grey"/>
                                        <label htmlFor="grey" className="label"><span>Grey</span>&nbsp;<span>(2)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_light-blue"
                                            id="light-blue"/>
                                        <label htmlFor="light-blue" className="label"><span>Light
                                                Blue</span>&nbsp;<span>(5)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_light-green"
                                            id="light-green"/>
                                        <label htmlFor="light-green" className="label"><span>Light
                                                Green</span>&nbsp;<span>(3)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_light-grey"
                                            id="light-grey"/>
                                        <label htmlFor="light-grey" className="label"><span>Light
                                                Grey</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_light-pink"
                                            id="light-pink"/>
                                        <label htmlFor="light-pink" className="label"><span>Light
                                                Pink</span>&nbsp;<span>(2)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_purple"
                                            id="light-purple"/>
                                        <label htmlFor="light-purple" className="label"><span>Light
                                                Purple</span>&nbsp;<span>(2)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_light-yellow"
                                            id="light-yellow"/>
                                        <label htmlFor="light-yellow" className="label"><span>Light
                                                Yellow</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_orange" id="orange"/>
                                        <label htmlFor="orange"
                                            className="label"><span>Orange</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_pink" id="pink"/>
                                        <label htmlFor="pink" className="label"><span>Pink</span>&nbsp;<span>(2)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_taupe" id="taupe"/>
                                        <label htmlFor="taupe"
                                            className="label"><span>Taupe</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_white" id="white"/>
                                        <label htmlFor="white"
                                            className="label"><span>White</span>&nbsp;<span>(14)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="color" className="tf-check-color bg_yellow" id="yellow"/>
                                        <label htmlFor="yellow"
                                            className="label"><span>Yellow</span>&nbsp;<span>(1)</span></label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="widget-facet">
                            <div className="facet-title" data-bs-target="#size" data-bs-toggle="collapse"
                                aria-expanded="true" aria-controls="size">
                                <span>Size</span>
                                <span className="icon icon-arrow-up"></span>
                            </div>
                            <div id="size" className="collapse show">
                                <ul className="tf-filter-group current-scrollbar">
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="size" className="tf-check" id="s"/>
                                        <label htmlFor="s" className="label"><span>S</span>&nbsp;<span>(7)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="size" className="tf-check" id="m"/>
                                        <label htmlFor="m" className="label"><span>M</span>&nbsp;<span>(8)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="size" className="tf-check" id="l"/>
                                        <label htmlFor="l" className="label"><span>L</span>&nbsp;<span>(8)</span></label>
                                    </li>
                                    <li className="list-item d-flex gap-12 align-items-center">
                                        <input type="radio" name="size" className="tf-check" id="xl"/>
                                        <label htmlFor="xl" className="label"><span>XL</span>&nbsp;<span>(6)</span></label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
     
        <div className="offcanvas offcanvas-end canvas-search" id="canvasSearch">
            <div className="canvas-wrapper">
                <header className="tf-search-head">
                    <div className="title fw-5">
                        Search our site
                        <div className="close">
                            <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas"
                                aria-label="Close"></span>
                        </div>
                    </div>
                    <div className="tf-search-sticky">
                        <form className="tf-mini-search-frm">
                            <fieldset className="text">
                                <input type="text" placeholder="Search" className="" name="text" tabIndex="0" value=""
                                    aria-required="true" required=""/>
                            </fieldset>
                            <button className="" type="submit"><i className="icon-search"></i></button>
                        </form>
                    </div>
                </header>
                <div className="canvas-body p-0">
                    <div className="tf-search-content">
                        <div className="tf-cart-hide-has-results">
                            <div className="tf-col-quicklink">
                                <div className="tf-search-content-title fw-5">Quick link</div>
                                <ul className="tf-quicklink-list">
                                    <li className="tf-quicklink-item">
                                        <Link to="shop-default" className="">Fashion</Link>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <Link to="shop-default" className="">Men</Link>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <Link to="shop-default" className="">Women</Link>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <Link to="shop-default" className="">Accessories</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="tf-col-content">
                                <div className="tf-search-content-title fw-5">Need some inspiration?</div>
                                <div className="tf-search-hidden-inner">
                                    <div className="tf-loop-item">
                                        <div className="image">
                                            <Link to="product-detail">
                                                <img src="images/products/white-3.jpg" alt=""/>
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <Link to="product-detail">Cotton jersey top</Link>
                                            <div className="tf-product-info-price">
                                                <div className="compare-at-price">$10.00</div>
                                                <div className="price-on-sale fw-6">$8.00</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-loop-item">
                                        <div className="image">
                                            <Link to="product-detail">
                                                <img src="images/products/white-2.jpg" alt=""/>
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <Link to="product-detail">Mini crossbody bag</Link>
                                            <div className="tf-product-info-price">
                                                <div className="price fw-6">$18.00</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-loop-item">
                                        <div className="image">
                                            <Link to="product-detail">
                                                <img src="images/products/white-1.jpg" alt=""/>
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <Link to="product-detail">Oversized Printed T-shirt</Link>
                                            <div className="tf-product-info-price">
                                                <div className="price fw-6">$18.00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </>
  )
}
