import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function MyAccountEdit() {
  return (
    <>
      <div className="tf-page-title">
            <div className="container-full">
                <div className="heading text-center">Account Details</div>
            </div>
        </div>
        
        <section className="flat-spacing-11">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <ul className="my-account-nav">
                            <li><Link to="my-account" className="my-account-nav-item ">Dashboard</Link></li>
                            <li><Link to="my-account-orders" className="my-account-nav-item">Orders</Link></li>
                            <li><Link to="my-account-address" className="my-account-nav-item">Addresses</Link></li>
                            <li><span className="my-account-nav-item active">Account Details</span></li>
                            <li><Link to="my-account-wishlist" className="my-account-nav-item">Wishlist</Link></li>
                            <li><Link to="login" className="my-account-nav-item">Logout</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-9">
                        <div className="my-account-content account-edit">
                            <div className="">
                                <form className="" id="form-password-change" action="#">
                                    <div className="tf-field style-1 mb_15">
                                        <input className="tf-field-input tf-input" placeholder=" " type="text"
                                            id="property1" name="first name"/>
                                        <label className="tf-field-label fw-4 text_black-2" for="property1">First
                                            name</label>
                                    </div>
                                    <div className="tf-field style-1 mb_15">
                                        <input className="tf-field-input tf-input" placeholder=" " type="text"
                                            id="property2" name="last name"/>
                                        <label className="tf-field-label fw-4 text_black-2" for="property2">Last
                                            name</label>
                                    </div>
                                    <div className="tf-field style-1 mb_15">
                                        <input className="tf-field-input tf-input" placeholder=" " type="email"
                                            id="property3" name="email"/>
                                        <label className="tf-field-label fw-4 text_black-2" for="property3">Email</label>
                                    </div>
                                    <h6 className="mb_20">Password Change</h6>
                                    <div className="tf-field style-1 mb_30">
                                        <input className="tf-field-input tf-input" placeholder=" " type="password"
                                            id="property4" name="password"/>
                                        <label className="tf-field-label fw-4 text_black-2" for="property4">Current
                                            password</label>
                                    </div>
                                    <div className="tf-field style-1 mb_30">
                                        <input className="tf-field-input tf-input" placeholder=" " type="password"
                                            id="property5" name="password"/>
                                        <label className="tf-field-label fw-4 text_black-2" for="property5">New
                                            password</label>
                                    </div>
                                    <div className="tf-field style-1 mb_30">
                                        <input className="tf-field-input tf-input" placeholder=" " type="password"
                                            id="property6" name="password"/>
                                        <label className="tf-field-label fw-4 text_black-2" for="property6">Confirm
                                            password</label>
                                    </div>
                                    <div className="mb_20">
                                        <button type="submit"
                                            className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center">Save
                                            Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
