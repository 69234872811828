import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function ProductDetail() {
  return (
    <>
     <div className="tf-breadcrumb">
            <div className="container">
                <div className="tf-breadcrumb-wrap d-flex justify-content-between flex-wrap align-items-center">
                    <div className="tf-breadcrumb-list">
                        <Link to="index" className="text">Home</Link>
                        <i className="icon icon-arrow-right"></i>
                        <Link to="category-inner" className="text">Garter Set</Link>
                        <i className="icon icon-arrow-right"></i>
                        <span className="text">Garter Set Detail</span>
                    </div>

                </div>
            </div>
        </div>
       
        <section className="flat-spacing-4 pt_0">
            <div className="tf-main-product section-image-zoom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="tf-product-media-wrap sticky-top">
                                <div className="thumbs-slider">
                                    <div className="swiper tf-product-media-thumbs other-image-zoom"
                                        data-direction="vertical">
                                        <div className="swiper-wrapper stagger-wrap">
                                            <div className="swiper-slide stagger-item">
                                                <div className="item">
                                                    <img className="lazyload" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                                        alt=""/>
                                                </div>
                                            </div>
                                            <div className="swiper-slide stagger-item">
                                                <div className="item">
                                                    <img className="lazyload" data-src="myimg/gar2.jpg" src="myimg/gar2.jpg"
                                                        alt="img-compare"/>
                                                </div>
                                            </div>
                                            <div className="swiper-slide stagger-item">
                                                <div className="item">
                                                    <img className="lazyload" data-src="myimg/gar3.jpg" src="myimg/gar3.jpg"
                                                        alt="img-compare"/>
                                                </div>
                                            </div>
                                            <div className="swiper-slide stagger-item">
                                                <div className="item">
                                                    <img className="lazyload" data-src="myimg/gar3.jpg" src="myimg/gar3.jpg"
                                                        alt="img-compare"/>
                                                </div>
                                            </div>
                                            <div className="swiper-slide stagger-item">
                                                <div className="item">
                                                    <img className="lazyload" data-src="myimg/gar3.jpg" src="myimg/gar3.jpg"
                                                        alt="img-compare"/>
                                                </div>
                                            </div>
                                            <div className="swiper-slide stagger-item">
                                                <div className="item">
                                                    <img className="lazyload" data-src="myimg/gar3.jpg" src="myimg/gar3.jpg"
                                                        alt="img-compare"/>
                                                </div>
                                            </div>
                                            <div className="swiper-slide stagger-item">
                                                <div className="item">
                                                    <img className="lazyload" data-src="myimg/gar3.jpg" src="myimg/gar3.jpg"
                                                        alt="img-compare"/>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="swiper tf-product-media-main" id="gallery-swiper-started">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <Link to="#" target="_blank" className="item" data-pswp-width="770px"
                                                    data-pswp-height="1075px">
                                                    <img className="tf-image-zoom lazyload" data-zoom="myimg/gar1.jpg"
                                                        data-src="myimg/gar1.jpg" src="myimg/gar1.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div className="swiper-slide">
                                                <Link to="myimg/gar2.jpg" target="_blank" className="item"
                                                    data-pswp-width="770px" data-pswp-height="1075px">
                                                    <img className="tf-image-zoom lazyload" data-zoom="myimg/gar2.jpg"
                                                        data-src="myimg/gar2.jpg" src="myimg/gar2.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div className="swiper-slide">
                                                <Link to="myimg/gar3.jpg" target="_blank" className="item"
                                                    data-pswp-width="770px" data-pswp-height="1075px">
                                                    <img className="tf-image-zoom lazyload" data-zoom="myimg/gar3.jpg"
                                                        data-src="myimg/gar3.jpg" src="myimg/gar3.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div className="swiper-slide">
                                                <Link to="myimg/gar3.jpg" target="_blank" className="item"
                                                    data-pswp-width="770px" data-pswp-height="1075px">
                                                    <img className="tf-image-zoom lazyload" data-zoom="myimg/gar3.jpg"
                                                        data-src="myimg/gar3.jpg" src="myimg/gar3.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div className="swiper-slide">
                                                <Link to="myimg/gar3.jpg" target="_blank" className="item"
                                                    data-pswp-width="770px" data-pswp-height="1075px">
                                                    <img className="tf-image-zoom lazyload" data-zoom="myimg/gar3.jpg"
                                                        data-src="myimg/gar3.jpg" src="myimg/gar3.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div className="swiper-slide">
                                                <Link to="myimg/gar3.jpg" target="_blank" className="item"
                                                    data-pswp-width="770px" data-pswp-height="1075px">
                                                    <img className="tf-image-zoom lazyload" data-zoom="myimg/gar3.jpg"
                                                        data-src="myimg/gar3.jpg" src="myimg/gar3.jpg" alt=""/>
                                                </Link>
                                            </div>
                                            <div className="swiper-slide">
                                                <Link to="myimg/gar3.jpg" target="_blank" className="item"
                                                    data-pswp-width="770px" data-pswp-height="1075px">
                                                    <img className="tf-image-zoom lazyload" data-zoom="myimg/gar3.jpg"
                                                        data-src="myimg/gar3.jpg" src="myimg/gar3.jpg" alt=""/>
                                                </Link>
                                            </div>

                                        </div>
                                        <div className="swiper-button-next button-style-arrow thumbs-next"></div>
                                        <div className="swiper-button-prev button-style-arrow thumbs-prev"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tf-product-info-wrap position-relative">
                                <div className="tf-zoom-main"></div>
                                <div className="tf-product-info-list other-image-zoom">
                                    <div className="tf-product-info-title">
                                        <h5>Garter Set</h5>
                                    </div>
                                    <div className="tf-product-info-badges">
                                        <div className="badges">Best seller</div>
                                        <div className="product-status-content">
                                            <i className="icon-lightning"></i>
                                            <p className="fw-6">Selling fast! 56 people have this in their carts.</p>
                                        </div>
                                    </div>
                                    <div className="tf-product-info-price">
                                        <div className="price-on-sale">₹ 1,799</div>
                                        <div className="compare-at-price">₹ 1,999</div>
                                        <div className="badges-on-sale"><span>20</span>% OFF</div>
                                    </div>
                                    <div className="tf-product-info-liveview">
                                        <div className="liveview-count">20</div>
                                        <p className="fw-6">People are viewing this right now</p>
                                    </div>
                                    <div className="tf-product-info-countdown">
                                        <div className="countdown-wrap">
                                            <div className="countdown-title">
                                                <i className="icon-time tf-ani-tada"></i>
                                                <p>HURRY UP! SALE ENDS IN:</p>
                                            </div>
                                            <div className="tf-countdown style-1">
                                                <div className="js-countdown" data-timer="1007500"
                                                    data-labels="Days :,Hours :,Mins :,Secs"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-product-info-variant-picker">
                                        <div className="variant-picker-item">
                                            <div className="variant-picker-label">
                                                Color: <span className="fw-6 variant-picker-label-value">Beige</span>
                                            </div>
                                            <div className="variant-picker-values">
                                                <input id="values-beige" type="radio" name="color1" checked/>
                                                <label className="hover-tooltip radius-60" for="values-beige"
                                                    data-value="Beige">
                                                    <span className="btn-checkbox bg-color-beige"></span>
                                                    <span className="tooltip">Beige</span>
                                                </label>
                                                <input id="values-black" type="radio" name="color1"/>
                                                <label className=" hover-tooltip radius-60" for="values-black"
                                                    data-value="Black">
                                                    <span className="btn-checkbox bg-color-black"></span>
                                                    <span className="tooltip">Black</span>
                                                </label>
                                                <input id="values-blue" type="radio" name="color1"/>
                                                <label className="hover-tooltip radius-60" for="values-blue"
                                                    data-value="Blue">
                                                    <span className="btn-checkbox bg-color-blue"></span>
                                                    <span className="tooltip">Blue</span>
                                                </label>
                                                <input id="values-white" type="radio" name="color1"/>
                                                <label className="hover-tooltip radius-60" for="values-white"
                                                    data-value="White">
                                                    <span className="btn-checkbox bg-color-white"></span>
                                                    <span className="tooltip">White</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="variant-picker-item">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="variant-picker-label">
                                                    Size: <span className="fw-6 variant-picker-label-value">S</span>
                                                </div>
                                                <Link to="#find_size" data-bs-toggle="modal" className="find-size fw-6">Find
                                                    your size</Link>
                                            </div>
                                            <div className="variant-picker-values">
                                                <input type="radio" name="size1" id="values-s" checked/>
                                                <label className="style-text" for="values-s" data-value="S">
                                                    <p>S</p>
                                                </label>
                                                <input type="radio" name="size1" id="values-m"/>
                                                <label className="style-text" for="values-m" data-value="M">
                                                    <p>M</p>
                                                </label>
                                                <input type="radio" name="size1" id="values-l"/>
                                                <label className="style-text" for="values-l" data-value="L">
                                                    <p>L</p>
                                                </label>
                                                <input type="radio" name="size1" id="values-xl"/>
                                                <label className="style-text" for="values-xl" data-value="XL">
                                                    <p>XL</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-product-info-quantity">
                                        <div className="quantity-title fw-6">Quantity</div>
                                        <div className="wg-quantity">
                                            <span className="btn-quantity minus-btn">-</span>
                                            <input type="text" name="number" value="1"/>
                                            <span className="btn-quantity plus-btn">+</span>
                                        </div>
                                    </div>
                                    <div className="tf-product-info-buy-button">
                                        <form className="">
                                            <Link to="#"
                                                className="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn "><span>Add
                                                    to cart -&nbsp;</span><span className="tf-qty-price">₹ 1,799</span></Link>
                                            <Link to="javascript:void(0);"
                                                className="tf-product-btn-wishlist hover-tooltip box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>
                                            <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                                className="tf-product-btn-wishlist hover-tooltip box-icon bg_white compare btn-icon-action">
                                                <span className="icon icon-compare"></span>
                                                <span className="tooltip">Add to Compare</span>
                                                <span className="icon icon-check"></span>
                                            </Link>
                                            <div className="w-100">
                                                <Link to="#" className="btns-full">Buy with <img
                                                        src="images/payments/paypal.png" alt=""/></Link>
                                                <Link to="#" className="payment-more-option">More payment options</Link>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tf-product-info-extra-link">

                                        <Link to="#ask_question" data-bs-toggle="modal" className="tf-product-extra-icon">
                                            <div className="icon">
                                                <i className="icon-question"></i>
                                            </div>
                                            <div className="text fw-6">Ask a question</div>
                                        </Link>
                                        <Link to="#delivery_return" data-bs-toggle="modal" className="tf-product-extra-icon">
                                            <div className="icon">
                                                <svg className="d-inline-block" xmlns="http://www.w3.org/2000/svg"
                                                    width="22" height="18" viewBox="0 0 22 18" fill="currentColor">
                                                    <path
                                                        d="M21.7872 10.4724C21.7872 9.73685 21.5432 9.00864 21.1002 8.4217L18.7221 5.27043C18.2421 4.63481 17.4804 4.25532 16.684 4.25532H14.9787V2.54885C14.9787 1.14111 13.8334 0 12.4255 0H9.95745V1.69779H12.4255C12.8948 1.69779 13.2766 2.07962 13.2766 2.54885V14.5957H8.15145C7.80021 13.6052 6.85421 12.8936 5.74468 12.8936C4.63515 12.8936 3.68915 13.6052 3.33792 14.5957H2.55319C2.08396 14.5957 1.70213 14.2139 1.70213 13.7447V2.54885C1.70213 2.07962 2.08396 1.69779 2.55319 1.69779H9.95745V0H2.55319C1.14528 0 0 1.14111 0 2.54885V13.7447C0 15.1526 1.14528 16.2979 2.55319 16.2979H3.33792C3.68915 17.2884 4.63515 18 5.74468 18C6.85421 18 7.80021 17.2884 8.15145 16.2979H13.423C13.7742 17.2884 14.7202 18 15.8297 18C16.9393 18 17.8853 17.2884 18.2365 16.2979H21.7872V10.4724ZM16.684 5.95745C16.9494 5.95745 17.2034 6.08396 17.3634 6.29574L19.5166 9.14894H14.9787V5.95745H16.684ZM5.74468 16.2979C5.27545 16.2979 4.89362 15.916 4.89362 15.4468C4.89362 14.9776 5.27545 14.5957 5.74468 14.5957C6.21392 14.5957 6.59575 14.9776 6.59575 15.4468C6.59575 15.916 6.21392 16.2979 5.74468 16.2979ZM15.8298 16.2979C15.3606 16.2979 14.9787 15.916 14.9787 15.4468C14.9787 14.9776 15.3606 14.5957 15.8298 14.5957C16.299 14.5957 16.6809 14.9776 16.6809 15.4468C16.6809 15.916 16.299 16.2979 15.8298 16.2979ZM18.2366 14.5957C17.8853 13.6052 16.9393 12.8936 15.8298 12.8936C15.5398 12.8935 15.252 12.943 14.9787 13.04V10.8511H20.0851V14.5957H18.2366Z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div className="text fw-6">Delivery & Return</div>
                                        </Link>
                                        <Link to="#share_social" data-bs-toggle="modal" className="tf-product-extra-icon">
                                            <div className="icon">
                                                <i className="icon-share"></i>
                                            </div>
                                            <div className="text fw-6">Share</div>
                                        </Link>
                                    </div>
                                    <div className="tf-product-info-delivery-return">
                                        <div className="row">
                                            <div className="col-xl-6 col-12">
                                                <div className="tf-product-delivery">
                                                    <div className="icon">
                                                        <i className="icon-delivery-time"></i>
                                                    </div>
                                                    <p>Estimate delivery times: <span className="fw-7">12-26 days</span>
                                                        (International), <span className="fw-7">3-6 days</span> (India).</p>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-12">
                                                <div className="tf-product-delivery mb-0">
                                                    <div className="icon">
                                                        <i className="icon-return-order"></i>
                                                    </div>
                                                    <p>Return within <span className="fw-7">30 days</span> of purchase.
                                                        Duties & taxes are non-refundable.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-product-info-trust-seal">
                                        <div className="tf-product-trust-mess">
                                            <i className="icon-safe"></i>
                                            <p className="fw-6">Guarantee Safe <br/> Checkout</p>
                                        </div>
                                        <div className="tf-payment">
                                            <img src="images/payments/visa.png" alt=""/>
                                            <img src="images/payments/img-1.png" alt=""/>
                                            <img src="images/payments/img-2.png" alt=""/>
                                            <img src="images/payments/img-3.png" alt=""/>
                                            <img src="images/payments/img-4.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tf-sticky-btn-atc">
                <div className="container">
                    <div className="tf-height-observer w-100 d-flex align-items-center">
                        <div className="tf-sticky-atc-product d-flex align-items-center">
                            <div className="tf-sticky-atc-img">
                                <img className="lazyloaded" data-src="images/shop/products/p-d1.png" alt=""
                                    src="images/shop/products/p-d1.png"/>
                            </div>
                            <div className="tf-sticky-atc-title fw-5 d-xl-block d-none">Cotton jersey top</div>
                        </div>
                        <div className="tf-sticky-atc-infos">
                            <form className="">
                                <div className="tf-sticky-atc-variant-price text-center">
                                    <select className="tf-select">
                                        <option selected="selected">Beige / S - $8.00</option>
                                        <option>Beige / M - $8.00</option>
                                        <option>Beige / L - $8.00</option>
                                        <option>Beige / XL - $8.00</option>
                                        <option>Black / S - $8.00</option>
                                        <option>Black / M - $8.00</option>
                                        <option>Black / L - $8.00</option>
                                        <option>Black / XL - $8.00</option>
                                        <option>Blue / S - $8.00</option>
                                        <option>Blue / M - $8.00</option>
                                        <option>Blue / L - $8.00</option>
                                        <option>Blue / XL - $8.00</option>
                                        <option>White / S - $8.00</option>
                                        <option>White / M - $8.00</option>
                                        <option>White / L - $8.00</option>
                                        <option>White / XL - $8.00</option>
                                    </select>
                                </div>
                                <div className="tf-sticky-atc-btns">
                                    <div className="tf-product-info-quantity">
                                        <div className="wg-quantity">
                                            <span className="btn-quantity minus-btn">-</span>
                                            <input type="text" name="number" value="1"/>
                                            <span className="btn-quantity plus-btn">+</span>
                                        </div>
                                    </div>
                                    <Link to="#"
                                        className="tf-btn btn-fill radius-3 justify-content-center fw-6 fs-14 flex-grow-1 animate-hover-btn ">Add
                                            to cart</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        <section className="flat-spacing-17 pt_0">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="widget-tabs style-has-border">
                            <ul className="widget-menu-tab">
                                <li className="item-title active">
                                    <span className="inner">Description</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">Review</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">Shipping</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">Return Policies</span>
                                </li>
                            </ul>
                            <div className="widget-content-tab">
                                <div className="widget-content-inner active">
                                    <div className="">
                                        <p className="mb_30">
                                            A women's garter set typically includes a garter belt and matching lingerie
                                            items such as stockings, panties, and sometimes a bra. The garter belt is
                                            designed to be worn around the waist or hips, with adjustable straps that
                                            attach to stockings, holding them in place. This type of lingerie is often
                                            used for special occasions, adding a touch of elegance and sensuality to the
                                            ensemble.

                                            Garter sets come in various styles, colors, and materials, including lace,
                                            satin, and mesh, allowing for a personalized and sophisticated look.
                                        </p>
                                        <div className="tf-product-des-demo">
                                            <div className="right">
                                                <h3 className="fs-16 fw-5">Features</h3>
                                                <ul>
                                                    <li>Garter Belt</li>
                                                    <li> Stockings</li>
                                                    <li>Matching Panties</li>
                                                </ul>
                                                <h3 className="fs-16 fw-5">Materials Care</h3>
                                                <ul className="mb-0">
                                                    <li>Content: 100% Cotton</li>
                                                    <li>Care: Hand wash</li>
                                                    <li>Imported</li>
                                                </ul>
                                            </div>
                                            <div className="left">
                                                <h3 className="fs-16 fw-5">Materials Care</h3>
                                                <div className="d-flex gap-10 mb_15 align-items-center">
                                                    <div className="icon">
                                                        <i className="icon-machine"></i>
                                                    </div>
                                                    <span>Machine wash max. 30ºC. Short spin.</span>
                                                </div>
                                                <div className="d-flex gap-10 mb_15 align-items-center">
                                                    <div className="icon">
                                                        <i className="icon-iron"></i>
                                                    </div>
                                                    <span>Iron maximum 110ºC.</span>
                                                </div>
                                                <div className="d-flex gap-10 mb_15 align-items-center">
                                                    <div className="icon">
                                                        <i className="icon-bleach"></i>
                                                    </div>
                                                    <span>Do not bleach/bleach.</span>
                                                </div>
                                                <div className="d-flex gap-10 mb_15 align-items-center">
                                                    <div className="icon">
                                                        <i className="icon-dry-clean"></i>
                                                    </div>
                                                    <span>Do not dry clean.</span>
                                                </div>
                                                <div className="d-flex gap-10 align-items-center">
                                                    <div className="icon">
                                                        <i className="icon-tumble-dry"></i>
                                                    </div>
                                                    <span>Tumble dry, medium hear.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content-inner">

                                    <p>1. Radhika Apte : I was pleasantly surprised by how comfortable this garter set
                                        is. The
                                        adjustable straps made it easy to get the perfect fit, and it stayed in
                                        place all night </p>
                                    <p>1. Radhika Apte : I was pleasantly surprised by how comfortable this garter set
                                        is. The
                                        adjustable straps made it easy to get the perfect fit, and it stayed in
                                        place all night </p>
                                    <p>1. Radhika Apte : I was pleasantly surprised by how comfortable this garter set
                                        is. The
                                        adjustable straps made it easy to get the perfect fit, and it stayed in
                                        place all night </p>

                                </div>
                                <div className="widget-content-inner">
                                    <div className="tf-page-privacy-policy">
                                        <div className="title">The Nicky Private Limited Policy</div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati adipisci
                                            autem, delectus ipsa harum nesciunt, nisi non officia quas at culpa esse
                                            vero aut, officiis optio labore voluptatibus qui itaque sint dolorum quia
                                            praesentium veniam. Exercitationem, architecto natus! Aut dignissimos
                                            provident dolorem est enim voluptatum, similique error, odit esse beatae
                                            qui, totam illo sint? Ab, iure pariatur sint consequuntur saepe ipsa vero
                                            voluptates fugiat a, exercitationem veniam asperiores ut odit. Dolore libero
                                            aperiam, ea eligendi mollitia asperiores repellendus blanditiis iusto sit
                                            ipsum alias voluptas consequatur dolor voluptates. Cupiditate reprehenderit
                                            repellat distinctio voluptatibus enim, dolore consequuntur possimus, rerum
                                            incidunt, eaque iusto.</p>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero veritatis
                                            molestias ut assumenda sapiente? Facere culpa earum eaque, molestias autem
                                            odio magni, blanditiis nemo facilis expedita sint, quo maxime dignissimos.
                                            Quam consequuntur corporis maiores reprehenderit odio voluptates maxime,
                                            cumque, adipisci minus quis esse labore alias quas consequatur voluptas, hic
                                            eius animi nesciunt distinctio pariatur sunt id dolor iure. Officiis
                                            doloribus nihil obcaecati ullam. Doloremque at animi magnam? Est, sit autem!
                                        </p>
                                        <p>We are not responsible for the content or privacy practices on any websites.
                                        </p>
                                        <p>We reserve the right, in our sole discretion, to modify, update, add to,
                                            discontinue, remove or otherwise change any portion of this Privacy Policy,
                                            in whole or in part, at any time. When we amend this Privacy Policy, we will
                                            revise the “last updated” date located at the top of this Privacy Policy.
                                        </p>
                                        <p>If you provide information to us or access or use the Website in any way
                                            after this Privacy Policy has been changed, you will be deemed to have
                                            unconditionally consented and agreed to such changes. The most current
                                            version of this Privacy Policy will be available on the Website and will
                                            supersede all previous versions of this Privacy Policy.</p>
                                        <p>If you have any questions regarding this Privacy Policy, you should contact
                                            our Customer Service Department by email at marketing@company.com</p>
                                    </div>
                                </div>
                                <div className="widget-content-inner">
                                    <ul className="d-flex justify-content-center mb_18">
                                        <li className="">
                                            <svg viewBox="0 0 40 40" width="35px" height="35px" color="#222"
                                                margin="5px">
                                                <path fill="currentColor"
                                                    d="M8.7 30.7h22.7c.3 0 .6-.2.7-.6l4-25.3c-.1-.4-.3-.7-.7-.8s-.7.2-.8.6L34 8.9l-3-1.1c-2.4-.9-5.1-.5-7.2 1-2.3 1.6-5.3 1.6-7.6 0-2.1-1.5-4.8-1.9-7.2-1L6 8.9l-.7-4.3c0-.4-.4-.7-.7-.6-.4.1-.6.4-.6.8l4 25.3c.1.3.3.6.7.6zm.8-21.6c2-.7 4.2-.4 6 .8 1.4 1 3 1.5 4.6 1.5s3.2-.5 4.6-1.5c1.7-1.2 4-1.6 6-.8l3.3 1.2-3 19.1H9.2l-3-19.1 3.3-1.2zM32 32H8c-.4 0-.7.3-.7.7s.3.7.7.7h24c.4 0 .7-.3.7-.7s-.3-.7-.7-.7zm0 2.7H8c-.4 0-.7.3-.7.7s.3.6.7.6h24c.4 0 .7-.3.7-.7s-.3-.6-.7-.6zm-17.9-8.9c-1 0-1.8-.3-2.4-.6l.1-2.1c.6.4 1.4.6 2 .6.8 0 1.2-.4 1.2-1.3s-.4-1.3-1.3-1.3h-1.3l.2-1.9h1.1c.6 0 1-.3 1-1.3 0-.8-.4-1.2-1.1-1.2s-1.2.2-1.9.4l-.2-1.9c.7-.4 1.5-.6 2.3-.6 2 0 3 1.3 3 2.9 0 1.2-.4 1.9-1.1 2.3 1 .4 1.3 1.4 1.3 2.5.3 1.8-.6 3.5-2.9 3.5zm4-5.5c0-3.9 1.2-5.5 3.2-5.5s3.2 1.6 3.2 5.5-1.2 5.5-3.2 5.5-3.2-1.6-3.2-5.5zm4.1 0c0-2-.1-3.5-.9-3.5s-1 1.5-1 3.5.1 3.5 1 3.5c.8 0 .9-1.5.9-3.5zm4.5-1.4c-.9 0-1.5-.8-1.5-2.1s.6-2.1 1.5-2.1 1.5.8 1.5 2.1-.5 2.1-1.5 2.1zm0-.8c.4 0 .7-.5.7-1.2s-.2-1.2-.7-1.2-.7.5-.7 1.2.3 1.2.7 1.2z">
                                                </path>
                                            </svg>
                                        </li>
                                        <li className="">
                                            <svg viewBox="0 0 40 40" width="35px" height="35px" color="#222"
                                                margin="5px">
                                                <path fill="currentColor"
                                                    d="M36.7 31.1l-2.8-1.3-4.7-9.1 7.5-3.5c.4-.2.6-.6.4-1s-.6-.5-1-.4l-7.5 3.5-7.8-15c-.3-.5-1.1-.5-1.4 0l-7.8 15L4 15.9c-.4-.2-.8 0-1 .4s0 .8.4 1l7.5 3.5-4.7 9.1-2.8 1.3c-.4.2-.6.6-.4 1 .1.3.4.4.7.4.1 0 .2 0 .3-.1l1-.4-1.5 2.8c-.1.2-.1.5 0 .8.1.2.4.3.7.3h31.7c.3 0 .5-.1.7-.4.1-.2.1-.5 0-.8L35.1 32l1 .4c.1 0 .2.1.3.1.3 0 .6-.2.7-.4.1-.3 0-.8-.4-1zm-5.1-2.3l-9.8-4.6 6-2.8 3.8 7.4zM20 6.4L27.1 20 20 23.3 12.9 20 20 6.4zm-7.8 15l6 2.8-9.8 4.6 3.8-7.4zm22.4 13.1H5.4L7.2 31 20 25l12.8 6 1.8 3.5z">
                                                </path>
                                            </svg>
                                        </li>
                                        <li className="">
                                            <svg viewBox="0 0 40 40" width="35px" height="35px" color="#222"
                                                margin="5px">
                                                <path fill="currentColor"
                                                    d="M5.9 5.9v28.2h28.2V5.9H5.9zM19.1 20l-8.3 8.3c-2-2.2-3.2-5.1-3.2-8.3s1.2-6.1 3.2-8.3l8.3 8.3zm-7.4-9.3c2.2-2 5.1-3.2 8.3-3.2s6.1 1.2 8.3 3.2L20 19.1l-8.3-8.4zM20 20.9l8.3 8.3c-2.2 2-5.1 3.2-8.3 3.2s-6.1-1.2-8.3-3.2l8.3-8.3zm.9-.9l8.3-8.3c2 2.2 3.2 5.1 3.2 8.3s-1.2 6.1-3.2 8.3L20.9 20zm8.4-10.2c-1.2-1.1-2.6-2-4.1-2.6h6.6l-2.5 2.6zm-18.6 0L8.2 7.2h6.6c-1.5.6-2.9 1.5-4.1 2.6zm-.9.9c-1.1 1.2-2 2.6-2.6 4.1V8.2l2.6 2.5zM7.2 25.2c.6 1.5 1.5 2.9 2.6 4.1l-2.6 2.6v-6.7zm3.5 5c1.2 1.1 2.6 2 4.1 2.6H8.2l2.5-2.6zm18.6 0l2.6 2.6h-6.6c1.4-.6 2.8-1.5 4-2.6zm.9-.9c1.1-1.2 2-2.6 2.6-4.1v6.6l-2.6-2.5zm2.6-14.5c-.6-1.5-1.5-2.9-2.6-4.1l2.6-2.6v6.7z">
                                                </path>
                                            </svg>
                                        </li>
                                        <li className="">
                                            <svg viewBox="0 0 40 40" width="35px" height="35px" color="#222"
                                                margin="5px">
                                                <path fill="currentColor"
                                                    d="M35.1 33.6L33.2 6.2c0-.4-.3-.7-.7-.7H13.9c-.4 0-.7.3-.7.7s.3.7.7.7h18l.7 10.5H20.8c-8.8.2-15.9 7.5-15.9 16.4 0 .4.3.7.7.7h28.9c.2 0 .4-.1.5-.2s.2-.3.2-.5v-.2h-.1zm-28.8-.5C6.7 25.3 13 19 20.8 18.9h11.9l1 14.2H6.3zm11.2-6.8c0 1.2-1 2.1-2.1 2.1s-2.1-1-2.1-2.1 1-2.1 2.1-2.1 2.1 1 2.1 2.1zm6.3 0c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1s1-2.1 2.1-2.1 2.1 1 2.1 2.1z">
                                                </path>
                                            </svg>
                                        </li>
                                        <li className="">
                                            <svg viewBox="0 0 40 40" width="35px" height="35px" color="#222"
                                                margin="5px">
                                                <path fill="currentColor"
                                                    d="M20 33.8c7.6 0 13.8-6.2 13.8-13.8S27.6 6.2 20 6.2 6.2 12.4 6.2 20 12.4 33.8 20 33.8zm0-26.3c6.9 0 12.5 5.6 12.5 12.5S26.9 32.5 20 32.5 7.5 26.9 7.5 20 13.1 7.5 20 7.5zm-.4 15h.5c1.8 0 3-1.1 3-3.7 0-2.2-1.1-3.6-3.1-3.6h-2.6v10.6h2.2v-3.3zm0-5.2h.4c.6 0 .9.5.9 1.7 0 1.1-.3 1.7-.9 1.7h-.4v-3.4z">
                                                </path>
                                            </svg>
                                        </li>
                                        <li className="">
                                            <svg viewBox="0 0 40 40" width="35px" height="35px" color="#222"
                                                margin="5px">
                                                <path fill="currentColor"
                                                    d="M30.2 29.3c2.2-2.5 3.6-5.7 3.6-9.3s-1.4-6.8-3.6-9.3l3.6-3.6c.3-.3.3-.7 0-.9-.3-.3-.7-.3-.9 0l-3.6 3.6c-2.5-2.2-5.7-3.6-9.3-3.6s-6.8 1.4-9.3 3.6L7.1 6.2c-.3-.3-.7-.3-.9 0-.3.3-.3.7 0 .9l3.6 3.6c-2.2 2.5-3.6 5.7-3.6 9.3s1.4 6.8 3.6 9.3l-3.6 3.6c-.3.3-.3.7 0 .9.1.1.3.2.5.2s.3-.1.5-.2l3.6-3.6c2.5 2.2 5.7 3.6 9.3 3.6s6.8-1.4 9.3-3.6l3.6 3.6c.1.1.3.2.5.2s.3-.1.5-.2c.3-.3.3-.7 0-.9l-3.8-3.6z">
                                                </path>
                                            </svg>
                                        </li>
                                        <li className="">
                                            <svg viewBox="0 0 40 40" width="35px" height="35px" color="#222"
                                                margin="5px">
                                                <path fill="currentColor"
                                                    d="M34.1 34.1H5.9V5.9h28.2v28.2zM7.2 32.8h25.6V7.2H7.2v25.6zm13.5-18.3a.68.68 0 0 0-.7-.7.68.68 0 0 0-.7.7v10.9a.68.68 0 0 0 .7.7.68.68 0 0 0 .7-.7V14.5z">
                                                </path>
                                            </svg>
                                        </li>
                                    </ul>
                                    <p className="text-center text-paragraph">LT01: 70% wool, 15% polyester, 10% polyamide,
                                        5% acrylic 900 Grms/mt</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        <section className="flat-spacing-4 pt_0">
            <div className="container">
                <div className="flat-title">
                    <span className="title">Recently Viewed</span>
                </div>
                <div className="hover-sw-nav hover-sw-2">
                    <div className="swiper tf-sw-recent wrap-sw-over" data-preview="4" data-tablet="3" data-mobile="2"
                        data-space-lg="30" data-space-md="30" data-space="15" data-pagination="1" data-pagination-md="1"
                        data-pagination-lg="1">
                        <div className="swiper-wrapper">
                        <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={1500}
                                removeArrowOnDeviceType={["tablet", "mobile","desktop"]}                                                               
                                deviceType="desktop"
                                itemClass="carousel-item-margin-10-px"
                            >

                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="javascript:void(0);"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Light Green</span>
                                                <span className="swatch-value bg_light-green"></span>
                                                <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                    src="images/products/light-green-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-3.jpg"
                                                    src="images/products/black-3.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Blue</span>
                                                <span className="swatch-value bg_blue-2"></span>
                                                <img className="lazyload" data-src="images/products/blue.jpg"
                                                    src="images/products/blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Dark Blue</span>
                                                <span className="swatch-value bg_dark-blue"></span>
                                                <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                    src="images/products/dark-blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-6.jpg"
                                                    src="images/products/white-6.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Light Grey</span>
                                                <span className="swatch-value bg_light-grey"></span>
                                                <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                    src="images/products/light-grey.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="javascript:void(0);"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Light Green</span>
                                                <span className="swatch-value bg_light-green"></span>
                                                <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                    src="images/products/light-green-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-3.jpg"
                                                    src="images/products/black-3.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Blue</span>
                                                <span className="swatch-value bg_blue-2"></span>
                                                <img className="lazyload" data-src="images/products/blue.jpg"
                                                    src="images/products/blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Dark Blue</span>
                                                <span className="swatch-value bg_dark-blue"></span>
                                                <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                    src="images/products/dark-blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-6.jpg"
                                                    src="images/products/white-6.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Light Grey</span>
                                                <span className="swatch-value bg_light-grey"></span>
                                                <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                    src="images/products/light-grey.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="javascript:void(0);"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Light Green</span>
                                                <span className="swatch-value bg_light-green"></span>
                                                <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                    src="images/products/light-green-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-3.jpg"
                                                    src="images/products/black-3.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Blue</span>
                                                <span className="swatch-value bg_blue-2"></span>
                                                <img className="lazyload" data-src="images/products/blue.jpg"
                                                    src="images/products/blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Dark Blue</span>
                                                <span className="swatch-value bg_dark-blue"></span>
                                                <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                    src="images/products/dark-blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-6.jpg"
                                                    src="images/products/white-6.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Light Grey</span>
                                                <span className="swatch-value bg_light-grey"></span>
                                                <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                    src="images/products/light-grey.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="javascript:void(0);"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Light Green</span>
                                                <span className="swatch-value bg_light-green"></span>
                                                <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                    src="images/products/light-green-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-3.jpg"
                                                    src="images/products/black-3.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Blue</span>
                                                <span className="swatch-value bg_blue-2"></span>
                                                <img className="lazyload" data-src="images/products/blue.jpg"
                                                    src="images/products/blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Dark Blue</span>
                                                <span className="swatch-value bg_dark-blue"></span>
                                                <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                    src="images/products/dark-blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-6.jpg"
                                                    src="images/products/white-6.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Light Grey</span>
                                                <span className="swatch-value bg_light-grey"></span>
                                                <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                    src="images/products/light-grey.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="javascript:void(0);"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Light Green</span>
                                                <span className="swatch-value bg_light-green"></span>
                                                <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                    src="images/products/light-green-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-3.jpg"
                                                    src="images/products/black-3.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Blue</span>
                                                <span className="swatch-value bg_blue-2"></span>
                                                <img className="lazyload" data-src="images/products/blue.jpg"
                                                    src="images/products/blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Dark Blue</span>
                                                <span className="swatch-value bg_dark-blue"></span>
                                                <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                    src="images/products/dark-blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-6.jpg"
                                                    src="images/products/white-6.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Light Grey</span>
                                                <span className="swatch-value bg_light-grey"></span>
                                                <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                    src="images/products/light-grey.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="javascript:void(0);"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Light Green</span>
                                                <span className="swatch-value bg_light-green"></span>
                                                <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                    src="images/products/light-green-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-3.jpg"
                                                    src="images/products/black-3.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Blue</span>
                                                <span className="swatch-value bg_blue-2"></span>
                                                <img className="lazyload" data-src="images/products/blue.jpg"
                                                    src="images/products/blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Dark Blue</span>
                                                <span className="swatch-value bg_dark-blue"></span>
                                                <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                    src="images/products/dark-blue.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-6.jpg"
                                                    src="images/products/white-6.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Light Grey</span>
                                                <span className="swatch-value bg_light-grey"></span>
                                                <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                    src="images/products/light-grey.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
</Carousel>
                        </div>
                    </div>
                    <div className="nav-sw nav-next-slider nav-next-recent box-icon w_46 round"><span
                            className="icon icon-arrow-left"></span></div>
                    <div className="nav-sw nav-prev-slider nav-prev-recent box-icon w_46 round"><span
                            className="icon icon-arrow-right"></span></div>
                    <div className="sw-dots style-2 sw-pagination-recent justify-content-center"></div>
                </div>
            </div>
        </section> 
    </>
  )
}
