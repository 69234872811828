import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function MyAccountWishlist() {
  return (
    <>
     <div className="tf-page-title">
            <div className="container-full">
                <div className="heading text-center">Wishlist</div>
            </div>
        </div>
        <section className="flat-spacing-11">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <ul className="my-account-nav">
                            <li><Link to="my-account" className="my-account-nav-item ">Dashboard</Link></li>
                            <li><Link to="my-account-orders" className="my-account-nav-item">Orders</Link></li>
                            <li><Link to="my-account-address" className="my-account-nav-item">Addresses</Link></li>
                            <li><Link to="my-account-edit" className="my-account-nav-item">Account Details</Link></li>
                            <li><span className="my-account-nav-item active">Wishlist</span></li>
                            <li><Link to="login" className="my-account-nav-item">Logout</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-9">
                        <div className="my-account-content account-wishlist">
                            <div className="grid-layout wrapper-shop" data-grid="grid-3">
                                
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bestsl02.jpg"
                                                src="myimg/bestsl02.jpg" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bestsl02.jpg"
                                                src="myimg/bestsl02.jpg" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bestsl02.jpg"
                                                src="myimg/bestsl02.jpg" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bestsl02.jpg"
                                                src="myimg/bestsl02.jpg" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bestsl02.jpg"
                                                src="myimg/bestsl02.jpg" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bestsl02.jpg"
                                                src="myimg/bestsl02.jpg" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Babydoll Dress</Link>
                                        <span className="price">₹ 1,999</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
