import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function Contact() {
  return (
    <>
     <div className="tf-page-title style-2">
            <div className="container-full">
                <div className="heading text-center">Contact Us</div>
            </div>
        </div>
        <div className="w-100">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448196.52633258584!2d76.76357436215979!3d28.64368462633545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1725363683376!5m2!1sen!2sin"
                width="100%" height="646" style={{border:"0"}} allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
        <section className="flat-spacing-21">
            <div className="container">
                <div className="tf-grid-layout gap30 lg-col-2">
                    <div className="tf-content-left">
                        <h5 className="mb_20">Visit Our Store</h5>
                        <div className="mb_20">
                            <p className="mb_15"><strong>Address</strong></p>
                            <p>66 CP, Karol Bagh, New Delhi, Zip Code: 110041</p>
                        </div>
                        <div className="mb_20">
                            <p className="mb_15"><strong>Phone</strong></p>
                            <p>7894561237</p>
                        </div>
                        <div className="mb_20">
                            <p className="mb_15"><strong>Email</strong></p>
                            <p>nicky@lingerie.com</p>
                        </div>

                        <div>
                            <ul className="tf-social-icon d-flex gap-20 style-default">
                                <li><Link to="#" className="box-icon link round social-facebook border-line-black"><i
                                            className="icon fs-14 icon-fb"></i></Link></li>
                                <li><Link to="#" className="box-icon link round social-twiter border-line-black"><i
                                            className="icon fs-12 icon-Icon-x"></i></Link></li>
                                <li><Link to="#" className="box-icon link round social-instagram border-line-black"><i
                                            className="icon fs-14 icon-instagram"></i></Link></li>
                                <li><Link to="#" className="box-icon link round social-tiktok border-line-black"><i
                                            className="icon fs-14 icon-tiktok"></i></Link></li>
                                <li><Link to="#" className="box-icon link round social-pinterest border-line-black"><i
                                            className="icon fs-14 icon-pinterest-1"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="tf-content-right">
                        <h5 className="mb_20">Get in Touch</h5>
                        <p className="mb_24">If you’ve got great products your making or looking to work with us then drop
                            us a line.</p>
                        <div>
                            <form className="form-contact" id="contactform" action="./contact/contact-process.php"
                                method="post">
                                <div className="d-flex gap-15 mb_15">
                                    <fieldset className="w-100">
                                        <input type="text" name="name" id="name" required placeholder="Name *" />
                                    </fieldset>
                                    <fieldset className="w-100">
                                        <input type="email" name="email" id="email" required placeholder="Email *" />
                                    </fieldset>
                                </div>
                                <div className="mb_15">
                                    <textarea placeholder="Message" name="message" id="message" required cols="30"
                                        rows="10"></textarea>
                                </div>
                                <div className="send-wrap">
                                    <button type="submit"
                                        className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
