import React, {useState, useEffect} from 'react'
import { Link, useParams } from "react-router-dom";
import axios from "axios";
//import InfiniteScroll from "react-infinite-scroll-component";


export default function Products(props) {

    const {id,search, subcategory} = useParams();
    const [products, setProducts] = useState([]);
    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [progress, setProgress] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [itemsize, setItemsize] = useState(9);
    let url = "";
    
    useEffect(() => {
        productlist();
      }, [props]);
      
      if(id!='' && id!== undefined)
        {
            
            url=`https://gobusinessserver.in/nikki-apis/products.php?id=${id}&itemsize=${itemsize}`; 
            console.log(url); 
        }
        else if(search!='' && search!== undefined)
            {
                url=`https://gobusinessserver.in/nikki-apis/products.php?search=${search}&itemsize=${itemsize}`;
            }
        else{        
            url=`https://gobusinessserver.in/nikki-apis/products.php?itemsize=${itemsize}`;
        }
      const productlist = () => {
        
          setProgress(progress + 0);
        axios
          .get(url)
          .then(function (response) {
            
              setProducts(response.data.res.products);
              setColors(response.data.res.colors);
              setPhotos(response.data.res.photos);
              setSizes(response.data.res.sizes);
              setProgress(progress + 100);
              setItemsize(itemsize + 9);
              if(response.data.res.length == response.data.total)
              {
                  setHasMore(false);
              }
              else{                
                  setHasMore(true);
              }
              
              
          })
          .catch(function (error) {});
      };

  return (
    <>
      
          {/* <InfiniteScroll
          dataLength={products.length}
          next={productlist}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        > */}
    
{products.length > 0 ? products.map((object, i) => {
                
                return(

<div className="card-product" key={i}>
                        <div  className="card-product-wrapper">
                            <Link to={`/product-detail/${object.id}`} className="product-img">
                                <img className="lazyload img-product" data-src={`https://gobusinessserver.in/nikkitrader/img/${object.image}`} src={`https://gobusinessserver.in/nikkitrader/img/${object.image}`}
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src={`https://gobusinessserver.in/nikkitrader/img/${object.image}`} src={`https://gobusinessserver.in/nikkitrader/img/${object.image}`}
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag" style={{color: "#440001"}}></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>{sizes[i]} sizes available</span> 
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to={`/product-detail/${object.id}`} className="title link">{object.name}</Link>
                            <span className="price"><s className="canprice">₹ {object.offerprice}</s> ₹ {object.price}

                            </span>
                            <ul className="list-color-product">
                            {colors[i].map((objectss, ii) => {
                               
                return(
                    <li key={ii} className="list-color-item color-swatch" style={{ background:objectss.title }}>
                     <span className="tooltip">{objectss.title}</span>
                    <span className={`swatch-value bg_${objectss.title}-3`} style={{ border:objectss.title }}></span>
                    {/* <img className="lazyload" data-src={`https://gobusinessserver.in/nikkitrader/img/${photos[i][ii].image}`}
                        src={`https://gobusinessserver.in/nikkitrader/img/${photos[i][ii].image}`} alt="image-product"/> */}
                </li>
                )
                })} 
                                
                                {/* <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                );
                
                
            }) : (
                <center style={{margin:"auto"}}><h4>No data found!</h4></center>
              )} 


        {/* </InfiniteScroll> */}


    </>
  )
}
