import React, { useEffect,useState } from 'react'
import { Link,useNavigate } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
        slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const responsive1 = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const responsive2 = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};


const responsive3 = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function Home(props) {

    //const(window.sessionStorage.getItem('cats'));
    
      
  return (
    <>
        <div className="tf-slideshow slider-home-2 slider-effect-fade position-relative">
            <div className="swiper tf-sw-slideshow" data-preview="1" data-tablet="1" data-mobile="1" data-centered="false"
                data-space="0" data-loop="true" data-auto-play="true" data-delay="2000" data-speed="1000">
                <div className="swiper-wrapper">
                <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                responsive={responsive1}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={1500}
                                removeArrowOnDeviceType={["tablet", "mobile","desktop"]}                                
                                dotListClass="custom-dot-list-style"
                                deviceType="desktop"
                                itemClass="carousel-item-margin-10-px"
                            >
                    <div className="swiper-slide" lazy="true">
                        <div className="wrap-slider">
                            <img className="lazyload" data-src="myimg/ban1.jpg" src="myimg/ban1.jpg"
                                alt="fashion-slideshow-01"/>
                            {/* <!-- <div className="box-content">
                                <div className="container">
                                    <h1 className="fade-item fade-item-1">Summer Style<br>Sensations</h1>
                                    <p className="fade-item fade-item-2">Discover the hottest trends and must-have looks</p>
                                    <Link to="shop-default"
                                        className="fade-item fade-item-3 tf-btn btn-fill animate-hover-btn btn-xl radius-3"><span>Shop
                                            collection</span><i className="icon icon-arrow-right"></i></Link>
                                </div>
                            </div> --> */}
                        </div>
                    </div>
                    <div className="swiper-slide" lazy="true">
                        <div className="wrap-slider">
                            <img className="lazyload" data-src="myimg/ban4 - Copy.png" src="myimg/ban4 - Copy.png"
                                alt="fashion-slideshow-02"/>
                            {/* <!-- <div className="box-content">
                                <div className="container">
                                    <h1 className="fade-item fade-item-1">Youthful<br>Summer style</h1>
                                    <p className="fade-item fade-item-2">Discover the hottest trends and must-have looks</p>
                                    <Link to="shop-default"
                                        className="fade-item fade-item-3 tf-btn btn-fill animate-hover-btn btn-xl radius-3"><span>Shop
                                            collection</span><i className="icon icon-arrow-right"></i></Link>
                                </div>

                            </div> --> */}
                        </div>
                    </div>
                    <div className="swiper-slide" lazy="true">
                        <div className="wrap-slider">
                            <img className="lazyload" data-src="myimg/ban5.png" src="myimg/ban5.png"
                                alt="fashion-slideshow-03"/>
                            {/* <!-- <div className="box-content">
                                <div className="container">
                                    <h1 className="fade-item fade-item-1">Gentle<br>Summer style</h1>
                                    <p className="fade-item fade-item-2">Discover the hottest trends and must-have looks</p>
                                    <Link to="shop-default"
                                        className="fade-item fade-item-3 tf-btn btn-fill animate-hover-btn btn-xl radius-3"><span>Shop
                                            collection</span><i className="icon icon-arrow-right"></i></Link>
                                </div>

                            </div> --> */}
                        </div>
                    </div>
                                </Carousel>
                </div>
            </div>
            <div className="wrap-pagination sw-absolute-2">
                <div className="container">
                    <div className="sw-dots sw-pagination-slider"></div>
                </div>
            </div>
        </div>
       
        <section className="flat-spacing-12 bg_grey-3">
            <div className="container">

                {/* <!-- <div className="head-bg">
                    <img src="myimg/hdsecbg.jpg" alt="">
                    <div className="flat-title wow fadeInUp" data-wow-delay="0s">
                        <span className="title">Shop by Categories</span>
                        <p className="sub-title">Explore our categories and dive into the world of nicky traders</p>
                    </div>
                </div> --> */}

                <div className="col-12 cls hero-section flat-title wow fadeInUp" data-wow-delay="0s">
                    <h1>Shop By Categories</h1>
                    {/* <!-- <p>Dive into the world of lingeries.</p> --> */}
                </div>


                <div className="hover-sw-nav hover-sw-2">
                    <div className="swiper tf-sw-collection" data-preview="6" data-tablet="3" data-mobile="2"
                        data-space-lg="50" data-space-md="30" data-space="15" data-loop="false" data-auto-play="true">
                        <div className="swiper-wrapper">
                        <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={1500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["desktop"]}
                                deviceType="desktop"
                                itemClass="carousel-item-margin-10-px"
                            >
                                 {props.catlist.map((object, i) => {
                
                return(
                            <div key={i} className="swiper-slide" lazy="true">
                                <div className="collection-item-circle hover-img">
                                    <Link to={`category-inner/${object.seo}/${object.id}`} className="collection-image img-style">
                                        <img className="lazyload" data-src={`https://gobusinessserver.in/nikkitrader/img/${object.image}`} src={`https://gobusinessserver.in/nikkitrader/img/${object.image}`}
                                            alt="collection-img"/>
                                    </Link>
                                    <div className="collection-content text-center">
                                        <Link to={`/category-inner/${object.seo}/${object.id}`} className="link title fw-5">{object.name}</Link>

                                    </div>
                                </div>
                            </div>
                            );
                        })}  
                           
                            </Carousel>
                        </div>
                    </div>
                    {/* <div className="sw-dots style-2 sw-pagination-collection justify-content-center"></div> */}
                    <div className="nav-sw nav-next-slider nav-next-collection"><span className="icon icon-arrow-left"></span>
                    </div>
                    <div className="nav-sw nav-prev-slider nav-prev-collection"><span className="icon icon-arrow-right"></span>
                    </div>
                </div>
            </div>
        </section>
      
        <section>
            <div className="container">
                <div className="swiper tf-sw-recent" data-preview="3" data-tablet="3" data-mobile="1.3" data-space-lg="30"
                    data-space-md="15" data-space="15" data-pagination="1" data-pagination-md="1"
                    data-pagination-lg="1">
                    <div className='row'>
                        <div className="col-md-4" lazy="true">
                            <div className="collection-item-v4 lg hover-img">
                                <div className="collection-inner">
                                    <Link to="#" className="radius-20 collection-image img-style">

                                        <video width="100%" height="100%" autoPlay playsInline loop muted className="lazyload"
                                            data-src="myimg/vid2.mp4" src="myimg/vid2.mp4"></video>
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading text_white">Lingeriee Collection</p>
                                        <h5 className="heading text_white fw-6">Designed for All Occasions</h5>
                                        <Link to="shop-collection-sub"
                                            className="rounded-full tf-btn style-3 fw-6 btn-light-icon animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" lazy="true">
                            <div className="collection-item-v4 lg hover-img">
                                <div className="collection-inner">
                                    <Link to="#" className="radius-20 collection-image img-style">


                                        <video width="100%" height="100%" autoPlay playsInline loop muted
                                            data-src="myimg/bravid.mp4" src="myimg/bravid.mp4"></video>
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading text_white">30% OFF</p>
                                        <h5 className="heading text_white fw-6">Comfort, Unlimited Fashion</h5>
                                        <Link to="shop-collection-sub"
                                            className="rounded-full tf-btn style-3 fw-6 btn-light-icon animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" lazy="true">
                            <div className="collection-item-v4 lg hover-img">
                                <div className="collection-inner">
                                    <Link to="#" className="radius-20 collection-image img-style">

                                        <video width="100%" height="100%" autoPlay playsInline loop muted className="lazyload"
                                            data-src="myimg/vid3.mp4" src="myimg/vid3.mp4"></video>
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading text_white">FREE SHIPPING</p>
                                        <h5 className="heading text_white fw-6">Iconic design and brilliant Quality</h5>
                                        <Link to="shop-collection-sub"
                                            className="rounded-full tf-btn style-3 fw-6 btn-light-icon animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        <section className="flat-spacing-15 pb_0">
            <div className="container">
                <div className="col-12 cls hero-section flat-title wow fadeInUp" data-wow-delay="0s">
                    <h1>Our Bestseller's</h1>
                    <p>Beautifully & Purposefully Designed Lingeries.</p>
                </div>
                {/* <!-- <div className="flat-title wow fadeInUp" data-wow-delay="0s">
                    <span className="title" style="font-weight: 600;">Our Bestseller's</span>
                    <p className="sub-title">Beautifully & Purposefully Designed Lingeries.</p>
                </div> --> */}
                <div className="hover-sw-nav hover-sw-3">
                    <div className="swiper tf-sw-product-sell wrap-sw-over" data-preview="4" data-tablet="3" data-mobile="2"
                        data-space-lg="30" data-space-md="15" data-pagination="2" data-pagination-md="3"
                        data-pagination-lg="3" data-auto-play="true">
                        <div className="swiper-wrapper">
                        <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive2}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={1500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["desktop"]}
                                deviceType="desktop"
                                itemClass="carousel-item-margin-10-px"
                            >
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="#" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bestsell1.jpg"
                                                src="myimg/bestsell1.jpg" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bestsell01.jpg"
                                                src="myimg/bestsell01.jpg" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag" style={{color: "#440001"}}></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>


                                        </div>
                                        <div className="size-list">
                                            <span>4 sizes available</span>
                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Premium Roleplay Set</Link>
                                        <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799

                                        </span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bestsl2.png"
                                                src="myimg/bestsl2.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bestsl02.jpg"
                                                src="myimg/bestsl02.jpg" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>


                                        </div>
                                        <div className="size-list">
                                            <span>4 sizes available</span>
                                        </div>
                                        <div className="on-sale-wrap">
                                            <div className="on-sale-item">-33%</div>
                                        </div>
                                        <div className="countdown-box">
                                            <div className="js-countdown" data-timer="1007500" data-labels="d :,h :,m :,s">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">3 piece Bikini Set</Link>
                                        <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                            <ul className="list-color-product">
                                                <li className="list-color-item color-swatch active">
                                                    <span className="tooltip">Brown</span>
                                                    <span className="swatch-value bg_brown"></span>
                                                    <img className="lazyload" data-src="images/products/brown.jpg"
                                                        src="images/products/brown.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Light Purple</span>
                                                    <span className="swatch-value bg_purple"></span>
                                                    <img className="lazyload" data-src="images/products/purple.jpg"
                                                        src="images/products/purple.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Light Green</span>
                                                    <span className="swatch-value bg_light-green"></span>
                                                    <img className="lazyload" data-src="images/products/green.jpg"
                                                        src="images/products/green.jpg" alt="image-product"/>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl3.jpg"
                                                src="myimg/bstsl3.jpg" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl03.jpg"
                                                src="myimg/bstsl03.jpg" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn absolute-2">
                                            <Link to="#shoppingCart" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Add to cart</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                            {/* <!-- <Link to="#quick_view" data-bs-toggle="modal"
                                                className="box-icon bg_white quickview tf-btn-loading">
                                                <span className="icon icon-view"></span>
                                                <span className="tooltip">Quick View</span>
                                            </Link> --> */}
                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title link">Floral Overbust Corset</Link>
                                        <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>


                                        </div>
                                        <div className="size-list">
                                            <span>4 sizes available</span>
                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title">Babydoll Dresses</Link>
                                        <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                            <ul className="list-color-product">
                                                <li className="list-color-item color-swatch active">
                                                    <span className="tooltip">White</span>
                                                    <span className="swatch-value bg_white"></span>
                                                    <img className="lazyload" data-src="images/products/white-2.jpg"
                                                        src="images/products/white-2.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Pink</span>
                                                    <span className="swatch-value bg_purple"></span>
                                                    <img className="lazyload" data-src="images/products/pink-1.jpg"
                                                        src="images/products/pink-1.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Black</span>
                                                    <span className="swatch-value bg_dark"></span>
                                                    <img className="lazyload" data-src="images/products/black-2.jpg"
                                                        src="images/products/black-2.jpg" alt="image-product"/>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>

                                        </div>
                                        <div className="size-list">
                                            <span>4 sizes available</span>
                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title">Babydoll Dresses</Link>
                                        <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                            <ul className="list-color-product">
                                                <li className="list-color-item color-swatch active">
                                                    <span className="tooltip">White</span>
                                                    <span className="swatch-value bg_white"></span>
                                                    <img className="lazyload" data-src="images/products/white-2.jpg"
                                                        src="images/products/white-2.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Pink</span>
                                                    <span className="swatch-value bg_purple"></span>
                                                    <img className="lazyload" data-src="images/products/pink-1.jpg"
                                                        src="images/products/pink-1.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Black</span>
                                                    <span className="swatch-value bg_dark"></span>
                                                    <img className="lazyload" data-src="images/products/black-2.jpg"
                                                        src="images/products/black-2.jpg" alt="image-product"/>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" lazy="true">
                                <div className="card-product">
                                    <div className="card-product-wrapper">
                                        <Link to="product-detail" className="product-img">
                                            <img className="lazyload img-product" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                            <img className="lazyload img-hover" data-src="myimg/bstsl4.png"
                                                src="myimg/bstsl4.png" alt="image-product"/>
                                        </Link>
                                        <div className="list-product-btn">
                                            <Link to="#quick_add" data-bs-toggle="modal"
                                                className="box-icon bg_white quick-add tf-btn-loading">
                                                <span className="icon icon-bag"></span>
                                                <span className="tooltip">Quick Add</span>
                                            </Link>
                                            <Link to="#"
                                                className="box-icon bg_white wishlist btn-icon-action">
                                                <span className="icon icon-heart"></span>
                                                <span className="tooltip">Add to Wishlist</span>
                                                <span className="icon icon-delete"></span>
                                            </Link>


                                        </div>
                                        <div className="size-list">
                                            <span>4 sizes available</span>
                                        </div>
                                    </div>
                                    <div className="card-product-info">
                                        <Link to="product-detail" className="title">Babydoll Dresses</Link>
                                        <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                            <ul className="list-color-product">
                                                <li className="list-color-item color-swatch active">
                                                    <span className="tooltip">White</span>
                                                    <span className="swatch-value bg_white"></span>
                                                    <img className="lazyload" data-src="images/products/white-2.jpg"
                                                        src="images/products/white-2.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Pink</span>
                                                    <span className="swatch-value bg_purple"></span>
                                                    <img className="lazyload" data-src="images/products/pink-1.jpg"
                                                        src="images/products/pink-1.jpg" alt="image-product"/>
                                                </li>
                                                <li className="list-color-item color-swatch">
                                                    <span className="tooltip">Black</span>
                                                    <span className="swatch-value bg_dark"></span>
                                                    <img className="lazyload" data-src="images/products/black-2.jpg"
                                                        src="images/products/black-2.jpg" alt="image-product"/>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            </Carousel>
                        </div>
                    </div>
                    <div className="nav-sw nav-next-slider nav-next-product box-icon w_46 round"><span
                            className="icon icon-arrow-left"></span></div>
                    <div className="nav-sw nav-prev-slider nav-prev-product box-icon w_46 round"><span
                            className="icon icon-arrow-right"></span></div>
                </div>
            </div>
        </section>
      
        <section className="flat-spacing-8 pb_0 pt-5 banner-section">
            <div className="container">
                <div className="tf-banner-collection">
                    <img className="lazyload" data-src="myimg/ctabanner.jpg" src="myimg/ctabanner.jpg" alt="img-banner"
                        loading="lazy"/>
                    <div className="box-content">
                        <div className="container wow fadeInUp" data-wow-delay="0s">
                            <div className="sub fw-7 text_white">Get 20% off</div>
                            <h2 className="heading fw-6 text_white">Garter Sets</h2>
                            <p className="text_white">Newly Launched, Best Quality Garter Sets</p>
                            <Link to="shop-default"
                                className="rounded-full tf-btn style-3 fw-6 btn-light-icon animate-hover-btn"><span>Shop
                                    Now</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        <section className="flat-spacing-17">
            <div className="container">
                <div className="col-12 cls hero-section flat-title wow fadeInUp" data-wow-delay="0s">
                    <h1>Plus Size Lingeries</h1>
                    <p>Beautifully & Purposefully Designed Lingeries.</p>
                </div>
                <div className="tab-content">
                    <div className="tab-pane active show" id="bestSeller" role="tabpanel">
                        <div className="grid-layout loadmore-item" data-grid="grid-4">
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin3.jpg" src="myimg/lin3.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin3.jpg" src="myimg/lin3.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Babydoll Lingerie</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin6.jpg" src="myimg/lin6.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin6.jpg" src="myimg/lin6.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Lace Strappy Size Bra</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin4.jpg" src="myimg/lin4.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin4.jpg" src="myimg/lin4.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Babydoll Lingerie</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Babydoll Lingerie</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Babydoll Lingerie</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Babydoll Lingerie</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Babydoll Lingerie</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="myimg/lin5.jpg" src="myimg/lin5.jpg"
                                            alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>


                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Babydoll Lingerie</Link>
                                    <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                        <ul className="list-color-product">
                                            <li className="list-color-item color-swatch active">
                                                <span className="tooltip">Orange</span>
                                                <span className="swatch-value bg_orange-3"></span>
                                                <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                    src="images/products/orange-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">Black</span>
                                                <span className="swatch-value bg_dark"></span>
                                                <img className="lazyload" data-src="images/products/black-1.jpg"
                                                    src="images/products/black-1.jpg" alt="image-product"/>
                                            </li>
                                            <li className="list-color-item color-swatch">
                                                <span className="tooltip">White</span>
                                                <span className="swatch-value bg_white"></span>
                                                <img className="lazyload" data-src="images/products/white-1.jpg"
                                                    src="images/products/white-1.jpg" alt="image-product"/>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                        <div className="tf-pagination-wrap view-more-button text-center">
                            <button className="tf-btn-loading tf-loading-default style-2 btn-loadmore "><span
                                    className="text">Load more</span></button>
                        </div>
                    </div>
                    <div className="tab-pane" id="newArrivals" role="tabpanel">
                        <div className="grid-layout loadmore-item2" data-grid="grid-4">
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Ribbed Tank Top</Link>
                                    <span className="price">$16.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Orange</span>
                                            <span className="swatch-value bg_orange-3"></span>
                                            <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                src="images/products/orange-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-1.jpg"
                                                src="images/products/black-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-1.jpg"
                                                src="images/products/white-1.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/brown.jpg"
                                            src="images/products/brown.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/purple.jpg"
                                            src="images/products/purple.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="on-sale-wrap text-end">
                                        <div className="on-sale-item">-33%</div>
                                    </div>
                                    <div className="countdown-box">
                                        <div className="js-countdown" data-timer="1007500" data-labels="d :,h :,m :,s">
                                        </div>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Ribbed modal T-shirt</Link>
                                    <span className="price">From $18.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Brown</span>
                                            <span className="swatch-value bg_brown"></span>
                                            <img className="lazyload" data-src="images/products/brown.jpg"
                                                src="images/products/brown.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Purple</span>
                                            <span className="swatch-value bg_purple"></span>
                                            <img className="lazyload" data-src="images/products/purple.jpg"
                                                src="images/products/purple.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Green</span>
                                            <span className="swatch-value bg_light-green"></span>
                                            <img className="lazyload" data-src="images/products/green.jpg"
                                                src="images/products/green.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/white-3.jpg"
                                            src="images/products/white-3.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/white-4.jpg"
                                            src="images/products/white-4.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#shoppingCart" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Add to cart</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Oversized Printed T-shirt</Link>
                                    <span className="price">$10.00</span>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/white-2.jpg"
                                            src="images/products/white-2.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/pink-1.jpg"
                                            src="images/products/pink-1.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title">Oversized Printed T-shirt</Link>
                                    <span className="price">$16.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-2.jpg"
                                                src="images/products/white-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Pink</span>
                                            <span className="swatch-value bg_purple"></span>
                                            <img className="lazyload" data-src="images/products/pink-1.jpg"
                                                src="images/products/pink-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-2.jpg"
                                                src="images/products/black-2.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/brown-2.jpg"
                                            src="images/products/brown-2.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/brown-3.jpg"
                                            src="images/products/brown-3.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">V-neck linen T-shirt</Link>
                                    <span className="price">$114.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Brown</span>
                                            <span className="swatch-value bg_brown"></span>
                                            <img className="lazyload" data-src="images/products/brown-2.jpg"
                                                src="images/products/brown-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-5.jpg"
                                                src="images/products/white-5.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/light-green-1.jpg"
                                            src="images/products/light-green-1.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/light-green-2.jpg"
                                            src="images/products/light-green-2.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn absolute-2">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Loose Fit Sweatshirt</Link>
                                    <span className="price">$10.00</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Light Green</span>
                                            <span className="swatch-value bg_light-green"></span>
                                            <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                src="images/products/light-green-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-3.jpg"
                                                src="images/products/black-3.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Blue</span>
                                            <span className="swatch-value bg_blue-2"></span>
                                            <img className="lazyload" data-src="images/products/blue.jpg"
                                                src="images/products/blue.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Dark Blue</span>
                                            <span className="swatch-value bg_dark-blue"></span>
                                            <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                src="images/products/dark-blue.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-6.jpg"
                                                src="images/products/white-6.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Grey</span>
                                            <span className="swatch-value bg_light-grey"></span>
                                            <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                src="images/products/light-grey.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            ]
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/black-4.jpg"
                                            src="images/products/black-4.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-5.jpg"
                                            src="images/products/black-5.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Regular Fit Oxford Shirt</Link>
                                    <span className="price">$10.00</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-4.jpg"
                                                src="images/products/black-4.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Dark Blue</span>
                                            <span className="swatch-value bg_dark-blue"></span>
                                            <img className="lazyload" data-src="images/products/dark-blue-2.jpg"
                                                src="images/products/dark-blue-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Beige</span>
                                            <span className="swatch-value bg_beige"></span>
                                            <img className="lazyload" data-src="images/products/beige.jpg"
                                                src="images/products/beige.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Blue</span>
                                            <span className="swatch-value bg_light-blue"></span>
                                            <img className="lazyload" data-src="images/products/light-blue.jpg"
                                                src="images/products/light-blue.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-7.jpg"
                                                src="images/products/white-7.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/white-8.jpg"
                                            src="images/products/white-8.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-6.jpg"
                                            src="images/products/black-6.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Loose Fit Hoodie</Link>
                                    <span className="price">$9.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-8.jpg"
                                                src="images/products/white-8.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-7.jpg"
                                                src="images/products/black-7.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Blue</span>
                                            <span className="swatch-value bg_blue-2"></span>
                                            <img className="lazyload" data-src="images/products/blue-2.jpg"
                                                src="images/products/blue-2.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/brown-4.jpg"
                                            src="images/products/brown-4.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-8.jpg"
                                            src="images/products/black-8.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Patterned scarf</Link>
                                    <span className="price">$14.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Brown</span>
                                            <span className="swatch-value bg_brown"></span>
                                            <img className="lazyload" data-src="images/products/brown-4.jpg"
                                                src="images/products/brown-4.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-8.jpg"
                                                src="images/products/black-8.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/black-9.jpg"
                                            src="images/products/black-9.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-10.jpg"
                                            src="images/products/black-10.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Slim Fit Fine-knit Turtleneck
                                        Sweater</Link>
                                    <span className="price">$18.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-9.jpg"
                                                src="images/products/black-9.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-9.jpg"
                                                src="images/products/white-9.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/grey-2.jpg"
                                            src="images/products/grey-2.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/grey.jpg"
                                            src="images/products/grey.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Slim Fit Fine-knit Turtleneck
                                        Sweater</Link>
                                    <span className="price">$18.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Grey</span>
                                            <span className="swatch-value bg_grey"></span>
                                            <img className="lazyload" data-src="images/products/grey-2.jpg"
                                                src="images/products/grey-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Pink</span>
                                            <span className="swatch-value bg_pink"></span>
                                            <img className="lazyload" data-src="images/products/pink-2.jpg"
                                                src="images/products/pink-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Pink</span>
                                            <span className="swatch-value bg_light-pink"></span>
                                            <img className="lazyload" data-src="images/products/light-pink.jpg"
                                                src="images/products/light-pink.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item2">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/black-11.jpg"
                                            src="images/products/black-11.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-12.jpg"
                                            src="images/products/black-12.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Slim Fit Fine-knit Turtleneck
                                        Sweater</Link>
                                    <span className="price">$18.95</span>

                                </div>
                            </div>
                        </div>
                        <div className="tf-pagination-wrap view-more-button2 text-center">
                            <button className="tf-btn-loading tf-loading-default style-2 btn-loadmore2"><span
                                    className="text">Load more</span></button>
                        </div>
                    </div>
                    <div className="tab-pane" id="onSale" role="tabpanel">
                        <div className="grid-layout loadmore-item3" data-grid="grid-4">
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Ribbed Tank Top</Link>
                                    <span className="price">$16.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Orange</span>
                                            <span className="swatch-value bg_orange-3"></span>
                                            <img className="lazyload" data-src="images/products/orange-1.jpg"
                                                src="images/products/orange-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-1.jpg"
                                                src="images/products/black-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-1.jpg"
                                                src="images/products/white-1.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/brown.jpg"
                                            src="images/products/brown.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/purple.jpg"
                                            src="images/products/purple.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="on-sale-wrap text-end">
                                        <div className="on-sale-item">-33%</div>
                                    </div>
                                    <div className="countdown-box">
                                        <div className="js-countdown" data-timer="1007500" data-labels="d :,h :,m :,s">
                                        </div>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Ribbed modal T-shirt</Link>
                                    <span className="price">From $18.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Brown</span>
                                            <span className="swatch-value bg_brown"></span>
                                            <img className="lazyload" data-src="images/products/brown.jpg"
                                                src="images/products/brown.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Purple</span>
                                            <span className="swatch-value bg_purple"></span>
                                            <img className="lazyload" data-src="images/products/purple.jpg"
                                                src="images/products/purple.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Green</span>
                                            <span className="swatch-value bg_light-green"></span>
                                            <img className="lazyload" data-src="images/products/green.jpg"
                                                src="images/products/green.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/white-3.jpg"
                                            src="images/products/white-3.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/white-4.jpg"
                                            src="images/products/white-4.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#shoppingCart" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Add to cart</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Oversized Printed T-shirt</Link>
                                    <span className="price">$10.00</span>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/white-2.jpg"
                                            src="images/products/white-2.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/pink-1.jpg"
                                            src="images/products/pink-1.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title">Oversized Printed T-shirt</Link>
                                    <span className="price">$16.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-2.jpg"
                                                src="images/products/white-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Pink</span>
                                            <span className="swatch-value bg_purple"></span>
                                            <img className="lazyload" data-src="images/products/pink-1.jpg"
                                                src="images/products/pink-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-2.jpg"
                                                src="images/products/black-2.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/brown-2.jpg"
                                            src="images/products/brown-2.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/brown-3.jpg"
                                            src="images/products/brown-3.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">V-neck linen T-shirt</Link>
                                    <span className="price">$114.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Brown</span>
                                            <span className="swatch-value bg_brown"></span>
                                            <img className="lazyload" data-src="images/products/brown-2.jpg"
                                                src="images/products/brown-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-5.jpg"
                                                src="images/products/white-5.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/light-green-1.jpg"
                                            src="images/products/light-green-1.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/light-green-2.jpg"
                                            src="images/products/light-green-2.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="list-product-btn absolute-2">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Loose Fit Sweatshirt</Link>
                                    <span className="price">$10.00</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Light Green</span>
                                            <span className="swatch-value bg_light-green"></span>
                                            <img className="lazyload" data-src="images/products/light-green-1.jpg"
                                                src="images/products/light-green-1.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-3.jpg"
                                                src="images/products/black-3.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Blue</span>
                                            <span className="swatch-value bg_blue-2"></span>
                                            <img className="lazyload" data-src="images/products/blue.jpg"
                                                src="images/products/blue.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Dark Blue</span>
                                            <span className="swatch-value bg_dark-blue"></span>
                                            <img className="lazyload" data-src="images/products/dark-blue.jpg"
                                                src="images/products/dark-blue.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-6.jpg"
                                                src="images/products/white-6.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Grey</span>
                                            <span className="swatch-value bg_light-grey"></span>
                                            <img className="lazyload" data-src="images/products/light-grey.jpg"
                                                src="images/products/light-grey.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/black-4.jpg"
                                            src="images/products/black-4.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-5.jpg"
                                            src="images/products/black-5.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Regular Fit Oxford Shirt</Link>
                                    <span className="price">$10.00</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-4.jpg"
                                                src="images/products/black-4.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Dark Blue</span>
                                            <span className="swatch-value bg_dark-blue"></span>
                                            <img className="lazyload" data-src="images/products/dark-blue-2.jpg"
                                                src="images/products/dark-blue-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Beige</span>
                                            <span className="swatch-value bg_beige"></span>
                                            <img className="lazyload" data-src="images/products/beige.jpg"
                                                src="images/products/beige.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Blue</span>
                                            <span className="swatch-value bg_light-blue"></span>
                                            <img className="lazyload" data-src="images/products/light-blue.jpg"
                                                src="images/products/light-blue.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-7.jpg"
                                                src="images/products/white-7.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/white-8.jpg"
                                            src="images/products/white-8.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-6.jpg"
                                            src="images/products/black-6.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Loose Fit Hoodie</Link>
                                    <span className="price">$9.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">White</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-8.jpg"
                                                src="images/products/white-8.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-7.jpg"
                                                src="images/products/black-7.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Blue</span>
                                            <span className="swatch-value bg_blue-2"></span>
                                            <img className="lazyload" data-src="images/products/blue-2.jpg"
                                                src="images/products/blue-2.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/brown-4.jpg"
                                            src="images/products/brown-4.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-8.jpg"
                                            src="images/products/black-8.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>M</span>
                                        <span>L</span>
                                        <span>XL</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Patterned scarf</Link>
                                    <span className="price">$14.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Brown</span>
                                            <span className="swatch-value bg_brown"></span>
                                            <img className="lazyload" data-src="images/products/brown-4.jpg"
                                                src="images/products/brown-4.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-8.jpg"
                                                src="images/products/black-8.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/black-9.jpg"
                                            src="images/products/black-9.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-10.jpg"
                                            src="images/products/black-10.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Slim Fit Fine-knit Turtleneck
                                        Sweater</Link>
                                    <span className="price">$18.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_dark"></span>
                                            <img className="lazyload" data-src="images/products/black-9.jpg"
                                                src="images/products/black-9.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Black</span>
                                            <span className="swatch-value bg_white"></span>
                                            <img className="lazyload" data-src="images/products/white-9.jpg"
                                                src="images/products/white-9.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/grey-2.jpg"
                                            src="images/products/grey-2.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/grey.jpg"
                                            src="images/products/grey.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Slim Fit Fine-knit Turtleneck
                                        Sweater</Link>
                                    <span className="price">$18.95</span>
                                    <ul className="list-color-product">
                                        <li className="list-color-item color-swatch active">
                                            <span className="tooltip">Grey</span>
                                            <span className="swatch-value bg_grey"></span>
                                            <img className="lazyload" data-src="images/products/grey-2.jpg"
                                                src="images/products/grey-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Pink</span>
                                            <span className="swatch-value bg_pink"></span>
                                            <img className="lazyload" data-src="images/products/pink-2.jpg"
                                                src="images/products/pink-2.jpg" alt="image-product"/>
                                        </li>
                                        <li className="list-color-item color-swatch">
                                            <span className="tooltip">Light Pink</span>
                                            <span className="swatch-value bg_light-pink"></span>
                                            <img className="lazyload" data-src="images/products/light-pink.jpg"
                                                src="images/products/light-pink.jpg" alt="image-product"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="card-product fl-item3">
                                <div className="card-product-wrapper">
                                    <Link to="product-detail" className="product-img">
                                        <img className="lazyload img-product" data-src="images/products/black-11.jpg"
                                            src="images/products/black-11.jpg" alt="image-product"/>
                                        <img className="lazyload img-hover" data-src="images/products/black-12.jpg"
                                            src="images/products/black-12.jpg" alt="image-product"/>
                                    </Link>
                                    <div className="size-list">
                                        <span>S</span>
                                        <span>M</span>
                                        <span>L</span>
                                    </div>
                                    <div className="list-product-btn">
                                        <Link to="#quick_add" data-bs-toggle="modal"
                                            className="box-icon bg_white quick-add tf-btn-loading">
                                            <span className="icon icon-bag"></span>
                                            <span className="tooltip">Quick Add</span>
                                        </Link>
                                        <Link to="#"
                                            className="box-icon bg_white wishlist btn-icon-action">
                                            <span className="icon icon-heart"></span>
                                            <span className="tooltip">Add to Wishlist</span>
                                            <span className="icon icon-delete"></span>
                                        </Link>
                                        <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                            className="box-icon bg_white compare btn-icon-action">
                                            <span className="icon icon-compare"></span>
                                            <span className="tooltip">Add to Compare</span>
                                            <span className="icon icon-check"></span>
                                        </Link>

                                    </div>
                                </div>
                                <div className="card-product-info">
                                    <Link to="product-detail" className="title link">Slim Fit Fine-knit Turtleneck
                                        Sweater</Link>
                                    <span className="price">$18.95</span>

                                </div>
                            </div>
                        </div>
                        {/* <!-- <div className="tf-pagination-wrap view-more-button3 text-center" style="display: none !important;">
                            <button className="tf-btn-loading tf-loading-default style-2 btn-loadmore3"><span
                                    className="text">Load more</span></button>
                        </div> --> */}


                    </div>
                </div>
                <div className="col-md-12 col-12 text-center">
                    <Link to="#"><button className="vmore">View more</button></Link>
                </div>
            </div>
        </section>

       
        <section className="tf-slideshow2 slider-video position-relative pb-4">
            <div className="banner-wrapper">
                <video src="myimg/slider2.mp4" autoPlay muted playsInline loop className="banner-video"></video>
                <div className="box-content text-center">
                    <div className="container wow fadeInUp" data-wow-delay="0s">
                        <p className="subheading text-white fw-7">FREE SHIPPING OVER ORDER $120</p>
                        <h1 className="heading text-white">Spring Collection</h1>
                        <p className="description text-white">Here is your chance to upgrade your wardrobe with a variation
                            of styles</p>
                        <Link to="shop-women"
                            className="tf-btn btn-md btn-light-icon btn-icon radius-3 animate-hover-btn">
                            <span>Shop now</span><i className="icon icon-arrow-right"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </section>


        {/* <!-- Sale Product --> */}
        <section className="flat-spacing-17">
            <div className="container">
                <div className="col-12 cls hero-section flat-title wow fadeInUp" data-wow-delay="0s">
                    <h1>Roleplay Dresses</h1>
                    <p>Beautifully & Purposefully Roleplay Dresses.</p>
                </div>

                <div className="grid-layout" data-grid="grid-4">
                    {/* <!-- card product 1 --> */}
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/bestsell1.jpg"
                                    src="myimg/bestsell1.jpg" alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/bestsell1.jpg" src="myimg/bestsell1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link"> Nurse Roleplay Dress </Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/rp2.jpg" src="myimg/rp2.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/rp2.jpg" src="myimg/rp2.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Student Roleplay Dress</Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/rp3.jpg" src="myimg/rp3.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/rp3.jpg" src="myimg/rp3.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Police Girl Dress</Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Cosplay Dress</Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Cosplay Dress</Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Cosplay Dress</Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>

                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Cosplay Dress</Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    <div className="card-product fl-item">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/rp4.png" src="myimg/rp4.png"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>
                                <Link to="#" className="box-icon bg_white wishlist btn-icon-action">
                                    <span className="icon icon-heart"></span>
                                    <span className="tooltip">Add to Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>


                            </div>
                            <div className="size-list">
                                <span>S</span>
                                <span>M</span>
                                <span>L</span>
                                <span>XL</span>
                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Cosplay Dress</Link>
                            <span className="price"><s className="canprice">₹ 1,999</s> ₹ 1,799</span>
                                <ul className="list-color-product">
                                    <li className="list-color-item color-swatch active">
                                        <span className="tooltip">Orange</span>
                                        <span className="swatch-value bg_orange-3"></span>
                                        <img className="lazyload" data-src="images/products/orange-1.jpg"
                                            src="images/products/orange-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">Black</span>
                                        <span className="swatch-value bg_dark"></span>
                                        <img className="lazyload" data-src="images/products/black-1.jpg"
                                            src="images/products/black-1.jpg" alt="image-product"/>
                                    </li>
                                    <li className="list-color-item color-swatch">
                                        <span className="tooltip">White</span>
                                        <span className="swatch-value bg_white"></span>
                                        <img className="lazyload" data-src="images/products/white-1.jpg"
                                            src="images/products/white-1.jpg" alt="image-product"/>
                                    </li>
                                </ul>
                        </div>
                    </div>

                </div>



            </div>
            <div className="col-md-12 col-12 text-center mt-5">
                <Link to="#"><button className="vmore">View more</button></Link>
            </div>
        </section>
       


        <section className="cta-banner">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="banner">
                            <img src="myimg/ban3.jpg" alt="CTA Banner"/>
                            <div className="banner-content mob-cont">
                                <h2 style={{color: "white"}}>Unleash Desire Within You</h2>
                                <p> Premium Intimate Toys for Unforgettable Moments</p>
                                <Link to="#" className="btn-banner">Explore Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        {/* <!-- <section>
            <div className="container-full">
                <div className="swiper tf-sw-recent" data-preview="3" data-tablet="3" data-mobile="1.3" data-space-lg="30"
                    data-space-md="15" data-space="15" data-pagination="1" data-pagination-md="1"
                    data-pagination-lg="1">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide" lazy="true">
                            <div className="collection-item-v4 hover-img">
                                <div className="collection-inner">
                                    <Link to="shop-collection-sub" className="collection-image img-style">
                                        <img className="lazyload" data-src="myimg/bbydoll.jpg" src="myimg/bbydoll.jpg"
                                            alt="collection-img">
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading">UP TO 30% OFF</p>
                                        <h5 className="heading">Essential Basics</h5>
                                        <Link to="shop-collection-sub"
                                            className="tf-btn style-3 fw-6 btn-light-icon radius-3 animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide" lazy="true">
                            <div className="collection-item-v4 hover-img">
                                <div className="collection-inner">
                                    <Link to="shop-collection-sub" className="collection-image img-style">
                                        <img className="lazyload" data-src="images/collections/collection-40.jpg"
                                            src="images/collections/collection-40.jpg" alt="collection-img">
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading">UP TO 30% OFF</p>
                                        <h5 className="heading">Athleisure Wear</h5>
                                        <Link to="shop-collection-sub"
                                            className="tf-btn style-3 fw-6 btn-light-icon radius-3 animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide" lazy="true">
                            <div className="collection-item-v4 hover-img">
                                <div className="collection-inner">
                                    <Link to="shop-collection-sub" className="collection-image img-style">
                                        <img className="lazyload" data-src="images/collections/collection-41.jpg"
                                            src="images/collections/collection-41.jpg" alt="collection-img">
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading">UP TO 30% OFF</p>
                                        <h5 className="heading">Seasonal Favorites</h5>
                                        <Link to="shop-collection-sub"
                                            className="tf-btn style-3 fw-6 btn-light-icon radius-3 animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide" lazy="true">
                            <div className="collection-item-v4 hover-img">
                                <div className="collection-inner">
                                    <Link to="shop-collection-sub" className="collection-image img-style">
                                        <img className="lazyload" data-src="images/collections/collection-41.jpg"
                                            src="images/collections/collection-41.jpg" alt="collection-img">
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading">UP TO 30% OFF</p>
                                        <h5 className="heading">Seasonal Favorites</h5>
                                        <Link to="shop-collection-sub"
                                            className="tf-btn style-3 fw-6 btn-light-icon radius-3 animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide" lazy="true">
                            <div className="collection-item-v4 hover-img">
                                <div className="collection-inner">
                                    <Link to="shop-collection-sub" className="collection-image img-style">
                                        <img className="lazyload" data-src="images/collections/collection-41.jpg"
                                            src="images/collections/collection-41.jpg" alt="collection-img">
                                    </Link>
                                    <div className="collection-content wow fadeInUp" data-wow-delay="0s">
                                        <p className="subheading">UP TO 30% OFF</p>
                                        <h5 className="heading">Seasonal Favorites</h5>
                                        <Link to="shop-collection-sub"
                                            className="tf-btn style-3 fw-6 btn-light-icon radius-3 animate-hover-btn"><span>Shop
                                                now</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> --> */}
        
        <section className="flat-spacing-11 pb_4 flat-iconbox wow fadeInUp" data-wow-delay="0s">
            <div className="container">
                <div className="wrap-carousel wrap-mobile">
                    <div className="swiper tf-sw-mobile" data-preview="1" data-space="15">
                        <div className="swiper-wrapper wrap-iconbox">
                            <div className="swiper-slide">
                                <div className="tf-icon-box style-border-line text-center">
                                    <div className="icon">
                                        <i className="icon-shipping"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">Free Shipping</div>
                                        <p>Free shipping over order ₹ 2,999</p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="tf-icon-box style-border-line text-center">
                                    <div className="icon">
                                        <i className="icon-payment fs-22"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">Flexible Payment</div>
                                        <p>Pay with Multiple Credit Cards</p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="tf-icon-box style-border-line text-center">
                                    <div className="icon">
                                        <i className="icon-return fs-22"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">14 Day Returns</div>
                                        <p>Within 30 days for an exchange</p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="tf-icon-box style-border-line text-center">
                                    <div className="icon">
                                        <i className="icon-suport"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">Premium Support</div>
                                        <p>Outstanding premium support</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="sw-dots style-2 sw-pagination-mb justify-content-center"></div>
                </div>
            </div>
        </section>
        
        <section className="flat-spacing-5 pt_0 flat-testimonial">
            <div className="container">
                <div className="col-12 cls hero-section flat-title wow fadeInUp" data-wow-delay="0s">
                    <h1>Our Happy Client's</h1>
                    <p>Hear what they say about us.</p>
                </div>
                <div className="wrap-carousel">
                    <div className="swiper tf-sw-testimonial" data-preview="3" data-tablet="2" data-mobile="1"
                        data-space-lg="30" data-space-md="15">
                        <div className="swiper-wrapper">
                        <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive3}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={1500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["desktop"]}
                                deviceType="desktop"
                                itemClass="carousel-item-margin-10-px"
                            >
                            <div className="swiper-slide">
                                <div className="testimonial-item style-column wow fadeInUp" data-wow-delay="0s">
                                    <div className="rating">
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                    </div>
                                    <div className="heading">Best Babydoll Dress I bought till date</div>
                                    <div className="text">
                                        “ I always find something stylish and affordable on this site ”
                                    </div>
                                    <div className="author">
                                        <div className="name">Aditi Singh</div>
                                        <div className="metas">India</div>
                                    </div>

                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="testimonial-item style-column wow fadeInUp" data-wow-delay=".1s">
                                    <div className="rating">
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                    </div>
                                    <div className="heading">Best Quality Lingeries</div>
                                    <div className="text">
                                        "I love the variety of styles and the high-quality clothing on this web fashion
                                        site."
                                    </div>
                                    <div className="author">
                                        <div className="name">Preeti Saxena</div>
                                        <div className="metas">India</div>
                                    </div>

                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="testimonial-item style-column wow fadeInUp" data-wow-delay=".2s">
                                    <div className="rating">
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                    </div>
                                    <div className="heading">Best Customer Service</div>
                                    <div className="text">
                                        "I finally found a lingerie fashion site with stylish and flattering options in
                                        my
                                        size."
                                    </div>
                                    <div className="author">
                                        <div className="name">Shruti Hassan</div>
                                        <div className="metas">india</div>
                                    </div>

                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="testimonial-item style-column wow fadeInUp" data-wow-delay=".3s">
                                    <div className="rating">
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                        <i className="icon-start"></i>
                                    </div>
                                    <div className="heading">Great Selection and Quality</div>
                                    <div className="text">
                                        "I love the variety of styles and the high-quality clothing on this nicky
                                        lingerie website."
                                    </div>
                                    <div className="author">
                                        <div className="name">Riya Suryavanshi</div>
                                        <div className="metas">India</div>
                                    </div>

                                </div>
                            </div>
                            </Carousel>
                        </div>
                    </div>
                    <div className="nav-sw nav-next-slider nav-next-testimonial lg"><span
                            className="icon icon-arrow-left"></span></div>
                    <div className="nav-sw nav-prev-slider nav-prev-testimonial lg"><span
                            className="icon icon-arrow-right"></span></div>
                    <div className="sw-dots style-2 sw-pagination-testimonial justify-content-center"></div>
                </div>
            </div>
        </section>
       
        <section className="flat-spacing-7">
            <div className="container">
                <div className="col-12 cls hero-section flat-title wow fadeInUp" data-wow-delay="0s">
                    <h1>Shop Gram</h1>
                    {/* <!-- <p>Hear what they say about us.</p> --> */}
                </div>
                <div className="wrap-carousel wrap-shop-gram">
                    <div className="swiper tf-sw-shop-gallery" data-preview="5" data-tablet="3" data-mobile="2"
                        data-space-lg="7" data-space-md="7">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="gallery-item hover-img wow fadeInUp" data-wow-delay=".2s">
                                    <div className="img-style">
                                        <img className="lazyload img-hover" data-src="myimg/sg1.jpg" src="myimg/sg1.jpg"
                                            alt="image-gallery"/>
                                    </div>
                                    <Link to="#quick_add" data-bs-toggle="modal" className="box-icon"><span
                                            className="icon icon-bag"></span> <span className="tooltip">Quick Add</span></Link>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="gallery-item hover-img wow fadeInUp" data-wow-delay=".3s">
                                    <div className="img-style">
                                        <img className="lazyload img-hover" data-src="myimg/sg2.webp" src="myimg/sg2.webp"
                                            alt="image-gallery"/>
                                    </div>
                                    <Link to="#quick_add" data-bs-toggle="modal" className="box-icon"><span
                                            className="icon icon-bag"></span> <span className="tooltip">Quick Add</span></Link>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="gallery-item hover-img wow fadeInUp" data-wow-delay=".4s">
                                    <div className="img-style">
                                        <img className="lazyload img-hover" data-src="myimg/sg3.jpg" src="myimg/sg3.jpg"
                                            alt="image-gallery"/>
                                    </div>
                                    <Link to="#quick_add" data-bs-toggle="modal" className="box-icon"><span
                                            className="icon icon-bag"></span> <span className="tooltip">Quick Add</span></Link>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="gallery-item hover-img wow fadeInUp" data-wow-delay=".5s">
                                    <div className="img-style">
                                        <img className="lazyload img-hover" data-src="myimg/sg4.webp" src="myimg/sg4.webp"
                                            alt="image-gallery"/>
                                    </div>
                                    <Link to="product-detail" className="box-icon"><span className="icon icon-bag"></span>
                                        <span className="tooltip">View product</span></Link>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="gallery-item hover-img wow fadeInUp" data-wow-delay=".6s">
                                    <div className="img-style">
                                        <img className="lazyload img-hover" data-src="myimg/sg5.webp" src="myimg/sg5.webp"
                                            alt="image-gallery"/>
                                    </div>
                                    <Link to="product-detail" className="box-icon"><span className="icon icon-bag"></span>
                                        <span className="tooltip">View product</span></Link>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="gallery-item hover-img wow fadeInUp" data-wow-delay=".4s">
                                    <div className="img-style">
                                        <img className="lazyload img-hover" data-src="myimg/sg3.jpg" src="myimg/sg3.jpg"
                                            alt="image-gallery"/>
                                    </div>
                                    <Link to="#quick_add" data-bs-toggle="modal" className="box-icon"><span
                                            className="icon icon-bag"></span> <span className="tooltip">Quick Add</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sw-dots sw-pagination-gallery justify-content-center"></div>
                </div>
            </div>
        </section>
    </>
  )
}
