import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
// import './App.css';
import Header from "./common/Header";
import Footer from "./common/Footer";
import Login from "./components/Login";
// import Logout from "./user/Logout";
import MyAccount from "./user/MyAccountOrders.js";
import MyAccountAddress from "./user/MyAccountAddress";
import MyAccountEdit from "./user/MyAccountEdit";
import MyAccountOrders from "./user/MyAccountOrders";
import MyAccountWishlist from "./user/MyAccountWishlist";
import Home from "./components/Home";
import About from "./components/About";
import CategoryInner from "./components/CategoryInner";
import Contact from "./components/Contact";
import Checkout from "./components/Checkout";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductDetail from "./components/ProductDetail";
import Register from "./components/Register";
import ShippingDelivery from "./components/ShippingDelivery";
import TermsConditions from "./components/TermsConditions";
import ViewCart from "./components/ViewCart";
import Wishlist from "./components/Wishlist";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function App() {
  const [cart, setCart] = useState([]);
  const [qty, setQty] = useState(1);
  const [subtotal, setSubtotal] = useState();
  const [subqty, setSubqty] = useState();
  const [mesg, setMesg] = useState("");
  const [lmesg, setLmesg] = useState("");
  const [logout, setLogout] = useState(false);

// category list

const [catlist, setCatlist] = useState([]);

    useEffect(() => {
        categorylist();
      }, []);

      const categorylist = () => {
      axios
        .get("https://gobusinessserver.in/nikki-apis/category.php")
        .then(function (response) {
          setCatlist(response.data.category);
        })
        .catch(function (error) {});
    };
// category list


  // Register
  const [inputValues, setInputValue] = useState({
    fName: "",
    lName: "",
    email: "",
    password: "",
    phone: "",
    subm: ""
  });

  const [validation, setValidation] = useState({
    fName: "",
    lName: "",
    email: "",
    password: "",
    phone: "",
    subm: ""
  });

  //handle submit updates
  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  }

  const checkValidation = () => {
    let errors = validation;

    //first Name validation
    if (!inputValues.fName.trim()) {
      errors.fName = "First name is required";
      errors.subm = "1";
    } else {
      errors.fName = "";
      errors.subm = "";
    }
    //last Name validation
    if (!inputValues.lName.trim()) {
      errors.lName = "Last name is required";
      errors.subm = "1";
    } else {
      errors.lName = "";
      errors.subm = "";
    }

    // email validation
    const emailCond = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
    if (!inputValues.email.trim()) {
      errors.email = "Email is required";
      errors.subm = "1";
    } else if (!inputValues.email.match(emailCond)) {
      errors.email = "Please enter a valid email address";
      errors.subm = "1";
    } else {
      errors.email = "";
      errors.subm = "";
    }

    //password validation
    const password = inputValues.password;
    if (!password) {
      errors.password = "password is required";
      errors.subm = "1";
    } else {
      errors.password = "";
      errors.subm = "";
    }
    setValidation(errors);
  };

  useEffect(() => {
    checkValidation();
  }, [inputValues]);

  const handleSubmit = (e) => {
    
    e.preventDefault();
    if (validation.subm === "") {
      const datass = {
        fname: inputValues.fName,
        lname: inputValues.lName,
        email: inputValues.email,
        password: inputValues.password,
        phone: inputValues.phone,
      };
     
      axios
        .post(
          "https://gobusinessserver.in/nikki-apis/register.php",
          JSON.stringify(datass)
        )
        .then(function (response) {
          
          setMesg({
            msg: response.data.msg,
            typ: response.data.status === "1" ? "success" : "danger",
          });
        })
        .catch(function (error) {
          setMesg({
            msg: error,
            typ: "danger",
          });
        });
    }
  };

  // Register


// login user

const loginUser = (e) => {
  e.preventDefault();
  
    const data = {
      email: document.getElementById("login_email").value,
      password: document.getElementById("login_password").value,
    };

    axios
      .post("https://gobusinessserver.in/nikki-apis/login.php", JSON.stringify(data))
      .then(function (response) {
        if(response.data.status ==1){
        sessionStorage.setItem("email", response.data.email);
        sessionStorage.setItem("profileid", response.data.profileid);
        sessionStorage.setItem("name", response.data.name);
        setLmesg({
          msg: response.data.msg,
          typ: response.data.status === "1" ? "success" : "danger",
        });
        setLogout(false);
      }
      else{
        setLmesg({
          msg: response.data.msg,
          typ: "danger",
        });
      }
      })
      .catch(function (error) {
        
        setLmesg({
          msg: error,
          typ: "danger",
        });
      });
  
};

// login user



// Add time to cart
const addItem = (item,qty,size,color,msg) => {
  
  if(size  === undefined || color === undefined){
    toast.error("Please select size and color !!!");
  }
  else{
  const existingItem = cart.find((cartItem) => cartItem.id === item.id);
  if (existingItem) {
    
    setCart(
      cart.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + qty }
          : cartItem
      )
    );
  } else {
    setCart([...cart, { ...item, quantity: qty, color: color, size: size }]);
  }
  if(msg=='' || msg == undefined)
  {
    toast.success("Item added to cart !");
  }
}
};
// Add item to cart

// remove item from cart
const removeItm = (item, idd) => {
  let items = JSON.parse(localStorage.getItem("item"));
  items = cart.filter((item,o) => o !== idd);    
  setCart(items);
};
// remove item from cart


// decrease cart qty
const decreaseqty = (item,index) => {
  const existingItem = cart.find((cartItem) => cartItem.id === item.id);
  if (existingItem.quantity === 1) {
    setCart(cart.filter((cartItem) => cartItem.id !== item.id));
    
  } else {
    setCart(
      cart.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity - 1 }
          : cartItem
      )
    );
    
  }
};
// decrease cart qty

// empty cart

const removecart =()=>{
  setCart([]);
}


  return (
    <>
      <BrowserRouter>
        <Header cartlength={cart.length} logout={logout} catlist={catlist}/>

        <Routes>
          <Route exact path="/" element={<Home  catlist={catlist}/>} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/category-inner/:category/:id/" element={<CategoryInner key="/category-inner/:category/:id/"  addItem={addItem}/>} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/my-account" element={<MyAccount />} />
          <Route
            exact
            path="/my-account-address"
            element={<MyAccountAddress />}
          />
          <Route exact path="/my-account-edit" element={<MyAccountEdit />} />
          <Route
            exact
            path="/my-account-orders"
            element={<MyAccountOrders />}
          />
          <Route
            exact
            path="/my-account-wishlist"
            element={<MyAccountWishlist />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/product-detail/:id" element={<ProductDetail key="/product-detail/:id" addItem={addItem}/>} />
          <Route exact path="/register" element={<Register />} />
          <Route
            exact
            path="/shipping-delivery"
            element={<ShippingDelivery />}
          />
          <Route exact path="/terms-conditions" element={<TermsConditions />} />
          <Route exact path="/view-cart" element={<ViewCart />} />
          <Route exact path="/wishlist" element={<Wishlist />} />
          <Route
            exact
            path="/checkout"
            element={
              <Checkout carts={cart} subtotal={subtotal} subqty={subqty} />
            }
          />
          <Route exact path="/login" element={<Login />} />
          {/* <Route exact path="/faq" element={<Faq key="FaQ" title="Faq" />} />
        <Route exact path="/help" element={<Faq ley="Help" title="Help" />} />
        <Route exact path="/support" element={<Faq key="Support" title="Support" />} />
        <Route exact path="/register" element={<Register/>} />
        <Route exact path="/login" element={<Login loginUser={loginUser} mesg={mesg}/>} />
        <Route exact path="/logout" element={<Logout change_logout_menu={change_logout_menu}/>} />
        <Route exact path="/my-orders" element={<Myorders/>} />
        <Route exact path="/change-pass" element={<ChangePassword/>} />
        <Route exact path="/dashboard" element={<Dashboard/>} />
        <Route exact path="/shop/:subcategory/:id" element={<Shop addItem={addItem}/>} />
        <Route exact path="/shop/:search" element={<Shop addItem={addItem}/>} />
        <Route exact path="/shop" element={<Shop addItem={addItem}/>} />
        <Route exact path="/detail/:id" element={<Detail key="/detail/:id" addItem={addItem}/>} />
        <Route exact path="/cart" element={<Cart carts={cart} removeItm={removeItm} decreaseqty={decreaseqty} addItem={addItem} subtotal={subtotal} subqty={subqty}/>}/>
        <Route exact path="/thank-you" element={<Thankyou removecart={removecart}/>} /> */}
        </Routes>

        <Footer handleSubmit={handleSubmit} handleChange={handleChange} inputValues={inputValues} mesg={mesg} lmesg={lmesg} validation={validation} loginUser={loginUser} catlist={catlist}/>
      </BrowserRouter>
    </>
  );
}

export default App;
