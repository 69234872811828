import React, { useEffect,useState } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function Footer(props) {

    const [inputValues, setInputValue] = useState({
        email: "",
        password: "",
      });
    
      const [validation, setValidation] = useState({
        email: "",
        password: "",
        subm: "",
      });
      const navigate = useNavigate();
    
      //handle submit updates
      function handleChange(event) {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
      }
    
      const checkValidation = () => {
        let errors = validation;
    
        // email validation
        const emailCond = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
        if (!inputValues.email.trim()) {
          errors.email = "Email is required";
          errors.subm = "1";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter a valid email address";
          errors.subm = "1";
        } else {
          errors.email = "";
          errors.subm = "";
        }
    
        //password validation
        const password = inputValues.password;
        if (!password) {
          errors.password = "password is required";
          errors.subm = "1";
        } else {
          errors.password = "";
          errors.subm = "";
        }
    
        setValidation(errors);
      };
    
      useEffect(() => {
        checkValidation();
        //console.log(sessionStorage.getItem('email'));
        // if(sessionStorage.getItem('email')!='')
        // {
        //   navigate('/my-account');
        // }
        // if(sessionStorage.getItem('email')===null)
        //   {
        //     navigate('/login');
        //   }
    
      }, [inputValues,props]);
    
  return (
    <>
        <div id="wrapper">
        <footer id="footer" className="footer md-pb-70">
            <div className="footer-wrap">
                <div className="footer-body" style={{background: "#67000299"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4  col-12">
                                <div className="footer-infor">
                                    <div className="footer-logo">
                                        <Link to="index">
                                            <img src="myimg/NICKY_LINGERIE_STORE-removebg-preview.png" alt=""/>
                                        </Link>
                                    </div>
                                    <ul>
                                        <li>
                                            <p>Address: 1234 Fashion Street, Suite 567, <br/> New Delhi, dl-110059</p>
                                        </li>
                                        <li>
                                            <p>Email: <Link to="#">info@nikkilingerie.com</Link></p>
                                        </li>
                                        <li>
                                            <p>Phone: <Link to="#">+91-9546789365</Link></p>
                                        </li>
                                    </ul>

                                    <ul className="tf-social-icon d-flex gap-10">
                                        <li><Link to="#" className="box-icon w_34 round social-facebook social-line"><i
                                                    className="icon fs-14 icon-fb"></i></Link></li>
                                        <li><Link to="#" className="box-icon w_34 round social-twiter social-line"><i
                                                    className="icon fs-12 icon-Icon-x"></i></Link></li>
                                        <li><Link to="#" className="box-icon w_34 round social-instagram social-line"><i
                                                    className="icon fs-14 icon-instagram"></i></Link></li>
                                        <li><Link to="#" className="box-icon w_34 round social-tiktok social-line"><i
                                                    className="icon fs-14 icon-tiktok"></i></Link></li>
                                        <li><Link to="#" className="box-icon w_34 round social-pinterest social-line"><i
                                                    className="icon fs-14 icon-pinterest-1"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-2  col-12 footer-col-block">
                                <div className="footer-heading footer-heading-desktop">
                                    <h6>Shop By Occasion</h6>
                                </div>
                                <div className="footer-heading footer-heading-moblie">
                                    <h6>Shop By Occasion</h6>
                                </div>
                                <ul className="footer-menu-list tf-collapse-content">
                                    <li>
                                        <Link to="privacy-policy" className="footer-menu_item">Honeymoon Lingerie</Link>
                                    </li>
                                    <li>
                                        <Link to="delivery-return" className="footer-menu_item">Bridal Special
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="shipping-delivery" className="footer-menu_item">Beach Vacations</Link>
                                    </li>
                                    <li>
                                        <Link to="terms-conditions" className="footer-menu_item">Party Wear</Link>
                                    </li>
                                    <li>
                                        <Link to="faq-1" className="footer-menu_item">Anniversary</Link>
                                    </li>
                                    <li>
                                        <Link to="compare" className="footer-menu_item">First Night</Link>
                                    </li>
                                    <li>
                                        <Link to="wishlist" className="footer-menu_item">Casual Wear</Link>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-md-2 col-12 footer-col-block">
                                <div className="footer-heading footer-heading-desktop">
                                    <h6>Shop By Category

                                    </h6>
                                </div>
                                <div className="footer-heading footer-heading-moblie">
                                    <h6>Shop By Category</h6>
                                </div>
                                <ul className="footer-menu-list tf-collapse-content">
                                    <li>
                                        <Link to="about-us" className="footer-menu_item">Garter Set</Link>
                                    </li>
                                    <li>
                                        <Link to="our-store" className="footer-menu_item">RolePlay Dresses</Link>
                                    </li>
                                    <li>
                                        <Link to="contact-1" className="footer-menu_item">Bridal Robe
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="login" className="footer-menu_item">New Arrivals</Link>
                                    </li>
                                    <li>
                                        <Link to="login" className="footer-menu_item">Bodysuit</Link>
                                    </li>
                                    <li>
                                        <Link to="login" className="footer-menu_item">Bikini Set
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="login" className="footer-menu_item">Plus Size Lingerie </Link>

                                    </li>
                                    <li>
                                        <Link to="login" className="footer-menu_item">Corset </Link>

                                    </li>
                                    <li>
                                        <Link to="login" className="footer-menu_item">Intimate Toys </Link>

                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-2 col-12 footer-col-block">
                                <div className="footer-heading footer-heading-desktop">
                                    <h6>Shop Lingerie by Offer

                                    </h6>
                                </div>
                                <div className="footer-heading footer-heading-moblie">
                                    <h6>Shop Lingerie by Offer</h6>
                                </div>
                                <ul className="footer-menu-list tf-collapse-content">
                                    <li>
                                        <Link to="about-us" className="footer-menu_item">Deal of the Day</Link>
                                    </li>
                                    <li>
                                        <Link to="our-store" className="footer-menu_item">Under 699</Link>
                                    </li>
                                    <li>
                                        <Link to="contact-1" className="footer-menu_item">Under 499
                                        </Link>
                                    </li>

                                </ul>
                            </div>

                            <div className="col-md-2 col-12 footer-col-block">
                                <div className="footer-heading footer-heading-desktop">
                                    <h6>Quick Links</h6>
                                </div>
                                <div className="footer-heading footer-heading-moblie">
                                    <h6>Quick Links</h6>
                                </div>
                                <ul className="footer-menu-list tf-collapse-content">
                                    <li>
                                        <Link to="my-account" className="footer-menu_item">My Account</Link>
                                    </li>
                                    <li>
                                        <Link to="about" className="footer-menu_item">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="privacy-policy" className="footer-menu_item">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="shipping-delivery" className="footer-menu_item">Shipping &
                                            Return
                                            Policy</Link>

                                    </li>
                                    <li>
                                        <Link to="terms-conditions" className="footer-menu_item">Terms of Service </Link>

                                    </li>
                                    <li>
                                        <Link to="contact" className="footer-menu_item">Contact Us </Link>

                                    </li>
                                    <li>
                                        <Link to="#login" data-bs-toggle="modal" className="footer-menu_item">Login </Link>
                               


                                    </li>
                                    <li>
                                        <Link to="#register" data-bs-toggle="modal" className="footer-menu_item">Register </Link>

                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-bottom" style={{background: "#440001"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="footer-bottom-wrap d-flex gap-20 flex-wrap justify-content-between align-items-center">
                                    <div className="footer-menu_item" style={{color: "white"}}>© 2024 Nicky Lingerie Store. All
                                        Rights Reserved</div>
                                    <div className="tf-payment">
                                        <img src="images/payments/visa.png" alt=""/>
                                        <img src="images/payments/img-1.png" alt=""/>
                                        <img src="images/payments/img-2.png" alt=""/>
                                        <img src="images/payments/img-3.png" alt=""/>
                                        <img src="images/payments/img-4.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


        
    <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                style={{transition: "stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 286.138"}}>
            </path>
        </svg>
    </div>
    
    <div className="tf-toolbar-bottom type-1150">
        <div className="toolbar-item active">
            <Link to="#toolbarShopmb" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft">
                <div className="toolbar-icon">
                    <i className="icon-shop"></i>
                </div>
                <div className="toolbar-label">Shop</div>
            </Link>
        </div>

        <div className="toolbar-item">
            <Link to="#canvasSearch" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft">
                <div className="toolbar-icon">
                    <i className="icon-search"></i>
                </div>
                <div className="toolbar-label">Search</div>
            </Link>
        </div>
        <div className="toolbar-item">
            <Link to="#login" data-bs-toggle="modal">
                <div className="toolbar-icon">
                    <i className="icon-account"></i>
                </div>
                <div className="toolbar-label">Account</div>
            </Link>
        </div>
        <div className="toolbar-item">
            <Link to="wishlist">
                <div className="toolbar-icon">
                    <i className="icon-heart"></i>
                    <div className="toolbar-count">0</div>
                </div>
                <div className="toolbar-label">Wishlist</div>
            </Link>
        </div>
        <div className="toolbar-item">
            <Link to="#shoppingCart" data-bs-toggle="modal">
                <div className="toolbar-icon">
                    <i className="icon-bag"></i>
                    <div className="toolbar-count">1</div>
                </div>
                <div className="toolbar-label">Cart</div>
            </Link>
        </div>
    </div>
    
    <div className="modal fade modalDemo" id="modalDemo">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="header">
                    <h5 className="demo-title">Ultimate HTML Theme</h5>
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="mega-menu">
                    <div className="row-demo">
                        <div className="demo-item">
                            <Link to="index">
                                <div className="demo-image position-relative">
                                    <img className="lazyload" data-src="images/demo/home-01.jpg"
                                        src="images/demo/home-01.jpg" alt="home-01"/>
                                    <div className="demo-label">
                                        <span className="demo-new">New</span>
                                        <span>Trend</span>
                                    </div>
                                </div>
                                <span className="demo-name">Home Fashion 01</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-multi-brand">
                                <div className="demo-image position-relative">
                                    <img className="lazyload" data-src="images/demo/home-multi-brand.jpg"
                                        src="images/demo/home-multi-brand.jpg" alt="home-multi-brand"/>
                                    <div className="demo-label">
                                        <span className="demo-new">New</span>
                                        <span className="demo-hot">Hot</span>
                                    </div>
                                </div>
                                <span className="demo-name">Home Multi Brand</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-02">
                                <div className="demo-image position-relative">
                                    <img className="lazyload" data-src="images/demo/home-02.jpg"
                                        src="images/demo/home-02.jpg" alt="home-02"/>
                                    <div className="demo-label">
                                        <span className="demo-hot">Hot</span>
                                    </div>
                                </div>
                                <span className="demo-name">Home Fashion 02</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-03">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-03.jpg"
                                        src="images/demo/home-03.jpg" alt="home-03"/>
                                </div>
                                <span className="demo-name">Home Fashion 03</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-04">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-04.jpg"
                                        src="images/demo/home-04.jpg" alt="home-04"/>
                                </div>
                                <span className="demo-name">Home Fashion 04</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-05">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-05.jpg"
                                        src="images/demo/home-05.jpg" alt="home-05"/>
                                </div>
                                <span className="demo-name">Home Fashion 05</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-06">
                                <div className="demo-image position-relative">
                                    <img className="lazyload" data-src="images/demo/home-06.jpg"
                                        src="images/demo/home-06.jpg" alt="home-06"/>
                                    <div className="demo-label">
                                        <span className="demo-new">New</span>
                                    </div>
                                </div>
                                <span className="demo-name">Home Fashion 06</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-07">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-07.jpg"
                                        src="images/demo/home-07.jpg" alt="home-07"/>
                                </div>
                                <span className="demo-name">Home Fashion 07</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-08">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-08.jpg"
                                        src="images/demo/home-08.jpg" alt="home-08"/>
                                </div>
                                <span className="demo-name">Home Fashion 08</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-skincare">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-skincare.jpg"
                                        src="images/demo/home-skincare.jpg" alt="home-skincare"/>
                                </div>
                                <span className="demo-name">Home Skincare</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-headphone">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-headphone.jpg"
                                        src="images/demo/home-headphone.jpg" alt="home-headphone"/>
                                </div>
                                <span className="demo-name">Home Headphone</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-giftcard">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-giftcard.jpg"
                                        src="images/demo/home-giftcard.jpg" alt="home-gift-card"/>
                                </div>
                                <span className="demo-name">Home Gift Card</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-furniture">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-furniture.jpg"
                                        src="images/demo/home-furniture.jpg" alt="home-furniture"/>
                                </div>
                                <span className="demo-name">Home Furniture</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-furniture-02">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-furniture2.jpg"
                                        src="images/demo/home-furniture2.jpg" alt="home-furniture-2"/>
                                </div>
                                <span className="demo-name">Home Furniture 2</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-skateboard">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-skateboard.jpg"
                                        src="images/demo/home-skateboard.jpg" alt="home-skateboard"/>
                                </div>
                                <span className="demo-name">Home Skateboard</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-stroller">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-stroller.jpg"
                                        src="images/demo/home-stroller.jpg" alt="home-stroller"/>
                                </div>
                                <span className="demo-name">Home Stroller</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-decor">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-decor.jpg"
                                        src="images/demo/home-decor.jpg" alt="home-decor"/>
                                </div>
                                <span className="demo-name">Home Decor</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-electronic">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-electronic.jpg"
                                        src="images/demo/home-electronic.jpg" alt="home-electronic"/>
                                </div>
                                <span className="demo-name">Home Electronic</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-setup-gear">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-setup-gear.jpg"
                                        src="images/demo/home-setup-gear.jpg" alt="home-setup-gear"/>
                                </div>
                                <span className="demo-name">Home Setup Gear</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-dog-accessories">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-dog-accessories.jpg"
                                        src="images/demo/home-dog-accessories.jpg" alt="home-dog-accessories"/>
                                </div>
                                <span className="demo-name">Home Dog Accessories</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-kitchen-wear">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-kitchen.jpg"
                                        src="images/demo/home-kitchen.jpg" alt="home-kitchen-wear"/>
                                </div>
                                <span className="demo-name">Home Kitchen Wear</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-phonecase">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-phonecase.jpg"
                                        src="images/demo/home-phonecase.jpg" alt="home-phonecase"/>
                                </div>
                                <span className="demo-name">Home Phonecase</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-paddle-boards">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home_paddle_board.jpg"
                                        src="images/demo/home_paddle_board.jpg" alt="home-paddle_board"/>
                                </div>
                                <span className="demo-name">Home Paddle Boards</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-glasses">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-glasses.jpg"
                                        src="images/demo/home-glasses.jpg" alt="home-glasses"/>
                                </div>
                                <span className="demo-name">Home Glasses</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-pod-store">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-pod-store.jpg"
                                        src="images/demo/home-pod-store.jpg" alt="home-pod-store"/>
                                </div>
                                <span className="demo-name">Home POD Store</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-activewear">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-activewear.jpg"
                                        src="images/demo/home-activewear.jpg" alt="home-activewear"/>
                                </div>
                                <span className="demo-name">Activewear</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-handbag">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-handbag.jpg"
                                        src="images/demo/home-handbag.jpg" alt="home-handbag"/>
                                </div>
                                <span className="demo-name">Home Handbag</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-tee">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-tee.jpg"
                                        src="images/demo/home-tee.jpg" alt="home-tee"/>
                                </div>
                                <span className="demo-name">Home Tee</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-sock">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-socks.jpg"
                                        src="images/demo/home-socks.jpg" alt="home-sock"/>
                                </div>
                                <span className="demo-name">Home Sock</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-jewerly">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-jewelry.jpg"
                                        src="images/demo/home-jewelry.jpg" alt="home-jewelry"/>
                                </div>
                                <span className="demo-name">Home Jewelry</span>
                            </Link>
                        </div>
                        <div className="demo-item">
                            <Link to="home-sneaker">
                                <div className="demo-image">
                                    <img className="lazyload" data-src="images/demo/home-sneaker.jpg"
                                        src="images/demo/home-sneaker.jpg" alt="home-sneaker"/>
                                </div>
                                <span className="demo-name">Home Sneaker</span>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
   
    <div className="offcanvas offcanvas-start canvas-mb" id="mobileMenu">
        <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
        <div className="mb-canvas-content">
            <div className="mb-body">
                <ul className="nav-ul-mb" id="wrapper-menu-navigation">
                    <li className="nav-mb-item">
                        <Link to="#dropdown-menu-one" className="collapsed mb-menu-link current" data-bs-toggle="collapse"
                            aria-expanded="true" aria-controls="dropdown-menu-one">
                            <span>Home</span>
                             {/* <span className="btn-open-sub"></span> */}
                        </Link>


                    </li>
                    <li className="nav-mb-item">
                        <Link to="#dropdown-menu-two" className="collapsed mb-menu-link current" data-bs-toggle="collapse"
                            aria-expanded="true" aria-controls="dropdown-menu-two">
                            <span>Categories</span>
                            <span className="btn-open-sub"></span>
                        </Link>
                        <div id="dropdown-menu-two" className="collapse">
                            <ul className="sub-nav-menu" id="sub-menu-navigation">
                            {props.catlist.map((object, i) => {
                
                return(
                    <li key={i}>
                        <Link key={object.id} to={`/category-inner/${object.seo}/${object.id}`} className="sub-nav-link collapsed"><span>{object.name}</span> </Link>
                    </li>
                );
            })}  
                               
                            </ul>
                        </div>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="#dropdown-menu-three" className="collapsed mb-menu-link current" data-bs-toggle="collapse"
                            aria-expanded="true" aria-controls="dropdown-menu-three">
                            <span>Contact Us</span>

                        </Link>

                    </li>

                    <li className="nav-mb-item">
                        <Link to="https://themeforest.net/item/ecomus-ultimate-html5-template/53417990?s_rank=3"
                            className="mb-menu-link">Buy now</Link>
                    </li>
                </ul>
                <div className="mb-other-content">
                    <div className="d-flex group-icon">
                        <Link to="wishlist" className="site-nav-icon"><i className="icon icon-heart"></i>Wishlist</Link>
                        <Link to="home-search" className="site-nav-icon"><i className="icon icon-search"></i>Search</Link>
                    </div>
                    <div className="mb-notice">
                        <Link to="contact-1" className="text-need">Need help ?</Link>
                    </div>
                    <ul className="mb-info">
                        <li>Address: 1234 Fashion Street, Suite 567, <br/> New Delhi, dl-1234</li>
                        <li>Email: <b>info@nickylingerie.com</b></li>
                        <li>Phone: <b>+91-6578945365</b></li>
                    </ul>
                </div>
            </div>
            <div className="mb-bottom">
                
              
                <div className="bottom-bar-language">
                    <div className="tf-currencies">
                        <select className="image-select center style-default type-currencies">
                            <option value="" data-thumbnail="images/country/fr.svg">EUR € | France</option>
                            <option value="" data-thumbnail="images/country/de.svg">EUR € | Germany</option>
                            <option value="" data-thumbnail="images/country/us.svg">USD $ | United
                                    States</option>
                            <option value="" data-thumbnail="images/country/vn.svg">VND ₫ | Vietnam</option>
                        </select>
                    </div>
                    <div className="tf-languages">
                        <select className="image-select center style-default type-languages">
                            <option value="">English</option>
                            <option value="">العربية</option>
                            <option value="">简体中文</option>
                            <option value="">اردو</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <div className="offcanvas offcanvas-end canvas-search" id="canvasSearch">
        <div className="canvas-wrapper">
            <header className="tf-search-head">
                <div className="title fw-5">
                    Search on site
                    <div className="close">
                        <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
                    </div>
                </div>
                <div className="tf-search-sticky">
                    <form className="tf-mini-search-frm">
                        <fieldset className="text">
                            <input type="text" placeholder="Search" className="" name="text" tabIndex="0" value=""
                                aria-required="true" required=""/>
                        </fieldset>
                        <button className="" type="submit"><i className="icon-search"></i></button>
                    </form>
                </div>
            </header>
            <div className="canvas-body p-0">
                <div className="tf-search-content">
                    <div className="tf-cart-hide-has-results">
                        <div className="tf-col-quicklink">
                            <div className="tf-search-content-title fw-5">Quick link</div>
                            <ul className="tf-quicklink-list">
                                <li className="tf-quicklink-item">
                                    <Link to="shop-default" className="">Lingerie</Link>
                                </li>
                                <li className="tf-quicklink-item">
                                    <Link to="shop-default" className="">Roleplay dresses</Link>
                                </li>
                                <li className="tf-quicklink-item">
                                    <Link to="shop-default" className="">Garter Set</Link>
                                </li>
                                <li className="tf-quicklink-item">
                                    <Link to="shop-default" className="">Intimate Love Toys</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="tf-col-content">
                            <div className="tf-search-content-title fw-5">Need some inspiration?</div>
                            <div className="tf-search-hidden-inner">
                                <div className="tf-loop-item">
                                    <div className="image">
                                        <Link to="product-detail">
                                            <img src="myimg/rp2.jpg" alt=""/>
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <Link to="product-detail">Roleplay dress</Link>
                                        <div className="tf-product-info-price">
                                            <div className="compare-at-price">₹ 2,999</div>
                                            <div className="price-on-sale fw-6">₹ 1,499</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tf-loop-item">
                                    <div className="image">
                                        <Link to="product-detail">
                                            <img src="myimg/cat6.png" alt=""/>
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <Link to="#">Babydoll dress</Link>
                                        <div className="tf-product-info-price">
                                            <div className="price fw-6">₹ 1,699</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="offcanvas offcanvas-start canvas-mb toolbar-shop-mobile" id="toolbarShopmb">
        <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
        <div className="mb-canvas-content">
            <div className="mb-body">
                <ul className="nav-ul-mb" id="wrapper-menu-navigation">
                    <li className="nav-mb-item">
                        <Link to="shop-default" className="tf-category-link mb-menu-link">
                            <div className="image">
                                <img src="images/shop/cate/cate1.jpg" alt=""/>
                            </div>
                            <span>Accessories</span>
                        </Link>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="shop-default" className="tf-category-link mb-menu-link">
                            <div className="image">
                                <img src="images/shop/cate/cate2.jpg" alt=""/>
                            </div>
                            <span>Dog</span>
                        </Link>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="shop-default" className="tf-category-link mb-menu-link">
                            <div className="image">
                                <img src="images/shop/cate/cate3.jpg" alt=""/>
                            </div>
                            <span>Grocery</span>
                        </Link>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="shop-default" className="tf-category-link mb-menu-link">
                            <div className="image">
                                <img src="images/shop/cate/cate4.png" alt=""/>
                            </div>
                            <span>Handbag</span>
                        </Link>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="#cate-menu-one" className="tf-category-link has-children collapsed mb-menu-link"
                            data-bs-toggle="collapse" aria-expanded="true" aria-controls="cate-menu-one">
                            <div className="image">
                                <img src="images/shop/cate/cate5.jpg" alt=""/>
                            </div>
                            <span>Fashion</span>
                            <span className="btn-open-sub"></span>
                        </Link>
                        <div id="cate-menu-one" className="collapse list-cate">
                            <ul className="sub-nav-menu" id="cate-menu-navigation">
                                <li>
                                    <Link to="#cate-shop-one"
                                        className="tf-category-link has-children sub-nav-link collapsed"
                                        data-bs-toggle="collapse" aria-expanded="true" aria-controls="cate-shop-one">
                                        <div className="image">
                                            <img src="images/shop/cate/cate6.jpg" alt=""/>
                                        </div>
                                        <span>Mens</span>
                                        <span className="btn-open-sub"></span>
                                    </Link>
                                    <div id="cate-shop-one" className="collapse">
                                        <ul className="sub-nav-menu sub-menu-level-2">
                                            <li>
                                                <Link to="shop-default" className="tf-category-link sub-nav-link">
                                                    <div className="image">
                                                        <img src="images/shop/cate/cate1.jpg" alt=""/>
                                                    </div>
                                                    <span>Accessories</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="shop-default" className="tf-category-link sub-nav-link">
                                                    <div className="image">
                                                        <img src="images/shop/cate/cate8.jpg" alt=""/>
                                                    </div>
                                                    <span>Shoes</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <Link to="#cate-shop-two"
                                        className="tf-category-link has-children sub-nav-link collapsed"
                                        data-bs-toggle="collapse" aria-expanded="true" aria-controls="cate-shop-two">
                                        <div className="image">
                                            <img src="images/shop/cate/cate9.jpg" alt=""/>
                                        </div>
                                        <span>Womens</span>
                                        <span className="btn-open-sub"></span>
                                    </Link>
                                    <div id="cate-shop-two" className="collapse">
                                        <ul className="sub-nav-menu sub-menu-level-2">
                                            <li>
                                                <Link to="shop-default" className="tf-category-link sub-nav-link">
                                                    <div className="image">
                                                        <img src="images/shop/cate/cate4.png" alt=""/>
                                                    </div>
                                                    <span>Handbag</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="shop-default" className="tf-category-link sub-nav-link">
                                                    <div className="image">
                                                        <img src="images/shop/cate/cate7.jpg" alt=""/>
                                                    </div>
                                                    <span>Tee</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="#cate-menu-two" className="tf-category-link has-children collapsed mb-menu-link"
                            data-bs-toggle="collapse" aria-expanded="true" aria-controls="cate-menu-two">
                            <div className="image">
                                <img src="images/shop/cate/cate6.jpg" alt=""/>
                            </div>
                            <span>Men</span>
                            <span className="btn-open-sub"></span>
                        </Link>
                        <div id="cate-menu-two" className="collapse list-cate">
                            <ul className="sub-nav-menu" id="cate-menu-navigation1">
                                <li>
                                    <Link to="shop-default" className="tf-category-link sub-nav-link">
                                        <div className="image">
                                            <img src="images/shop/cate/cate1.jpg" alt=""/>
                                        </div>
                                        <span>Accessories</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="shop-default" className="tf-category-link sub-nav-link">
                                        <div className="image">
                                            <img src="images/shop/cate/cate8.jpg" alt=""/>
                                        </div>
                                        <span>Shoes</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="shop-default" className="tf-category-link mb-menu-link">
                            <div className="image">
                                <img src="images/shop/cate/cate7.jpg" alt=""/>
                            </div>
                            <span>Tee</span>
                        </Link>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="shop-default" className="tf-category-link mb-menu-link">
                            <div className="image">
                                <img src="images/shop/cate/cate8.jpg" alt=""/>
                            </div>
                            <span>Shoes</span>
                        </Link>
                    </li>
                    <li className="nav-mb-item">
                        <Link to="#cate-menu-three" className="tf-category-link has-children collapsed mb-menu-link"
                            data-bs-toggle="collapse" aria-expanded="true" aria-controls="cate-menu-three">
                            <div className="image">
                                <img src="images/shop/cate/cate9.jpg" alt=""/>
                            </div>
                            <span>Women</span>
                            <span className="btn-open-sub"></span>
                        </Link>
                        <div id="cate-menu-three" className="collapse list-cate">
                            <ul className="sub-nav-menu" id="cate-menu-navigation2">
                                <li>
                                    <Link to="shop-default" className="tf-category-link sub-nav-link">
                                        <div className="image">
                                            <img src="images/shop/cate/cate4.png" alt=""/>
                                        </div>
                                        <span>Handbag</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="shop-default" className="tf-category-link sub-nav-link">
                                        <div className="image">
                                            <img src="images/shop/cate/cate7.jpg" alt=""/>
                                        </div>
                                        <span>Tee</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="mb-bottom">
                <Link to="shop-default" className="tf-btn fw-5 btn-line">View all collection<i
                        className="icon icon-arrow1-top-left"></i></Link>
            </div>
        </div>
    </div>
   
    <div className="modal modalCentered fade form-sign-in modal-part-content" id="login">
        <div className="modal-dialog modal-dialog-centered">
        
            <div className="modal-content">
            {props.lmesg !== "" && (
          <div className={`alert alert-${props.lmesg.typ}`} role="alert">
            {props.lmesg.msg}
          </div>
        )}
                <div className="header">
                    <div className="demo-title">Log in</div>
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="tf-login-form">
                    <form onSubmit={props.loginUser} action="my-account" acceptCharset="utf-8">
                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" 
                            " type="email" name="email" id="login_email" onChange={(e) => handleChange(e)} value={inputValues.email}/>
                            <label className="tf-field-label" htmlFor="login_email">Email *</label>
                            {validation.email && (
            <p style={{ color: "red" }}>{validation.email}</p>
          )}
                        </div>
                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" " type="password" name="password" id="login_password" onChange={(e) => handleChange(e)} value={inputValues.password}/>
                            <label className="tf-field-label" htmlFor="login_password">Password *</label>
                            {validation.password && (
            <p style={{ color: "red" }}>{validation.password}</p>
          )}
                        </div>
                        <div>
                            <Link to="#forgotPassword" data-bs-toggle="modal" className="btn-link link">Forgot your
                                password?</Link>
                        </div>
                        <div className="bottom">
                            <div className="w-100">
                                <button type="submit"
                                    className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center" onClick={handleChange}><span>Log
                                        in</span></button>
                            </div>
                            <div className="w-100">
                                <Link to="#register" data-bs-toggle="modal" className="btn-link fw-6 w-100 link">
                                    New customer? Create your account
                                    <i className="icon icon-arrow1-top-left"></i>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div className="modal modalCentered fade form-sign-in modal-part-content" id="forgotPassword">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="header">
                    <div className="demo-title">Reset your password</div>
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="tf-login-form">
                    <form className="">
                        <div>
                            <p>Sign up for early Sale access plus tailored new arrivals, trends and promotions. To opt
                                out, click unsubscribe in our emails</p>
                        </div>
                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" " type="email" name=""/>
                            <label className="tf-field-label" htmlFor="">Email *</label>
                        </div>
                        <div>
                            <Link to="#login" data-bs-toggle="modal" className="btn-link link">Cancel</Link>
                        </div>
                        <div className="bottom">
                            <div className="w-100">
                                <button type="submit"
                                    className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center"><span>Reset
                                        password</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div className="modal modalCentered fade form-sign-in modal-part-content" id="register">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="header">
                    <div className="demo-title">Register</div>
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="tf-login-form">
                {props.mesg !=='' &&
        <div className={`alert alert-${props.mesg.typ}`} role="alert">{props.mesg.msg}</div>
      }
                    <form method="POST" onSubmit={props.handleSubmit}>
                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" " type="text" name="fName" onChange={(e) => props.handleChange(e)} value={props.inputValues.fName}/>
                            <label className="tf-field-label" htmlFor="fName">First name</label>
                            {props.validation.fName && <p style={{color:"red"}}>{props.validation.fName}</p>}
            {props.validation.fName}
                        </div>
                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" " type="text" name="lName" onChange={(e) => props.handleChange(e)} value={props.inputValues.lName}/>
                            <label className="tf-field-label" htmlFor="lName">Last name</label>
                            {props.validation.lName && <p style={{color:"red"}}>{props.validation.lName}</p>}
            {props.validation.lName}
                        </div>
                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" " type="email" name="email"  onChange={(e) => props.handleChange(e)} value={props.inputValues.email}/>
                            <label className="tf-field-label" htmlFor="email">Email *</label>
                            {props.validation.email && <p style={{color:"red"}}>{props.validation.email}</p>}
            {props.validation.email}
                        </div>
                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" " type="password" name="password"  onChange={(e) => props.handleChange(e)} value={props.inputValues.password}/>
                            <label className="tf-field-label" htmlFor="password">Password *</label>
                            {props.validation.password && <p style={{color:"red"}}>{props.validation.password}</p>}
            {props.validation.password}
                        </div>

                        <div className="tf-field style-1">
                            <input className="tf-field-input tf-input" placeholder=" " type="text" name="phone"  onChange={(e) => props.handleChange(e)} value={props.inputValues.phone}/>
                            <label className="tf-field-label" htmlFor="phone">Phone</label>
                            {props.validation.phone && <p style={{color:"red"}}>{props.validation.phone}</p>}
            {props.validation.phone}
                        </div>
                        <div className="bottom">
                            <div className="w-100">
                                <button type='submit'
                                    className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center" name="submit"  onClick={props.handleChange}><span>Register</span></button>
                            </div>
                            <div className="w-100">
                                <Link to="#login" data-bs-toggle="modal" className="btn-link fw-6 w-100 link">
                                    Already have an account? Log in here
                                    <i className="icon icon-arrow1-top-left"></i>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
    <div className="modal fullRight fade modal-shopping-cart" id="shoppingCart">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="header">
                    <div className="title fw-5">Shopping cart</div>
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="wrap">
                    <div className="tf-mini-cart-threshold">
                        <div className="tf-progress-bar">
                            <span style={{width: "50%"}}></span>
                        </div>
                        <div className="tf-progress-msg">
                            Buy <span className="price fw-6">₹ 1,299</span> more to enjoy <span className="fw-6">Free
                                Shipping</span>
                        </div>
                    </div>
                    <div className="tf-mini-cart-wrap">
                        <div className="tf-mini-cart-main">
                            <div className="tf-mini-cart-sroll">
                                <div className="tf-mini-cart-items">
                                    <div className="tf-mini-cart-item">
                                        <div className="tf-mini-cart-image">
                                            <Link to="product-detail">
                                                <img src="myimg/ling1.jpg" alt=""/>
                                            </Link>
                                        </div>
                                        <div className="tf-mini-cart-info">
                                            <Link className="title link" to="product-detail">Lingerie</Link>
                                            <div className="meta-variant">Light gray</div>
                                            <div className="price fw-6">₹ 1,299</div>
                                            <div className="tf-mini-cart-btns">
                                                <div className="wg-quantity small">
                                                    <span className="btn-quantity minus-btn">-</span>
                                                    <input type="text" name="number" value="1"/>
                                                    <span className="btn-quantity plus-btn">+</span>
                                                </div>
                                                <div className="tf-mini-cart-remove">Remove</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- <div className="tf-minicart-recommendations">
                                    <div className="tf-minicart-recommendations-heading">
                                        <div className="tf-minicart-recommendations-title">You may also like</div>
                                        <div className="sw-dots small style-2 cart-slide-pagination"></div>
                                    </div>
                                    <div className="swiper tf-cart-slide">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="tf-minicart-recommendations-item">
                                                    <div className="tf-minicart-recommendations-item-image">
                                                        <Link to="product-detail">
                                                            <img src="images/products/white-3.jpg" alt="">
                                                        </Link>
                                                    </div>
                                                    <div className="tf-minicart-recommendations-item-infos flex-grow-1">
                                                        <Link className="title" to="product-detail">Loose Fit
                                                            Sweatshirt</Link>
                                                        <div className="price">$25.00</div>
                                                    </div>
                                                    <div className="tf-minicart-recommendations-item-quickview">
                                                        <div className="btn-show-quickview quickview hover-tooltip">
                                                            <span className="icon icon-view"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="tf-minicart-recommendations-item">
                                                    <div className="tf-minicart-recommendations-item-image">
                                                        <Link to="product-detail">
                                                            <img src="images/products/white-2.jpg" alt="">
                                                        </Link>
                                                    </div>
                                                    <div className="tf-minicart-recommendations-item-infos flex-grow-1">
                                                        <Link className="title" to="product-detail">Loose Fit Hoodie</Link>
                                                        <div className="price">$25.00</div>
                                                    </div>
                                                    <div className="tf-minicart-recommendations-item-quickview">
                                                        <div className="btn-show-quickview quickview hover-tooltip">
                                                            <span className="icon icon-view"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> --> */}
                            </div>
                        </div>
                        <div className="tf-mini-cart-bottom">
                            <div className="tf-mini-cart-tool">
                                <div className="tf-mini-cart-tool-btn btn-add-note">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"
                                        fill="currentColor">
                                        <path
                                            d="M5.12187 16.4582H2.78952C2.02045 16.4582 1.39476 15.8325 1.39476 15.0634V2.78952C1.39476 2.02045 2.02045 1.39476 2.78952 1.39476H11.3634C12.1325 1.39476 12.7582 2.02045 12.7582 2.78952V7.07841C12.7582 7.46357 13.0704 7.77579 13.4556 7.77579C13.8407 7.77579 14.1529 7.46357 14.1529 7.07841V2.78952C14.1529 1.25138 12.9016 0 11.3634 0H2.78952C1.25138 0 0 1.25138 0 2.78952V15.0634C0 16.6015 1.25138 17.8529 2.78952 17.8529H5.12187C5.50703 17.8529 5.81925 17.5407 5.81925 17.1555C5.81925 16.7704 5.50703 16.4582 5.12187 16.4582Z">
                                        </path>
                                        <path
                                            d="M15.3882 10.0971C14.5724 9.28136 13.2452 9.28132 12.43 10.0965L8.60127 13.9168C8.51997 13.9979 8.45997 14.0979 8.42658 14.2078L7.59276 16.9528C7.55646 17.0723 7.55292 17.1993 7.58249 17.3207C7.61206 17.442 7.67367 17.5531 7.76087 17.6425C7.84807 17.7319 7.95768 17.7962 8.07823 17.8288C8.19879 17.8613 8.32587 17.8609 8.44621 17.8276L11.261 17.0479C11.3769 17.0158 11.4824 16.9543 11.5675 16.8694L15.3882 13.0559C16.2039 12.2401 16.2039 10.9129 15.3882 10.0971ZM10.712 15.7527L9.29586 16.145L9.71028 14.7806L12.2937 12.2029L13.2801 13.1893L10.712 15.7527ZM14.4025 12.0692L14.2673 12.204L13.2811 11.2178L13.4157 11.0834C13.6876 10.8115 14.1301 10.8115 14.402 11.0834C14.6739 11.3553 14.6739 11.7977 14.4025 12.0692Z">
                                        </path>
                                    </svg>
                                </div>
                                <div className="tf-mini-cart-tool-btn btn-add-gift">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18"
                                        fill="currentColor">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M2.99566 2.73409C2.99566 0.55401 5.42538 -0.746668 7.23916 0.463462L8.50073 1.30516L9.7623 0.463462C11.5761 -0.746668 14.0058 0.55401 14.0058 2.73409V3.24744H14.8225C15.9633 3.24744 16.8881 4.17233 16.8881 5.31312V6.82566C16.8881 7.21396 16.5734 7.52873 16.1851 7.52873H15.8905V15.1877C15.8905 15.1905 15.8905 15.1933 15.8905 15.196C15.886 16.7454 14.6286 18 13.0782 18H3.92323C2.37003 18 1.11091 16.7409 1.11091 15.1877V7.52877H0.81636C0.42806 7.52877 0.113281 7.21399 0.113281 6.82569V5.31316C0.113281 4.17228 1.03812 3.24744 2.179 3.24744H2.99566V2.73409ZM4.40181 3.24744H7.79765V2.52647L6.45874 1.63317C5.57987 1.0468 4.40181 1.67677 4.40181 2.73409V3.24744ZM9.20381 2.52647V3.24744H12.5996V2.73409C12.5996 1.67677 11.4216 1.0468 10.5427 1.63317L9.20381 2.52647ZM2.179 4.6536C1.81472 4.6536 1.51944 4.94888 1.51944 5.31316V6.12261H5.73398L5.734 4.6536H2.179ZM5.73401 7.52877V13.9306C5.73401 14.1806 5.86682 14.4119 6.08281 14.5379C6.29879 14.6639 6.56545 14.6657 6.78312 14.5426L8.50073 13.5715L10.2183 14.5426C10.436 14.6657 10.7027 14.6639 10.9187 14.5379C11.1346 14.4119 11.2674 14.1806 11.2674 13.9306V7.52873H14.4844V15.1603C14.4844 15.1627 14.4843 15.1651 14.4843 15.1675V15.1877C14.4843 15.9643 13.8548 16.5938 13.0782 16.5938H3.92323C3.14663 16.5938 2.51707 15.9643 2.51707 15.1877V7.52877H5.73401ZM15.482 6.12258V5.31312C15.482 4.94891 15.1867 4.6536 14.8225 4.6536H11.2674V6.12258H15.482ZM9.86129 4.6536H7.14017V12.7254L8.15469 12.1518C8.36941 12.0304 8.63204 12.0304 8.84676 12.1518L9.86129 12.7254V4.6536Z">
                                        </path>
                                    </svg>
                                </div>
                                <div className="tf-mini-cart-tool-btn btn-estimate-shipping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18"
                                        fill="currentColor">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M0 0.811989C0 0.36354 0.36354 0 0.811989 0H15.4278C15.8763 0 16.2398 0.36354 16.2398 0.811989V3.10596H21.0144C23.6241 3.10596 25.8643 5.05894 25.8643 7.61523V14.6414C25.8643 15.0899 25.5007 15.4534 25.0523 15.4534H23.545C23.2139 16.9115 21.9098 18 20.3514 18C18.7931 18 17.4889 16.9115 17.1578 15.4534H8.69534C8.36423 16.9115 7.0601 18 5.50175 18C3.9434 18 2.63927 16.9115 2.30815 15.4534H0.811989C0.36354 15.4534 0 15.0899 0 14.6414V0.811989ZM2.35089 13.8294C2.74052 12.4562 4.00366 11.4503 5.50175 11.4503C6.99983 11.4503 8.26298 12.4562 8.6526 13.8294H14.6158V1.62398H1.62398V13.8294H2.35089ZM16.2398 4.72994V7.95749H24.2403V7.61523C24.2403 6.08759 22.8649 4.72994 21.0144 4.72994H16.2398ZM24.2403 9.58147H16.2398V13.8294H17.2006C17.5902 12.4562 18.8533 11.4503 20.3514 11.4503C21.8495 11.4503 23.1126 12.4562 23.5023 13.8294H24.2403V9.58147ZM5.50175 13.0743C4.58999 13.0743 3.85087 13.8134 3.85087 14.7251C3.85087 15.6369 4.58999 16.376 5.50175 16.376C6.41351 16.376 7.15263 15.6369 7.15263 14.7251C7.15263 13.8134 6.41351 13.0743 5.50175 13.0743ZM20.3514 13.0743C19.4397 13.0743 18.7005 13.8134 18.7005 14.7251C18.7005 15.6369 19.4397 16.376 20.3514 16.376C21.2632 16.376 22.0023 15.6369 22.0023 14.7251C22.0023 13.8134 21.2632 13.0743 20.3514 13.0743Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="tf-mini-cart-bottom-wrap">
                                <div className="tf-cart-totals-discounts">
                                    <div className="tf-cart-total">Subtotal</div>
                                    <div className="tf-totals-total-value fw-6">₹ 1,299</div>
                                </div>
                                <div className="tf-cart-tax">Taxes and <Link to="#">shipping</Link> calculated at checkout</div>
                                <div className="tf-mini-cart-line"></div>
                                <div className="tf-cart-checkbox">
                                    <div className="tf-checkbox-wrapp">
                                        <input className="" type="checkbox" id="CartDrawer-Form_agree"
                                            name="agree_checkbox"/>
                                        <div>
                                            <i className="icon-check"></i>
                                        </div>
                                    </div>
                                    <label htmlFor="CartDrawer-Form_agree">
                                        I agree with the
                                        <Link to="#" title="Terms of Service">terms and conditions</Link>
                                    </label>
                                </div>
                                <div className="tf-mini-cart-view-checkout">
                                    <Link to="view-cart"
                                        className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center">View
                                        cart</Link>
                                    <Link to="checkout"
                                        className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center"><span>Check
                                            out</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="tf-mini-cart-tool-openable add-note">
                            <div className="overplay tf-mini-cart-tool-close"></div>
                            <div className="tf-mini-cart-tool-content">
                                <label htmlFor="Cart-note" className="tf-mini-cart-tool-text">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18"
                                            viewBox="0 0 16 18" fill="currentColor">
                                            <path
                                                d="M5.12187 16.4582H2.78952C2.02045 16.4582 1.39476 15.8325 1.39476 15.0634V2.78952C1.39476 2.02045 2.02045 1.39476 2.78952 1.39476H11.3634C12.1325 1.39476 12.7582 2.02045 12.7582 2.78952V7.07841C12.7582 7.46357 13.0704 7.77579 13.4556 7.77579C13.8407 7.77579 14.1529 7.46357 14.1529 7.07841V2.78952C14.1529 1.25138 12.9016 0 11.3634 0H2.78952C1.25138 0 0 1.25138 0 2.78952V15.0634C0 16.6015 1.25138 17.8529 2.78952 17.8529H5.12187C5.50703 17.8529 5.81925 17.5407 5.81925 17.1555C5.81925 16.7704 5.50703 16.4582 5.12187 16.4582Z">
                                            </path>
                                            <path
                                                d="M15.3882 10.0971C14.5724 9.28136 13.2452 9.28132 12.43 10.0965L8.60127 13.9168C8.51997 13.9979 8.45997 14.0979 8.42658 14.2078L7.59276 16.9528C7.55646 17.0723 7.55292 17.1993 7.58249 17.3207C7.61206 17.442 7.67367 17.5531 7.76087 17.6425C7.84807 17.7319 7.95768 17.7962 8.07823 17.8288C8.19879 17.8613 8.32587 17.8609 8.44621 17.8276L11.261 17.0479C11.3769 17.0158 11.4824 16.9543 11.5675 16.8694L15.3882 13.0559C16.2039 12.2401 16.2039 10.9129 15.3882 10.0971ZM10.712 15.7527L9.29586 16.145L9.71028 14.7806L12.2937 12.2029L13.2801 13.1893L10.712 15.7527ZM14.4025 12.0692L14.2673 12.204L13.2811 11.2178L13.4157 11.0834C13.6876 10.8115 14.1301 10.8115 14.402 11.0834C14.6739 11.3553 14.6739 11.7977 14.4025 12.0692Z">
                                            </path>
                                        </svg>
                                    </div>
                                    <span>Add Order Note</span>
                                </label>
                                <textarea name="note" id="Cart-note" placeholder="How can we help you?"></textarea>
                                <div className="tf-cart-tool-btns justify-content-center">
                                    <div
                                        className="tf-mini-cart-tool-primary text-center w-100 fw-6 tf-mini-cart-tool-close">
                                        Close</div>
                                </div>
                            </div>
                        </div>
                        <div className="tf-mini-cart-tool-openable add-gift">
                            <div className="overplay tf-mini-cart-tool-close"></div>
                            <form className="tf-product-form-addgift">
                                <div className="tf-mini-cart-tool-content">
                                    <div className="tf-mini-cart-tool-text">
                                        <div className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="currentColor">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M4.65957 3.64545C4.65957 0.73868 7.89921 -0.995558 10.3176 0.617949L11.9997 1.74021L13.6818 0.617949C16.1001 -0.995558 19.3398 0.73868 19.3398 3.64545V4.32992H20.4286C21.9498 4.32992 23.1829 5.56311 23.1829 7.08416V9.10087C23.1829 9.61861 22.7632 10.0383 22.2454 10.0383H21.8528V20.2502C21.8528 20.254 21.8527 20.2577 21.8527 20.2614C21.8467 22.3272 20.1702 24 18.103 24H5.89634C3.82541 24 2.14658 22.3212 2.14658 20.2502V10.0384H1.75384C1.23611 10.0384 0.816406 9.61865 0.816406 9.10092V7.08421C0.816406 5.56304 2.04953 4.32992 3.57069 4.32992H4.65957V3.64545ZM6.53445 4.32992H11.0622V3.36863L9.27702 2.17757C8.10519 1.39573 6.53445 2.2357 6.53445 3.64545V4.32992ZM12.9371 3.36863V4.32992H17.4649V3.64545C17.4649 2.2357 15.8942 1.39573 14.7223 2.17756L12.9371 3.36863ZM3.57069 6.2048C3.08499 6.2048 2.69128 6.59851 2.69128 7.08421V8.16348H8.31067L8.3107 6.2048H3.57069ZM8.31071 10.0384V18.5741C8.31071 18.9075 8.48779 19.2158 8.77577 19.3838C9.06376 19.5518 9.4193 19.5542 9.70953 19.3901L11.9997 18.0953L14.2898 19.3901C14.58 19.5542 14.9356 19.5518 15.2236 19.3838C15.5115 19.2158 15.6886 18.9075 15.6886 18.5741V10.0383H19.9779V20.2137C19.9778 20.2169 19.9778 20.2201 19.9778 20.2233V20.2502C19.9778 21.2857 19.1384 22.1251 18.103 22.1251H5.89634C4.86088 22.1251 4.02146 21.2857 4.02146 20.2502V10.0384H8.31071ZM21.308 8.16344V7.08416C21.308 6.59854 20.9143 6.2048 20.4286 6.2048H15.6886V8.16344H21.308ZM13.8138 6.2048H10.1856V16.9672L11.5383 16.2024C11.8246 16.0405 12.1748 16.0405 12.461 16.2024L13.8138 16.9672V6.2048Z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div className="tf-gift-wrap-infos">
                                            <p>Do you want a gift wrap?</p>
                                            Only
                                            <span className="price fw-6">$5.00</span>
                                        </div>
                                    </div>
                                    <div className="tf-cart-tool-btns">
                                        <button type="submit"
                                            className="tf-btn fw-6 w-100 justify-content-center btn-fill animate-hover-btn radius-3"><span>Add
                                                a gift wrap</span></button>
                                        <div
                                            className="tf-mini-cart-tool-primary text-center w-100 fw-6 tf-mini-cart-tool-close">
                                            Cancel</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="tf-mini-cart-tool-openable estimate-shipping">
                            <div className="overplay tf-mini-cart-tool-close"></div>
                            <div className="tf-mini-cart-tool-content">
                                <div className="tf-mini-cart-tool-text">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15"
                                            viewBox="0 0 21 15" fill="currentColor">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M0.441406 1.13155C0.441406 0.782753 0.724159 0.5 1.07295 0.5H12.4408C12.7896 0.5 13.0724 0.782753 13.0724 1.13155V2.91575H16.7859C18.8157 2.91575 20.5581 4.43473 20.5581 6.42296V11.8878C20.5581 12.2366 20.2753 12.5193 19.9265 12.5193H18.7542C18.4967 13.6534 17.4823 14.5 16.2703 14.5C15.0582 14.5 14.0439 13.6534 13.7864 12.5193H7.20445C6.94692 13.6534 5.93259 14.5 4.72054 14.5C3.50849 14.5 2.49417 13.6534 2.23664 12.5193H1.07295C0.724159 12.5193 0.441406 12.2366 0.441406 11.8878V1.13155ZM2.26988 11.2562C2.57292 10.1881 3.55537 9.40578 4.72054 9.40578C5.88572 9.40578 6.86817 10.1881 7.17121 11.2562H11.8093V1.76309H1.7045V11.2562H2.26988ZM13.0724 4.17884V6.68916H19.295V6.42296C19.295 5.2348 18.2252 4.17884 16.7859 4.17884H13.0724ZM19.295 7.95226H13.0724V11.2562H13.8196C14.1227 10.1881 15.1051 9.40578 16.2703 9.40578C17.4355 9.40578 18.4179 10.1881 18.7209 11.2562H19.295V7.95226ZM4.72054 10.6689C4.0114 10.6689 3.43652 11.2437 3.43652 11.9529C3.43652 12.662 4.0114 13.2369 4.72054 13.2369C5.42969 13.2369 6.00456 12.662 6.00456 11.9529C6.00456 11.2437 5.42969 10.6689 4.72054 10.6689ZM16.2703 10.6689C15.5611 10.6689 14.9863 11.2437 14.9863 11.9529C14.9863 12.662 15.5611 13.2369 16.2703 13.2369C16.9794 13.2369 17.5543 12.662 17.5543 11.9529C17.5543 11.2437 16.9794 10.6689 16.2703 10.6689Z">
                                            </path>
                                        </svg>
                                    </div>
                                    <span className="fw-6">Estimate Shipping</span>
                                </div>
                                <div className="field">
                                    <p>Country</p>
                                    <select className="tf-select w-100" id="ShippingCountry_CartDrawer-Form"
                                        name="address[country]" data-default="">
                                        <option value="---" data-provinces="[]">---</option>
                                        <option value="Australia">
                                            Australia</option>
                                        <option value="Austria" data-provinces="[]">Austria</option>
                                        <option value="Belgium" data-provinces="[]">Belgium</option>
                                        <option value="Canada">
                                            Canada</option>
                                        <option value="Czech Republic" data-provinces="[]">Czechia</option>
                                        <option value="Denmark" data-provinces="[]">Denmark</option>
                                        <option value="Finland" data-provinces="[]">Finland</option>
                                        <option value="France" data-provinces="[]">France</option>
                                        <option value="Germany" data-provinces="[]">Germany</option>
                                        <option value="Hong Kong">
                                            Hong Kong SAR</option>
                                        <option value="Ireland">
                                            Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy"></option>
                                        <option value="Japan">Japan</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Netherlands" data-provinces="[]">Netherlands</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Norway" data-provinces="[]">Norway</option>
                                        <option value="Poland" data-provinces="[]">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Singapore" data-provinces="[]">Singapore</option>
                                        <option value="South Korea">South Korea</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Sweden" data-provinces="[]">Sweden</option>
                                        <option value="Switzerland" data-provinces="[]">Switzerland</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="Vietnam" data-provinces="[]">Vietnam</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <p>Zip code</p>
                                    <input type="text" name="text" placeholder=""/>
                                </div>
                                <div className="tf-cart-tool-btns">
                                    <Link to="#"
                                        className="tf-btn fw-6 justify-content-center btn-fill w-100 animate-hover-btn radius-3"><span>Estimate</span></Link>
                                    <div
                                        className="tf-mini-cart-tool-primary text-center fw-6 w-100 tf-mini-cart-tool-close">
                                        Cancel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <div className="offcanvas offcanvas-bottom canvas-compare" id="compare">
        <div className="canvas-wrapper">
            <header className="canvas-header">
                <div className="close-popup">
                    <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
                </div>
            </header>
            <div className="canvas-body">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tf-compare-list">
                                <div className="tf-compare-head">
                                    <div className="title">Compare Products</div>
                                </div>
                                <div className="tf-compare-offcanvas">
                                    <div className="tf-compare-item">
                                        <div className="position-relative">
                                            <div className="icon">
                                                <i className="icon-close"></i>
                                            </div>
                                            <Link to="product-detail">
                                                <img className="radius-3" src="images/products/orange-1.jpg" alt=""/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="tf-compare-item">
                                        <div className="position-relative">
                                            <div className="icon">
                                                <i className="icon-close"></i>
                                            </div>
                                            <Link to="product-detail">
                                                <img className="radius-3" src="images/products/pink-1.jpg" alt=""/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="tf-compare-buttons">
                                    <div className="tf-compare-buttons-wrap">
                                        <Link to="compare"
                                            className="tf-btn radius-3 btn-fill justify-content-center fw-6 fs-14 flex-grow-1 animate-hover-btn ">Compare</Link>
                                        <div className="tf-compapre-button-clear-all link">
                                            Clear All
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="modal fade modalDemo" id="quick_add">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="header">
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="wrap">
                    <div className="tf-product-info-item">
                        <div className="image">
                            <img src="myimg/gar1.jpg" alt=""/>
                        </div>
                        <div className="content">
                            <Link to="product-detail">Garter Set</Link>
                            <div className="tf-product-info-price">
                                {/* <!-- <div className="price-on-sale">$8.00</div>
                                <div className="compare-at-price">$10.00</div>
                                <div className="badges-on-sale"><span>20</span>% OFF</div> --> */}
                                <div className="price">₹ 1,799</div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-product-info-variant-picker mb_15">
                        <div className="variant-picker-item">
                            <div className="variant-picker-label">
                                Color: <span className="fw-6 variant-picker-label-value">Orange</span>
                            </div>
                            <div className="variant-picker-values">
                                <input id="values-orange" type="radio" name="color" checked=""/>
                                <label className="hover-tooltip radius-60" htmlFor="values-orange" data-value="Orange">
                                    <span className="btn-checkbox bg-color-orange"></span>
                                    <span className="tooltip">Orange</span>
                                </label>
                                <input id="values-black" type="radio" name="color"/>
                                <label className=" hover-tooltip radius-60" htmlFor="values-black" data-value="Black">
                                    <span className="btn-checkbox bg-color-black"></span>
                                    <span className="tooltip">Black</span>
                                </label>
                                <input id="values-white" type="radio" name="color"/>
                                <label className="hover-tooltip radius-60" htmlFor="values-white" data-value="White">
                                    <span className="btn-checkbox bg-color-white"></span>
                                    <span className="tooltip">White</span>
                                </label>
                            </div>
                        </div>
                        <div className="variant-picker-item">
                            <div className="variant-picker-label">
                                Size: <span className="fw-6 variant-picker-label-value">S</span>
                            </div>
                            <div className="variant-picker-values">
                                <input type="radio" name="size" id="values-s" checked=""/>
                                <label className="style-text" htmlFor="values-s" data-value="S">
                                    <p>S</p>
                                </label>
                                <input type="radio" name="size" id="values-m"/>
                                <label className="style-text" htmlFor="values-m" data-value="M">
                                    <p>M</p>
                                </label>
                                <input type="radio" name="size" id="values-l"/>
                                <label className="style-text" htmlFor="values-l" data-value="L">
                                    <p>L</p>
                                </label>
                                <input type="radio" name="size" id="values-xl"/>
                                <label className="style-text" htmlFor="values-xl" data-value="XL">
                                    <p>XL</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="tf-product-info-quantity mb_15">
                        <div className="quantity-title fw-6">Quantity</div>
                        <div className="wg-quantity">
                            <span className="btn-quantity minus-btn">-</span>
                            <input type="text" name="number" value="1"/>
                            <span className="btn-quantity plus-btn">+</span>
                        </div>
                    </div>
                    <div className="tf-product-info-buy-button">
                        <form className="">
                            <Link to="#shoppingCart"
                                className="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn "
                                data-bs-toggle="modal"><span>Add
                                    to cart -&nbsp;</span><span className="tf-qty-price">$18.00</span></Link>
                            <div className="tf-product-btn-wishlist btn-icon-action">
                                <i className="icon-heart"></i>
                                <i className="icon-delete"></i>
                            </div>
                            <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                className="tf-product-btn-wishlist box-icon bg_white compare btn-icon-action">
                                <span className="icon icon-compare"></span>
                                <span className="icon icon-check"></span>
                            </Link>
                            <div className="w-100">
                                <Link to="#" className="btns-full">Buy with <img src="images/payments/paypal.png" alt=""/></Link>
                                <Link to="#" className="payment-more-option">More payment options</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div className="modal fade modalDemo" id="quick_view">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="header">
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="wrap">
                    <div className="tf-product-media-wrap">
                        <div className="swiper tf-single-slide">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="item">
                                        <img src="myimg/ling1.jpg" alt=""/>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="item">
                                        <img src="myimg/ling1.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-button-next button-style-arrow single-slide-prev"></div>
                            <div className="swiper-button-prev button-style-arrow single-slide-next"></div>
                        </div>
                    </div>
                    <div className="tf-product-info-wrap position-relative">
                        <div className="tf-product-info-list">
                            <div className="tf-product-info-title">
                                <h5><Link className="link" to="product-detail">Babydoll Lingerie</Link></h5>
                            </div>
                            <div className="tf-product-info-badges">
                                <div className="badges text-uppercase">Best seller</div>
                                <div className="product-status-content">
                                    <i className="icon-lightning"></i>
                                    <p className="fw-6">Selling fast! 48 people have this in their carts.</p>
                                </div>
                            </div>
                            <div className="tf-product-info-price">
                                <div className="price">₹ 1,799</div>
                            </div>
                            <div className="tf-product-description">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum neque eos quidem odit
                                    eum voluptatum!</p>
                            </div>
                            <div className="tf-product-info-variant-picker">
                                <div className="variant-picker-item">
                                    <div className="variant-picker-label">
                                        Color: <span className="fw-6 variant-picker-label-value">Orange</span>
                                    </div>
                                    <div className="variant-picker-values">
                                        <input id="values-orange-1" type="radio" name="color-1" checked=""/>
                                        <label className="hover-tooltip radius-60" htmlFor="values-orange-1"
                                            data-value="Orange">
                                            <span className="btn-checkbox bg-color-orange"></span>
                                            <span className="tooltip">Orange</span>
                                        </label>
                                        <input id="values-black-1" type="radio" name="color-1"/>
                                        <label className=" hover-tooltip radius-60" htmlFor="values-black-1" data-value="Black">
                                            <span className="btn-checkbox bg-color-black"></span>
                                            <span className="tooltip">Black</span>
                                        </label>
                                        <input id="values-white-1" type="radio" name="color-1"/>
                                        <label className="hover-tooltip radius-60" htmlFor="values-white-1" data-value="White">
                                            <span className="btn-checkbox bg-color-white"></span>
                                            <span className="tooltip">White</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="variant-picker-item">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="variant-picker-label">
                                            Size: <span className="fw-6 variant-picker-label-value">S</span>
                                        </div>
                                        <div className="find-size btn-choose-size fw-6">Find your size</div>
                                    </div>
                                    <div className="variant-picker-values">
                                        <input type="radio" name="size-1" id="values-s-1" checked=""/>
                                        <label className="style-text" htmlFor="values-s-1" data-value="S">
                                            <p>S</p>
                                        </label>
                                        <input type="radio" name="size-1" id="values-m-1"/>
                                        <label className="style-text" htmlFor="values-m-1" data-value="M">
                                            <p>M</p>
                                        </label>
                                        <input type="radio" name="size-1" id="values-l-1"/>
                                        <label className="style-text" htmlFor="values-l-1" data-value="L">
                                            <p>L</p>
                                        </label>
                                        <input type="radio" name="size-1" id="values-xl-1"/>
                                        <label className="style-text" htmlFor="values-xl-1" data-value="XL">
                                            <p>XL</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="tf-product-info-quantity">
                                <div className="quantity-title fw-6">Quantity</div>
                                <div className="wg-quantity">
                                    <span className="btn-quantity minus-btn">-</span>
                                    <input type="text" name="number" value="1"/>
                                    <span className="btn-quantity plus-btn">+</span>
                                </div>
                            </div>
                            <div className="tf-product-info-buy-button">
                                <form className="">
                                    <Link to="#"
                                        className="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn "><span>Add
                                            to cart -&nbsp;</span><span className="tf-qty-price">₹ 1,700</span></Link>
                                    <Link to="#"
                                        className="tf-product-btn-wishlist hover-tooltip box-icon bg_white wishlist btn-icon-action">
                                        <span className="icon icon-heart"></span>
                                        <span className="tooltip">Add to Wishlist</span>
                                        <span className="icon icon-delete"></span>
                                    </Link>
                                    <Link to="#compare" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                        className="tf-product-btn-wishlist hover-tooltip box-icon bg_white compare btn-icon-action">
                                        <span className="icon icon-compare"></span>
                                        <span className="tooltip">Add to Compare</span>
                                        <span className="icon icon-check"></span>
                                    </Link>
                                    <div className="w-100">
                                        <Link to="#" className="btns-full">Buy with <img src="images/payments/paypal.png"
                                                alt=""/></Link>
                                        <Link to="#" className="payment-more-option">More payment options</Link>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <Link to="product-detail" className="tf-btn fw-6 btn-line">View full details<i
                                        className="icon icon-arrow1-top-left"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div className="modal fade modalDemo tf-product-modal" id="find_size">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="header">
                    <div className="demo-title">Size chart</div>
                    <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="tf-rte">
                    <div className="tf-table-res-df">
                        <h6>Size guide</h6>
                        <table className="tf-sizeguide-table">
                            <thead>
                                <tr>
                                    <th>Size</th>
                                    <th>US</th>
                                    <th>Bust</th>
                                    <th>Waist</th>
                                    <th>Low Hip</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>XS</td>
                                    <td>2</td>
                                    <td>32</td>
                                    <td>24 - 25</td>
                                    <td>33 - 34</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>4</td>
                                    <td>34 - 35</td>
                                    <td>26 - 27</td>
                                    <td>35 - 26</td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>6</td>
                                    <td>36 - 37</td>
                                    <td>28 - 29</td>
                                    <td>38 - 40</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>8</td>
                                    <td>38 - 29</td>
                                    <td>30 - 31</td>
                                    <td>42 - 44</td>
                                </tr>
                                <tr>
                                    <td>XL</td>
                                    <td>10</td>
                                    <td>40 - 41</td>
                                    <td>32 - 33</td>
                                    <td>45 - 47</td>
                                </tr>
                                <tr>
                                    <td>XXL</td>
                                    <td>12</td>
                                    <td>42 - 43</td>
                                    <td>34 - 35</td>
                                    <td>48 - 50</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="tf-page-size-chart-content">
                        <div>
                            <h6>Measuring Tips</h6>
                            <div className="title">Bust</div>
                            <p>Measure around the fullest part of your bust.</p>
                            <div className="title">Waist</div>
                            <p>Measure around the narrowest part of your torso.</p>
                            <div className="title">Low Hip</div>
                            <p className="mb-0">With your feet together measure around the fullest part of your hips/rear.
                            </p>
                        </div>
                        <div>
                            <img className="sizechart lazyload" data-src="images/shop/products/size_chart2.jpg"
                                src="images/shop/products/size_chart2.jpg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="modal modalCentered fade auto-popup modal-newleter">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-top">
                    <img className="lazyload" data-src="images/item/banner-newleter.jpg"
                        src="images/item/banner-newleter.jpg" alt="home-01"/>
                    <span className="icon icon-close btn-hide-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="modal-bottom">
                    <h4 className="text-center">Don’t miss out</h4>
                    <h6 className="text-center">Be the first one to get the new product at early bird prices.</h6>
                    <form id="subscribe-form" action="#" className="form-newsletter" method="post" acceptCharset="utf-8"
                        data-mailchimp="true">
                        <div id="subscribe-content">
                            <input type="email" name="email-form" id="subscribe-email" placeholder="Email *"/>
                            <button type="button" id="subscribe-button"
                                className="tf-btn btn-fill radius-3 animate-hover-btn w-100 justify-content-center">Keep me
                                updated</button>
                        </div>
                        <div id="subscribe-msg"></div>
                    </form>
                    <div className="text-center">
                        <Link to="#" data-bs-dismiss="modal" className="tf-btn btn-line fw-6 btn-hide-popup">Not
                            interested</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>

        </div>
    </>
  )
}
