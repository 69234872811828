import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function About() {
  return (
    <>
     <div className="tf-page-title style-2">
            <div className="container-full">
                <div className="heading text-center">About Us</div>
            </div>
        </div>

        <section className="flat-spacing-9">
            <div className="container">
                <div className="flat-title my-0">
                    <span className="title">We are Nicky Lingerie</span>
                    <p className="sub-title text_black-2">
                        Nicky Lingerie is a brand that embodies elegance, comfort, and confidence. Known for its
                        high-quality materials and attention to detail, Nicky Lingerie offers a diverse range of styles
                        that cater to various tastes and preferences. From luxurious lace to everyday essentials, each
                        piece is designed to enhance the wearer's natural beauty while providing a perfect fit. Whether
                        you're looking for something sensual or simply seeking comfortable daily wear, Nicky Lingerie
                        ensures that you feel empowered and stylish every day.<br className="d-xl-block d-none"/>
                        that timeless style never goes out of fashion. Our collection features classic <br
                            className="d-xl-block d-none"/>
                        pieces that are both stylish and versatile, perfect for building a <br
                            className="d-xl-block d-none"/>
                        wardrobe that will last for years.
                    </p>
                </div>
            </div>
        </section>
        
        <div className="container">
            <div className="line"></div>
        </div>
        
        <section className="flat-spacing-23 flat-image-text-section">
            <div className="container">
                <div className="tf-grid-layout md-col-2 tf-img-with-text style-4">
                    <div className="tf-image-wrap">
                        <img className="lazyload w-100" data-src="myimg/cat6.png" src="myimg/cat6.png" alt="collection-img"/>
                    </div>
                    <div className="tf-content-wrap px-0 d-flex justify-content-center w-100">
                        <div>
                            <div className="heading">Established - 2024</div>

                            <div className="text">
                                Nicky Lingerie was founded with a vision to revolutionize the intimate apparel industry
                                by combining luxury, comfort,
                                and style in a way that was accessible to all women. The brand was born out of a passion
                                for creating lingerie that not
                                only looks beautiful but also feels great to wear, recognizing that true elegance comes
                                from both aesthetics and comfort.
                                The journey of Nicky Lingerie began with a small team of designers and textile experts
                                who shared a common goal: to craft lingerie that makes women feel confident and
                                empowered. They meticulously sourced the finest fabrics, including delicate lace, smooth
                                silks, and breathable cottons, ensuring that every piece was as durable as it was
                                stylish. The team also paid close attention to the construction of each garment,
                                focusing on creating silhouettes that flatter a wide range of body types while offering
                                superior support.

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="flat-spacing-15">
            <div className="container">
                <div className="tf-grid-layout md-col-2 tf-img-with-text style-4">
                    <div className="tf-content-wrap px-0 d-flex justify-content-center w-100">
                        <div>
                            <div className="heading">Our mission</div>
                            <div className="text">
                                The mission of Nicky Lingerie is to empower women by creating intimate apparel that
                                combines elegance, comfort, and quality. The brand is dedicated to designing lingerie
                                that enhances every woman's natural beauty, providing a perfect fit and exceptional
                                support for all body types. By using the finest materials and staying attuned to the
                                latest fashion trends, Nicky Lingerie strives to make every woman feel confident,
                                stylish, and comfortable in her own skin. The brand is committed to fostering a sense of
                                empowerment and self-expression, ensuring that every piece of lingerie is not just a
                                garment but an experience that celebrates femininity and individuality.








                            </div>
                        </div>
                    </div>
                    <div className="grid-img-group">
                        <div className="tf-image-wrap box-img item-1">
                            <div className="img-style">
                                <img className="lazyload" src="myimg/bstsl3.jpg" data-src="myimg/bstsl3.jpg"
                                    alt="img-slider"/>
                            </div>
                        </div>
                        <div className="tf-image-wrap box-img item-2">
                            <div className="img-style">
                                <img className="lazyload" src="myimg/bbydoll.jpg" data-src="myimg/bbydoll.jpg"
                                    alt="img-slider"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        <section>
            <div className="container">
                <div className="bg_grey-2 radius-10 flat-wrap-iconbox">
                    <div className="flat-title lg">
                        <span className="title fw-5">Quality is our priority</span>
                        <div>
                            <p className="sub-title text_black-2">Our talented stylists have put together outfits that are
                                perfect for the season.</p>
                            <p className="sub-title text_black-2">They've variety of ways to inspire your next
                                fashion-forward look.</p>
                        </div>
                    </div>
                    <div className="flat-iconbox-v3 lg">
                        <div className="wrap-carousel wrap-mobile">
                            <div className="swiper tf-sw-mobile" data-preview="1" data-space="15">
                                <div className="swiper-wrapper wrap-iconbox lg">
                                    <div className="swiper-slide">
                                        <div className="tf-icon-box text-center">
                                            <div className="icon">
                                                <i className="icon-materials"></i>
                                            </div>
                                            <div className="content">
                                                <div className="title">High-Quality Materials</div>
                                                <p className="text_black-2">Crafted with precision and excellence, our
                                                    activewear is meticulously engineered using premium materials to
                                                    ensure unmatched comfort and durability.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tf-icon-box text-center">
                                            <div className="icon">
                                                <i className="icon-design"></i>
                                            </div>
                                            <div className="content">
                                                <div className="title">Laconic Design</div>
                                                <p className="text_black-2">Simplicity refined. Our activewear embodies the
                                                    essence of minimalistic design, delivering effortless style that
                                                    speaks volumes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tf-icon-box text-center">
                                            <div className="icon">
                                                <i className="icon-sizes"></i>
                                            </div>
                                            <div className="content">
                                                <div className="title">Various Sizes</div>
                                                <p className="text_black-2">Designed for every body and anyone, our
                                                    activewear embraces diversity with a wide range of sizes and shapes,
                                                    celebrating the beauty of individuality.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="sw-dots style-2 sw-pagination-mb justify-content-center"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
