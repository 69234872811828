import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function Wishlist() {
  return (
    <>
      <div className="tf-page-title">
            <div className="container-full">
                <div className="heading text-center">Your wishlist</div>
            </div>
        </div>
        
        <section className="flat-spacing-2">
            <div className="container">
                <div className="grid-layout wrapper-shop" data-grid="grid-4">
                    
                    <div className="card-product">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn type-wishlist">
                                <Link to="javascript:void(0);" className="box-icon bg_white wishlist">
                                    <span className="tooltip">Remove Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>
                            </div>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>

                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Garter Set</Link>
                            <span className="price">₹ 1,799</span>
                            <ul className="list-color-product">
                                <li className="list-color-item color-swatch active">
                                    <span className="tooltip">Orange</span>
                                    <span className="swatch-value bg_orange-3"></span>
                                    <img className="lazyload" data-src="images/products/orange-1.jpg"
                                        src="images/products/orange-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="card-product">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn type-wishlist">
                                <Link to="javascript:void(0);" className="box-icon bg_white wishlist">
                                    <span className="tooltip">Remove Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>
                            </div>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>

                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Garter Set</Link>
                            <span className="price">₹ 1,799</span>
                            <ul className="list-color-product">
                                <li className="list-color-item color-swatch active">
                                    <span className="tooltip">Orange</span>
                                    <span className="swatch-value bg_orange-3"></span>
                                    <img className="lazyload" data-src="images/products/orange-1.jpg"
                                        src="images/products/orange-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="card-product">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn type-wishlist">
                                <Link to="javascript:void(0);" className="box-icon bg_white wishlist">
                                    <span className="tooltip">Remove Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>
                            </div>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>

                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Garter Set</Link>
                            <span className="price">₹ 1,799</span>
                            <ul className="list-color-product">
                                <li className="list-color-item color-swatch active">
                                    <span className="tooltip">Orange</span>
                                    <span className="swatch-value bg_orange-3"></span>
                                    <img className="lazyload" data-src="images/products/orange-1.jpg"
                                        src="images/products/orange-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="card-product">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn type-wishlist">
                                <Link to="javascript:void(0);" className="box-icon bg_white wishlist">
                                    <span className="tooltip">Remove Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>
                            </div>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>

                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Garter Set</Link>
                            <span className="price">₹ 1,799</span>
                            <ul className="list-color-product">
                                <li className="list-color-item color-swatch active">
                                    <span className="tooltip">Orange</span>
                                    <span className="swatch-value bg_orange-3"></span>
                                    <img className="lazyload" data-src="images/products/orange-1.jpg"
                                        src="images/products/orange-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="card-product">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn type-wishlist">
                                <Link to="javascript:void(0);" className="box-icon bg_white wishlist">
                                    <span className="tooltip">Remove Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>
                            </div>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>

                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Garter Set</Link>
                            <span className="price">₹ 1,799</span>
                            <ul className="list-color-product">
                                <li className="list-color-item color-swatch active">
                                    <span className="tooltip">Orange</span>
                                    <span className="swatch-value bg_orange-3"></span>
                                    <img className="lazyload" data-src="images/products/orange-1.jpg"
                                        src="images/products/orange-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="card-product">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn type-wishlist">
                                <Link to="javascript:void(0);" className="box-icon bg_white wishlist">
                                    <span className="tooltip">Remove Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>
                            </div>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>

                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Garter Set</Link>
                            <span className="price">₹ 1,799</span>
                            <ul className="list-color-product">
                                <li className="list-color-item color-swatch active">
                                    <span className="tooltip">Orange</span>
                                    <span className="swatch-value bg_orange-3"></span>
                                    <img className="lazyload" data-src="images/products/orange-1.jpg"
                                        src="images/products/orange-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="card-product">
                        <div className="card-product-wrapper">
                            <Link to="product-detail" className="product-img">
                                <img className="lazyload img-product" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                                <img className="lazyload img-hover" data-src="myimg/gar1.jpg" src="myimg/gar1.jpg"
                                    alt="image-product"/>
                            </Link>
                            <div className="list-product-btn type-wishlist">
                                <Link to="javascript:void(0);" className="box-icon bg_white wishlist">
                                    <span className="tooltip">Remove Wishlist</span>
                                    <span className="icon icon-delete"></span>
                                </Link>
                            </div>
                            <div className="list-product-btn">
                                <Link to="#quick_add" data-bs-toggle="modal"
                                    className="box-icon bg_white quick-add tf-btn-loading">
                                    <span className="icon icon-bag"></span>
                                    <span className="tooltip">Quick Add</span>
                                </Link>

                            </div>
                        </div>
                        <div className="card-product-info">
                            <Link to="product-detail" className="title link">Garter Set</Link>
                            <span className="price">₹ 1,799</span>
                            <ul className="list-color-product">
                                <li className="list-color-item color-swatch active">
                                    <span className="tooltip">Orange</span>
                                    <span className="swatch-value bg_orange-3"></span>
                                    <img className="lazyload" data-src="images/products/orange-1.jpg"
                                        src="images/products/orange-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">Black</span>
                                    <span className="swatch-value bg_dark"></span>
                                    <img className="lazyload" data-src="images/products/black-1.jpg"
                                        src="images/products/black-1.jpg" alt="image-product"/>
                                </li>
                                <li className="list-color-item color-swatch">
                                    <span className="tooltip">White</span>
                                    <span className="swatch-value bg_white"></span>
                                    <img className="lazyload" data-src="images/products/white-1.jpg"
                                        src="images/products/white-1.jpg" alt="image-product"/>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}
