import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function MyAccountOrders() {
  return (
    <>
     <div className="tf-page-title">
            <div className="container-full">
                <div className="heading text-center">My Orders</div>
            </div>
        </div>
      
        <section className="flat-spacing-11">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <ul className="my-account-nav">
                            <li><Link to="my-account" className="my-account-nav-item ">Dashboard</Link></li>
                            <li><span className="my-account-nav-item active">Orders</span></li>
                            <li><Link to="my-account-address" className="my-account-nav-item">Addresses</Link></li>
                            <li><Link to="my-account-edit" className="my-account-nav-item">Account Details</Link></li>
                            <li><Link to="my-account-wishlist" className="my-account-nav-item">Wishlist</Link></li>
                            <li><Link to="login" className="my-account-nav-item">Logout</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-9">
                        <div className="my-account-content account-order">
                            <div className="wrap-account-order">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="fw-6">Order</th>
                                            <th className="fw-6">Date</th>
                                            <th className="fw-6">Status</th>
                                            <th className="fw-6">Total</th>
                                            <th className="fw-6">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="tf-order-item">
                                            <td>
                                                #123
                                            </td>
                                            <td>
                                                August 1, 2024
                                            </td>
                                            <td>
                                                On hold
                                            </td>
                                            <td>
                                                ₹599.0 for 1 items
                                            </td>
                                            <td>
                                                <Link to="#"
                                                    className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">
                                                    <span>View</span>
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr className="tf-order-item">
                                            <td>
                                                #345
                                            </td>
                                            <td>
                                                August 2, 2024
                                            </td>
                                            <td>
                                                On hold
                                            </td>
                                            <td>
                                                ₹759.0 for 1 items
                                            </td>
                                            <td>
                                                <Link to="#"
                                                    className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">
                                                    <span>View</span>
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr className="tf-order-item">
                                            <td>
                                                #567
                                            </td>
                                            <td>
                                                August 3, 2024
                                            </td>
                                            <td>
                                                On hold
                                            </td>
                                            <td>
                                                ₹869.0 for 1 items
                                            </td>
                                            <td>
                                                <Link to="#"
                                                    className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">
                                                    <span>View</span>
                                                </Link>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
