import React, { useEffect,useState } from 'react'
import { Link,useNavigate } from "react-router-dom";
import axios from "axios";

export default function Header(props) {
    

  return (
    <>
        <div id="wrapper">
        <div className="announcement-bar bg_violet">
            <div className="wrap-announcement-bar">
                <div className="box-sw-announcement-bar speed-1">
                    <div className="announcement-bar-item">
                        <p>FREE SHIPPING AND RETURNS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>FREE SHIPPING AND RETURNS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>FREE SHIPPING AND RETURNS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>FREE SHIPPING AND RETURNS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>FREE SHIPPING AND RETURNS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
                    </div>
                    <div className="announcement-bar-item">
                        <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
                    </div>
                </div>
            </div>
            <span className="icon-close close-announcement-bar"></span>

        </div>
        <header id="header" className="header-default header-style-2">
            <div className="main-header line">
                <div className="container-full px_15 lg-px_40" style={{background:"#440001"}}>
                    <div className="row wrapper-header align-items-center">
                        <div className="col-xl-5 tf-md-hidden" style={{visibility: "hidden"}}>
                            <div className="tf-cur">
                                <div className="tf-currencies">
                                    <select className="image-select center style-default type-currencies">
                                        <option value="" data-thumbnail="images/country/fr.svg">EUR € | France</option>
                                        <option value="" data-thumbnail="images/country/de.svg">EUR € | Germany
                                        </option>
                                        <option value=""  data-thumbnail="images/country/us.svg">USD $ | United States</option>
                                        <option value="" data-thumbnail="images/country/vn.svg">VND ₫ | Vietnam</option>
                                    </select>
                                </div>
                                <div className="tf-languages">
                                    <select className="image-select center style-default type-languages">
                                        <option>English</option>
                                        <option>العربية</option>
                                        <option>简体中文</option>
                                        <option>اردو</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-3 tf-lg-hidden">
                            <Link to="#mobileMenu" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft"
                                style={{color: "white"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16"
                                    fill="none">
                                    <path
                                        d="M2.00056 2.28571H16.8577C17.1608 2.28571 17.4515 2.16531 17.6658 1.95098C17.8802 1.73665 18.0006 1.44596 18.0006 1.14286C18.0006 0.839753 17.8802 0.549063 17.6658 0.334735C17.4515 0.120408 17.1608 0 16.8577 0H2.00056C1.69745 0 1.40676 0.120408 1.19244 0.334735C0.978109 0.549063 0.857702 0.839753 0.857702 1.14286C0.857702 1.44596 0.978109 1.73665 1.19244 1.95098C1.40676 2.16531 1.69745 2.28571 2.00056 2.28571ZM0.857702 8C0.857702 7.6969 0.978109 7.40621 1.19244 7.19188C1.40676 6.97755 1.69745 6.85714 2.00056 6.85714H22.572C22.8751 6.85714 23.1658 6.97755 23.3801 7.19188C23.5944 7.40621 23.7148 7.6969 23.7148 8C23.7148 8.30311 23.5944 8.59379 23.3801 8.80812C23.1658 9.02245 22.8751 9.14286 22.572 9.14286H2.00056C1.69745 9.14286 1.40676 9.02245 1.19244 8.80812C0.978109 8.59379 0.857702 8.30311 0.857702 8ZM0.857702 14.8571C0.857702 14.554 0.978109 14.2633 1.19244 14.049C1.40676 13.8347 1.69745 13.7143 2.00056 13.7143H12.2863C12.5894 13.7143 12.8801 13.8347 13.0944 14.049C13.3087 14.2633 13.4291 14.554 13.4291 14.8571C13.4291 15.1602 13.3087 15.4509 13.0944 15.6653C12.8801 15.8796 12.5894 16 12.2863 16H2.00056C1.69745 16 1.40676 15.8796 1.19244 15.6653C0.978109 15.4509 0.857702 15.1602 0.857702 14.8571Z"
                                        fill="currentColor"></path>
                                </svg>
                            </Link>
                        </div>
                        <div className="col-xl-2 col-md-4 col-6 text-center">
                            <Link to="/" className="logo-header">
                                <img src="myimg/NICKY_LINGERIE_STORE-removebg-preview.png" alt="logo" className="logo"/>
                            </Link>
                        </div>

                        <div className="col-xl-5 col-md-4 col-3">
                            <ul className="nav-icon d-flex justify-content-end align-items-center gap-20">
                                <li className="nav-search"><Link to="#canvasSearch" data-bs-toggle="offcanvas"
                                        aria-controls="offcanvasLeft" className="nav-icon-item"><i
                                            className="icon icon-search"></i></Link></li>
                                            {(sessionStorage.getItem('email') === null || sessionStorage.getItem('email') == '' || props.logout === true) ? (
  <><li className="nav-account"><Link to="#login" data-bs-toggle="modal" className="nav-icon-item"><i
  className="icon icon-account"></i></Link></li></>) : (
 <><li className="nav-account"><Link to="/my-account" className="nav-icon-item"><i className="icon icon-account"></i></Link></li>
{/* <Link to="/logout" className="btn border">                    
 Logout
</Link> */}
</>)}
                                
                                <li className="nav-wishlist"><Link to="wishlist" className="nav-icon-item"><i
                                            className="icon icon-heart"></i><span className="count-box">0</span></Link></li>
                                <li className="nav-cart"><Link to="#shoppingCart" data-bs-toggle="modal"
                                        className="nav-icon-item"><i className="icon icon-bag"></i><span
                                            className="count-box">0</span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-bottom line tf-md-hidden">
                <div className="container-full px_15 lg-px_40">
                    <div className="wrapper-header d-flex justify-content-center align-items-center">
                        <nav className="box-navigation text-center">
                            <ul className="box-nav-ul d-flex align-items-center justify-content-center gap-30">
                             {props.catlist.map((object, i) => {
                
                            return(
                                <li key={i} className="menu-item">
                                    <Link key={object.id} to={`/category-inner/${object.seo}/${object.id}`} className="item-link">{object.name} </Link>
                                </li>
                            );
                        })}  
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

        </header>
        </div> 
    </>
  )
}
