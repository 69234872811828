import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <>
     <div className="tf-page-title style-2">
            <div className="container-full">
                <div className="heading text-center">Privacy Policy</div>
            </div>
        </div>
        <section className="flat-spacing-25">
            <div className="container">
                <div className="tf-main-area-page">
                    <h4>The Nicky Lingerie Privacy Policies</h4>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt minima quas tenetur quae
                        distinctio dignissimos, eaque voluptate dicta vitae eum numquam veniam ipsam, laboriosam placeat
                        assumenda omnis a maxime incidunt natus nobis illo praesentium. In enim minus aut sequi
                        necessitatibus blanditiis accusantium numquam optio animi nostrum expedita deleniti iure ullam,
                        ducimus, tenetur omnis? Ducimus, qui! Vero quis distinctio enim nulla, quaerat voluptatum et
                        animi exercitationem ipsam reiciendis cum sunt nam atque, maxime nobis cupiditate iure, ratione
                        dolor? Quos odio voluptatum totam laborum eaque temporibus sit dolorem, omnis repudiandae veniam
                        inventore mollitia laudantium facilis? Explicabo necessitatibus debitis voluptatum minus
                        consequatur veniam.</p>
                    <p>Please be advised that the practices described in this Privacy Policy apply to information
                        gathered by us or our subsidiaries, affiliates or agents: (i) through this Website, (ii) where
                        applicable, through our Customer Service Department in connection with this Website, (iii)
                        through information provided to us in our free standing retail stores, and (iv) through
                        information provided to us in conjunction with marketing promotions and sweepstakes.</p>
                    <p>We are not responsible for the content or privacy practices on any websites.</p>
                    <p>We reserve the right, in our sole discretion, to modify, update, add to, discontinue, remove or
                        otherwise change any portion of this Privacy Policy, in whole or in part, at any time. When we
                        amend this Privacy Policy, we will revise the “last updated” date located at the top of this
                        Privacy Policy.</p>
                    <p>If you provide information to us or access or use the Website in any way after this Privacy
                        Policy has been changed, you will be deemed to have unconditionally consented and agreed to such
                        changes. The most current version of this Privacy Policy will be available on the Website and
                        will supersede all previous versions of this Privacy Policy.</p>
                    <p>If you have any questions regarding this Privacy Policy, you should contact our Customer Service
                        Department by email at marketing@company.com</p>
                </div>
            </div>
        </section> 
    </>
  )
}
