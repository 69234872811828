import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function MyAccountAddress() {
  return (
    <>
     <div className="tf-page-title">
            <div className="container-full">
                <div className="heading text-center">Addresses</div>
            </div>
        </div>

        <section className="flat-spacing-11">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <ul className="my-account-nav">
                            <li><Link to="my-account" className="my-account-nav-item ">Dashboard</Link></li>
                            <li><Link to="my-account-orders" className="my-account-nav-item">Orders</Link></li>
                            <li><span className="my-account-nav-item active">Addresses</span></li>
                            <li><Link to="my-account-edit" className="my-account-nav-item">Account Details</Link></li>
                            <li><Link to="my-account-wishlist" className="my-account-nav-item">Wishlist</Link></li>
                            <li><Link to="login" className="my-account-nav-item">Logout</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-9">
                        <div className="my-account-content account-address">
                            <div className="">
                                <div>
                                    <h6 className="mb_20">Address</h6>
                                    <p>Delhi</p>
                                    <p>1234 Fashion Street, CP-47</p>
                                    <p>New Delhi</p>
                                    <p>info@nickylingerie.com</p>
                                    <p className="mb_10">+91-1234567894</p>
                                    <div className="d-flex gap-10">
                                        <Link to="#"
                                            className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">
                                            <span>Edit</span>
                                        </Link>
                                        <Link to="#"
                                            className="tf-btn btn-outline animate-hover-btn rounded-0 justify-content-center">
                                            <span>Delete</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
